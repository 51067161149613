import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { PET_HIDE_REASONS, SNOWFLAKE } from "../../constants";
import { HideAndRemovePetApiOption } from "../Pet/types";

const ConfirmationModalForm = ({
    removeHidePet,
    setConfirmationModalOpen,
    petId,
    isLoading,
    setIsLoading
}: {
    removeHidePet: (values: HideAndRemovePetApiOption[]) => void;
    setConfirmationModalOpen: (val: boolean) => void;
    petId: string;
    isLoading: boolean;
    setIsLoading: (val: boolean) => void;
}) => {
    const { t } = useTranslation();
    const onSubmit = async () => {
        setIsLoading(true);
        setConfirmationModalOpen(false);
        removeHidePet([{ petId: petId, petStatusId: 3, petRemovalReason: PET_HIDE_REASONS.DELETED }]);
    };
    return (
        <div className="flex items-end h-full">
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                    return (
                        <form id="hidePetConfirmOptionsForm" onSubmit={handleSubmit} role="form">
                            <div className="flex w-auto justify-center">

                                <div className="flex w-96 flex-col">
                                    <div className="flex flex-col px-4 my-6 text-center text-lg lg:px-0">
                                    </div>
                                    <div className="flex flex-col px-4 mb-6 text-center text-lg lg:px-0">
                                        <div>{t(SNOWFLAKE.REMOVE_PET_MODAL_BODY_PARA1)}</div>
                                        <div>{t(SNOWFLAKE.REMOVE_PET_MODAL_BODY_PARA2)}</div>
                                        <div>{t(SNOWFLAKE.REMOVE_PET_MODAL_BODY_PARA3)}</div>
                                    </div>
                                </div>


                            </div>
                            <div className="flex flex-col items-center lg:mt-8">
                                <Button
                                    type="submit"
                                    mode="contained"
                                    variant="primary"
                                    isLoading={isLoading}
                                    classes="lg:mt-2 w-80 py-4 font-bold text-xl px-4"
                                >
                                    {t(SNOWFLAKE.REMOVE_PET_MODAL_CTA)}
                                </Button>
                                <Button
                                    mode="link"
                                    variant="primary"
                                    aria-label="cancel button"
                                    type="button"
                                    onClick={() => {
                                        setConfirmationModalOpen(false);
                                    }}
                                    classes="flex-col mb-5 lg:mb-0 items-center lg:w-auto lg:flex-none mt-2"
                                >
                                    {t(SNOWFLAKE.REMOVE_PET_MODAL_CANCEL)}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            />
        </div>
    );
};

export default ConfirmationModalForm;
