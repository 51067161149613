/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
    useEffect,
    useRef,
    createContext,useState,
    useContext
  } from "react";
  import { Auth, useAuth } from "./use-auth";
  import { AUTHENTICATION_STATUS } from "../constants";
  import featureFlagConfig from "../featureFlags/featureFlags.json"
  import { getCurrentLocale } from "../i18n";

  const currentLocale = getCurrentLocale();
  const splitLocale = currentLocale.split("-").length > 1 ? currentLocale.split("-")[1] : currentLocale.split("-")[0];
  const selectedRegion = (featureFlagConfig as any) [splitLocale]
  const featureFlagContext = createContext(selectedRegion);
  
  export function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
    const isValidUser = useProvideFeatureFlag();
    const region = isValidUser ? selectedRegion : {};
    return <featureFlagContext.Provider value={region}>{children}</featureFlagContext.Provider>;
  }

  export const useFeatureFlagsConfig = () => {
    return useContext(featureFlagContext);
  };
  
  export const useProvideFeatureFlag = () =>{
    const [isValidUser, setValidUser] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);
    const auth: Auth = useAuth();
  
    useEffect(() => {
      abortControllerRef.current && abortControllerRef.current.abort();
      if (auth.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
        setValidUser(true);
      }
      return () => {
        abortControllerRef.current && abortControllerRef.current.abort();
      };
    }, [auth.status]);
  
    return isValidUser;
  }
  