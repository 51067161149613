export default {
    LOGIN_SUCCESS: {
      user_type: 'vet',
      method: 'Gigya',
      event: 'login',
      action: 'login success',
    },
    ARTICLES: {
      user_type: "",
      method: "",
      event: "article",
      action : "related_article_click",
      section_title: "Related Articles",
      article_title: "",
      click_text: "",
      click_url: ""
    },
    EDITPIC: {
      user_type: "",
      method: "",
      event: "CTA",
      action: "click",
      click_text: "profile pic upload",
    },
    SIDENAV: {
      user_type: "",
      method: "",
      event: "navigation",
      nav_group: "toolbar",
      action: "click",
      click_text: "",
      click_url: ""
    },
    PETTILE: {
      user_type: "",
      method: "",
      event: "CTA",
      action: "click",
      click_text: "",
      click_text2: "",
      click_url: "",
    },
    ADDAPETTILE: {
      user_type: "",
      method: "",
      event: "CTA",
      action: "click",
      click_text: "",
      click_url: "",
    },
  };
  