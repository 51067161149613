import React, { ReactElement } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';


interface FeedingGuideAccordionProps {
  contentHtml: string;
  isOpen: boolean;
}

const FeedingGuideAccordion: React.FC<FeedingGuideAccordionProps> = ({ contentHtml, isOpen }) => {

    // Function to count the number of <table> tags in the HTML content
  const countTablesInContent = (htmlContent: string) => {
    const tableMatch = htmlContent.match(/<table>/g); // Use a global regex to match all <table> tags
    return tableMatch ? tableMatch.length : 0; // Return the count of matches, or 0 if none found
  };

   // Count the number of tables in the content
   const numberOfTables = countTablesInContent(contentHtml);

    // Function to remove content before the first <table> tag, if there's only one table
  const getContentStartingFromTable = (htmlContent: string) => {
    if (countTablesInContent(htmlContent) === 1) {
      const tableIndex = htmlContent.indexOf('<table>');
      return tableIndex >= 0 ? htmlContent.substring(tableIndex) : htmlContent;
    }
    return htmlContent; // Return the original content if there's more than one table
  };

  // Use the adjusted function to sanitize the content
  const contentFromTable = getContentStartingFromTable(contentHtml);

  // Sanitize the HTML content using DOMPurify with a configuration for HTML only
  const sanitizedHtml = DOMPurify.sanitize(contentFromTable, { USE_PROFILES: { html: true } });

  // Parse the sanitized HTML content into React elements
  const parsedElements = parse(sanitizedHtml);

   // Function to apply custom styles to table cells
  const styleTableCells = (node: ReactElement, index: number, cells: ReactElement[]): ReactElement => {
    // Apply a dividing line to all cells except the last one
    const cellClass = index < cells.length - 1 ? 'border-r border-gray-300' : '';
    return <td className={`${cellClass} py-2 px-4 font-normal`}>{node.props.children}</td>;
  };

  // Function to apply custom styles to table rows
  const styleTableRows = (node: ReactElement, index: number): ReactElement => {
    // Apply the 'bg-brand-blue-100' class to the first row
    const rowClass = index === 0 ? 'bg-brand-color-library-blue-100' : index % 2 === 0 ? 'bg-gray-100' : 'bg-white';
    // Map over each cell in the row to apply cell styles
    const cells = React.Children.toArray(node.props.children) as ReactElement[];
    const styledCells = cells.map((cell, cellIndex) => styleTableCells(cell, cellIndex, cells));
    return <tr className={rowClass}>{styledCells}</tr>;
  };

  // Function to find and style the table within the parsed elements
  const styledTable = React.Children.map(parsedElements, (child) => {
    if (React.isValidElement(child) && child.type === 'table') {
      // Apply padding to the bottom of the table if there are multiple tables
      const tablePaddingClass = numberOfTables > 1 ? 'mb-5' : '';
      // Cast child to ReactElement to access its children prop
      const tableElement = child as ReactElement<{ children: React.ReactNode }>;
      // Map over each tbody in the table to apply styles to rows
      const styledTbody = React.Children.map(tableElement.props.children, (tableChild) => {
        if (React.isValidElement(tableChild) && tableChild.type === 'tbody') {
          // Cast tableChild to ReactElement to access its children prop
          const tbodyElement = tableChild as ReactElement<{ children: React.ReactNode }>;
          // Map over each row in the tbody to apply row styles
          const styledRows = React.Children.map(tbodyElement.props.children as ReactElement[], styleTableRows);
          return React.cloneElement(tbodyElement, {}, styledRows);
        }
        return tableChild;
      });
      // Return the table element with the styled tbody
      return (
        <table className={`lg:w-4/5 sm:w-full ${tablePaddingClass}`}>
          {styledTbody}
        </table>
      );
    }
    return child;
  });
  
  return (
    <>
      <div className={`accordion w-full ${isOpen ? '' : 'hidden'}`}>
        {/* Accordion content wrapper */}
        <div className="accordion-content w-full">
          {/* Accordion content */}
          {styledTable}
        </div>
      </div>
    </>
  );
};

export default FeedingGuideAccordion;