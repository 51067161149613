export const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    role="img"
    aria-label="Edit Icon"
  >
    <g aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5985 2C15.0352 2 16.2 3.16456 16.2 4.60149C16.2 5.23829 15.9664 5.851 15.5467 6.32529L15.4379 6.44091L10.2129 11.6659C9.93336 11.9455 9.59146 12.1542 9.21639 12.2756L9.0537 12.3223L4.81928 13.3807L5.8778 9.14604C5.97387 8.76232 6.15967 8.40739 6.41865 8.11063L6.53407 7.98709L11.7591 2.76207C12.1573 2.36386 12.6723 2.10704 13.2478 2.02555L13.4222 2.0064L13.5985 2ZM13.6005 3.5L13.5113 3.50346C13.2878 3.5212 13.0757 3.60678 12.9028 3.74771L12.8197 3.82273L12.2016 4.44091L13.7591 5.99843L14.3773 5.38025C14.584 5.17356 14.7 4.89349 14.7 4.60149C14.7 4.02884 14.2632 3.5584 13.7063 3.50504L13.6005 3.5ZM12.6984 7.05909L11.1409 5.50157L7.97479 8.66769L9.14293 9.05707L9.53231 10.2252L12.6984 7.05909ZM6.8805 11.3188L7.21179 9.99442L7.95675 10.2425L8.20539 10.988L6.8805 11.3188ZM7.098 4.9996L8.59875 3.4996L3.75 3.5L3.6178 3.50382C2.48043 3.56972 1.56972 4.48043 1.50382 5.6178L1.5 5.75V14.75L1.50382 14.8822C1.56972 16.0196 2.48043 16.9303 3.6178 16.9962L3.75 17H12.75L12.8822 16.9962C14.0196 16.9303 14.9303 16.0196 14.9962 14.8822L15 14.75V9.15085L13.5 10.6508V14.75L13.495 14.8375C13.455 15.1818 13.1818 15.455 12.8375 15.495L12.75 15.5H3.75L3.66253 15.495C3.31822 15.455 3.04504 15.1818 3.00505 14.8375L3 14.75V5.75L3.00505 5.66253C3.04504 5.31822 3.31822 5.04504 3.66253 5.00505L3.75 5L7.098 4.9996Z"
        fill="#0054A4"
      />
    </g>
  </svg>
);
