export const SNOWFLAKE = {
    GRIEVANCE_HEADER: "snowflake.grevienceHeader",
    GRIEVANCE_BODY: "snowflake.grevienceBody",
    GRIEVANCE_ACTION: "snowflake.grevienceAction",
    GRIEVANCE_ACTION_TEXT_DELETE: "snowflake.grevienceActionTextDelete",   
    GRIEVANCE_ACTION_TEXT_ARCHIVE: "snowflake.grevienceActionTextArchive",   
    REMOVE_PET_MODAL_TITLE:"snowflake.removePetModalTitle",
    REMOVE_PET_MODAL_BODY_PARA1:"snowflake.removePetModalBodyPara1",
    REMOVE_PET_MODAL_BODY_PARA2:"snowflake.removePetModalBodyPara2",
    REMOVE_PET_MODAL_BODY_PARA3:"snowflake.removePetModalBodyPara3",
    REMOVE_PET_MODAL_CTA:"snowflake.removePetModalCTA",
    REMOVE_PET_MODAL_CANCEL:"snowflake.removePetModalCancel",
    ADD_UPDATE_PET_MODAL_TITLE:"snowflake.addUpdatePetModalTitle",
    ADD_UPDATE_PET_MODAL_PARA1:"snowflake.addUpdatePetModalPara1",
    ADD_UPDATE_PET_MODAL_PARA2:"snowflake.addUpdatePetModalPara2",
    ADD_UPDATE_PET_MODAL_PARA3:"snowflake.addUpdatePetModalPara3",
    ADD_UPDATE_PET_MODAL_CTA:"snowflake.addUpdatePetModalCTA", 
    ATTRIBUTES_petNickName : "snowflake.attributes.petNickName",
    ATTRIBUTES_petType : "snowflake.attributes.petType",
    ATTRIBUTES_petBirthday : "snowflake.attributes.petBirthday",
    ATTRIBUTES_petAdoptDate : "snowflake.attributes.petAdoptDate",
    ATTRIBUTES_petGender : "snowflake.attributes.petGender",
    ATTRIBUTES_petBreed : "snowflake.attributes.petBreed",
    ATTRIBUTES_petSizeId : "snowflake.attributes.petSizeId",
    NOTIFICATION_HEADER: "snowflake.notification.infoHeader",
    NOTIFICATION_BODY: "snowflake.notification.infoBody",
    NOTIFICATION_ACTION: "snowflake.notification.infoCTA",
    ADD_PET__MODAL_TITLE: "snowflake.addPetModalTitle",
    ADD_PET__MODAL_PARA_1: "snowflake.addPetModalpara1",
    ADD_PET__MODAL_PARA_2: "snowflake.addPetModalpara2",
    ADD_PET_MODAL_ACTION_1: "snowflake.addPetModalAction1",
    ADD_PET_MODAL_TEXT: "snowflake.addPetModalText",
    ADD_PET_MODAL_ACTION_2: "snowflake.addPetModalAction2",
    LEFT_OUT_PET_MODAL_TITLE: "snowflake.leftOutPetModalTitle",
    LEFT_OUT_PET_MODAL_PARA_1: "snowflake.leftOutPetModalpara1",
    LEFT_OUT_PET_MODAL_PARA_2: "snowflake.leftOutPetModalpara2",
    LEFT_OUT_PET_MODAL_ACTION_1: "snowflake.leftOutPetModalAction1",
    LEFT_OUT_PET_MODAL_ACTION_2: "snowflake.leftOutPetModalAction2",
    PETS_ADDED_MODAL_TITLE: "snowflake.petsAddedModalTitle",
    PETS_ADDED_MODAL_PARA_1: "snowflake.petsAddedModalpara1",
    PET_ADDED_MODAL_ACTION_1: "snowflake.petAddedModalAction1",
    PET_ADDED_MODAL_ACTION_2: "snowflake.petAddedModalAction2",
    START_FROM_SCRATCH_MODAL_TITLE:"snowflake.startFromScratchModalTitle",
    START_FROM_SCRATCH_MODAL_PARA_1:"snowflake.startFromScratchModalpara1",
    START_FROM_SCRATCH_MODAL_PARA_2:"snowflake.startFromScratchModalpara2",
    START_FROM_SCRATCH_MODAL_ACTION_1:"snowflake.startFromScratchModalAction1",
    START_FROM_SCRATCH_MODAL_ACTION_2:"snowflake.startFromScratchModalAction2",
    NEW_PET:"snowflake.newPet",
  };
  