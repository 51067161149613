import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Form } from "react-final-form";
import { Loader, ProductCard } from "ui";
import { HillsProductType } from "../../../../../types";
//we would use this for the mock data
// import { API_URLS } from "../../../../constants/apiUrls"; 
import { Modal } from "../../../../lib/modal";
import { useTranslation } from "react-i18next";
import { SearchBar } from "./SearchBar";
import { Accordion } from "./Accordion";
import { useFoodSelectDataContext } from "./Contexts/FoodSelectDataContext";
import { Chip } from "./Chip";
// will revisit post MLP with pre-selected filters
// import { ToolTip } from "./ToolTip.tsx";
import { filterIcon } from "../../../../icons/filterIcon";
import { CloseIcon } from "../../../../icons/closeIcon";
import { filterConfigs } from './FilterConfig/FilterConfig';


const FoodSelectionModal = ({
  petName,
  petTypeId,
  open,
  setOpen,
  updateSelectedFood,
  resetFiltersTrigger,
}: {
  petName: string;
  petTypeId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  updateSelectedFood: (selectedFoodId: string | undefined) => void;
  resetFiltersTrigger: boolean; 
}) => {
    // State to manage selected filters by category
    const [selectedFilters, setSelectedFilters] = useState<{
      brand: string[];
      productForm: string[];
      lifestage: string[];
      healthCategory: string[];
      productFamily: string[];
    }>({
      brand: [],
      productForm: [],
      lifestage: [],
      healthCategory: [],
      productFamily: [],
    });
   // Add the search button handler
   const handleSearchExecute = () => {
    setExecuteSearch(true);
  };
  const { t } = useTranslation();
  const { hillsProducts, isDataLoading, loadHillsProducts } = useFoodSelectDataContext();
  const [searchQuery, setSearchQuery] = useState<string>('');
  // State to manage expanded state of accordions
  const [expandedAccordions, setExpandedAccordions] = useState<{
    [key: string]: boolean;
  }>({
    brand: false,
    productType: false,
    lifestage: false,
    healthCategory: false,
    additionalNeeds: false,
  });
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  // Add states for executeSearch and searchSuggestions
  const [executeSearch, setExecuteSearch] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  // Add a new state for the search input value
  const [searchInputValue, setSearchInputValue] = useState('');

  // Effect to clear filters when the trigger prop changes
  useEffect(() => {
    if (resetFiltersTrigger) {
      clearAllFilters();
    }
  }, [resetFiltersTrigger]);

  useEffect(() => {
    loadHillsProducts(petTypeId,open);
  }, [open]);

  const onSelection = (product: HillsProductType) => {
      updateSelectedFood(product.id); // Call the updateSelectedFood function with the product ID
    setOpen(false);
  };

  // ******** Start Filters and Search *********

  // Modify handleSearchInputChange to only set the search query and generate suggestions
  const handleSearchInputChange = (query: string) => {
    setSearchInputValue(query);
    setSearchQuery(query);
    setExecuteSearch(false);
  
    if (query.length > 0) {
      const lowerCaseQuery = query.toLowerCase();
      
      // Filter suggestions that include the search term and ensure product.name exists
      const filteredSuggestions = hillsProducts
        .filter((product) =>
          product.name && product.name.toLowerCase().includes(lowerCaseQuery)
        );
  
      // Sort suggestions by relevance
      const sortedSuggestions = filteredSuggestions.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        const indexA = nameA.indexOf(lowerCaseQuery);
        const indexB = nameB.indexOf(lowerCaseQuery);
  
        // Check for exact matches first
        if (nameA === lowerCaseQuery) return -1;
        if (nameB === lowerCaseQuery) return 1;
  
        // Check if the query matches at the start of the product name
        if (indexA === 0) return -1;
        if (indexB === 0) return 1;
  
        // Otherwise, prioritize by index of match
        if (indexA !== indexB) return indexA - indexB;
  
        // If index is the same, prioritize shorter suggestions
        return nameA.length - nameB.length;
      });
  
      // Update the search suggestions state with sorted suggestions
      const suggestionNames = sortedSuggestions.map((product) => product.name).slice(0, 5); // Limit to 5 suggestions visible at once
      setSearchSuggestions(suggestionNames);
    } else {
      // If the query is empty, clear the suggestions
      setSearchSuggestions([]);
    }
  };

// Add a function to apply filters to the products list
const applyFilters = (products: HillsProductType[]) => {
  return products.filter((product) => {
    // Check if product matches all selected filters
    return Object.entries(selectedFilters).every(([category, filters]) => {
      if (filters.length === 0) return true; // No filters selected for this category

      if (category === 'brand') {
        // Normalize and capitalize both the product brand and the filters before comparison
        const normalizedProductBrand = capitalizeWords(normalizeStringValue(product.brand));
        const normalizedFilters = filters.map(filter => capitalizeWords(normalizeStringValue(filter)));
        return normalizedFilters.includes(normalizedProductBrand);
      }
      if (category === 'healthCategory') {
        // Normalize and capitalize the healthCategory values before comparison
        const normalizedHealthCategories = product.healthCategory?.map(value => capitalizeWords(normalizeStringValue(value)));
        const normalizedFilters = filters.map(filter => capitalizeWords(normalizeStringValue(filter)));
        return normalizedFilters.some(filter => normalizedHealthCategories?.includes(filter));
      }
      // For other categories, check if the filter is included in the tagsMap
      const productValue = product.tagsMap[category as keyof typeof product.tagsMap];

      // If the value is an array, check if any of the capitalized filters are included
      if (Array.isArray(productValue)) {
        const capitalizedProductValues = productValue.map(value => capitalizeWords(normalizeStringValue(value)));
        const normalizedFilters = filters.map(filter => capitalizeWords(normalizeStringValue(filter)));
        return normalizedFilters.some(filter => capitalizedProductValues.includes(filter));
      }

      // If the value is a string, capitalize it and check if it matches any of the filters
      if (typeof productValue === 'string') {
        const capitalizedProductValue = capitalizeWords(normalizeStringValue(productValue));
        const normalizedFilters = filters.map(filter => capitalizeWords(normalizeStringValue(filter)));
        return normalizedFilters.includes(capitalizedProductValue);
      }

      return false;
    });
  });
};

const capitalizeWords = (value: string): string =>
  value
    // Normalize different apostrophes to a single type (curly apostrophe)
    .replace(/`|'/g, "’")
    // Lowercase the entire string first to handle mixed case scenarios
    .toLowerCase()
    // Capitalize the first letter of each word
    .replace(/\b\w/g, char => char.toUpperCase())
     // Correctly format "Hill’s" with a lowercase 's'
    .replace(/Hill’S/g, "Hill’s")
    // .replace(/Hill’s S/g, "Hill’s s")

// Use the updated capitalizeWords function in normalizeStringValue
const normalizeStringValue = (value: string | undefined): string => {
  if (typeof value !== 'string') {
    // Return an empty string or some default value if the input is not a string
    return '';
  }
  // Normalize the string value
  let normalizedValue = value
    .trim()
    .replace(/\u00A0/g, ' '); // Trim whitespace and replace non-breaking spaces with regular spaces

  // Capitalize the first letter of each word and handle special cases for "Hill’s"
  normalizedValue = capitalizeWords(normalizedValue);

  return normalizedValue;
};

// Update the filteredProducts logic to apply filters and search query
const filteredProducts = applyFilters(hillsProducts).filter((product) =>
  executeSearch ? normalizeStringValue(product?.name || '').toLowerCase().includes(normalizeStringValue(searchQuery || '').toLowerCase()) : true
);

const productCount = filteredProducts.length;
const showingProduct = t(productCount === 1 ? 'foodSelect.showingProduct.found' : 'foodSelect.showingProduct.found_plural', { count: productCount });


// ******** End Filters and Search *********

// ******** Start Filters logic & extract unique filter values & dynamically render *********

   // Function to extract unique filter values from products for top-level properties
   const extractUniqueTopLevelValues = (key: keyof HillsProductType) => {
    const allValues = hillsProducts.map((product) => product[key]);
    // Filter out undefined values, ensure all values are strings, and normalize them
    const filteredValues = allValues
      .filter((value): value is string => typeof value === 'string')
      .map(normalizeStringValue)
      .map(capitalizeWords) // Capitalize the first letter of each word
      .filter(value => value.split(' ').length > 1); // Exclude single-word brand names

return Array.from(new Set(filteredValues)).sort((a, b) => a.localeCompare(b));
  };

  const extractUniqueArrayValues = (key: keyof HillsProductType) => {
    // Ensure that we're only dealing with arrays of strings
    const allValues = hillsProducts.flatMap((product) => {
      const value = product[key];
      return Array.isArray(value) ? value : [];
    });
    
    const uniqueValues = Array.from(new Set(allValues)).sort();
    return uniqueValues;
  };

  // Function to extract unique filter values from the tagsMap object
  const extractUniqueTagsMapValues = (key: keyof HillsProductType['tagsMap']) => {
    const allValues = hillsProducts.flatMap((product) =>
      product.tagsMap && product.tagsMap[key] ? product.tagsMap[key] : []
    );
    // Flatten the array, filter out undefined values, and capitalize words
    const filteredValues = allValues
      .flat()
      .filter((value): value is string => typeof value === 'string')
      .map(normalizeStringValue) // Normalize the string values
      .map(capitalizeWords); // Capitalize the first letter of each word
        return Array.from(new Set(filteredValues)).sort();
    };
  

// Function to handle filter selection
const handleFilterSelect = (category: keyof typeof selectedFilters, filter: string) => {
  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [category]: prevFilters[category].includes(filter)
      ? prevFilters[category]
      : [...prevFilters[category], filter],
  }));
};

 // Flatten the selected filters into a single array for rendering the chips
 const allSelectedFilters = Object.entries(selectedFilters).flatMap(([category, filters]) =>
 filters.map((filter) => ({ category, filter }))
);

// Function to handle filter removal with category and filter
const handleFilterRemove = (category: keyof typeof selectedFilters, filter: string) => {
 setSelectedFilters((prevFilters) => ({
   ...prevFilters,
   [category]: prevFilters[category].filter((f) => f !== filter),
 }));
};

// Function to clear all filters
const clearAllFilters = () => {
  setSelectedFilters({
    brand: [],
    productForm: [],
    lifestage: [],
    healthCategory: [],
    productFamily: [],
  });
  // Collapse all accordions
  setExpandedAccordions({
    brand: false,
    productType: false,
    lifestage: false,
    healthCategory: false,
    additionalNeeds: false,
  });
  setSearchQuery('');
  setSearchInputValue(''); 
};

const handleAccordionToggle = (key: string) => {
  setExpandedAccordions((prevExpanded) => ({
    ...prevExpanded,
    [key]: !prevExpanded[key],
  }));
};

return (
  <Modal
    modalConfig={{
      btnConfig: [],
      customClass: "h-full w-full lg:w-10/12 max-w-screen-2xl lg:ml-64",
      customBodyClass: "pt-0 bg-white",
      body: (
        <Form
          onSubmit={onSelection}
          render={({ handleSubmit }) => (
          <div className="min-h-screen min-w-full bg-white">
            <form id="foodSelectionForm" onSubmit={handleSubmit} role="form">
              <div className="flex flex-col lg:flex-row">
                {/* Title (first row on mobile/tablet) */}
                <div className="flex items-center justify-between p-4 lg:hidden">
                  <h2 className="text-lg font-semibold">
                    {t("foodSelect.selectFood", { petName: petName })} 
                  </h2>
                  {/* Close button is from the reusable Modal component */}
                </div>
                {/* Search bar (second row on mobile/tablet) */}
                <div className="p-4 shadow-md lg:hidden">
                <SearchBar
                  onSearchInputChange={handleSearchInputChange}
                  onSearchExecute={handleSearchExecute}
                  searchSuggestions={searchSuggestions}
                  setSearchQuery={setSearchQuery}
                  inputValue={searchInputValue}
                  setInputValue={setSearchInputValue}
                />
                </div>
                {/* Filters button and products count (third row on mobile/tablet) */}
                <div className="flex flex-wrap items-center justify-between p-4 pb-1 lg:hidden">
                  {showMobileFilters ? (
                    <h2 className="text-lg font-semibold">Filters</h2>
                  ) : (
                    <button
                      className="flex items-center rounded-md border border-gray-800 p-2 font-semibold"
                      onClick={() => {
                        setShowMobileFilters(!showMobileFilters);
                      }}
                    >
                      <span className="mr-2">{filterIcon}</span>
                      Filters
                      <span className="ml-2">
                        ({Object.values(selectedFilters).flat().length})
                      </span>
                    </button>
                  )}
                  <div className="font-light">
                      {showingProduct}
                    </div>
                  {productCount === 0 && (
                    <div className="flex flex-col items-center justify-center">
                      <h2 className="text-3xl font-semibold">
                        {t("foodSelect.noProductsFound")}
                      </h2>
                      <p className="mt-4 text-xl">
                        {t("foodSelect.tryClearFilterSearch")}
                      </p>
                    </div>
                  )}
                </div>

                {/* Chips and tooltip (displayed beneath the Filters button on mobile/tablet) */}
              {!showMobileFilters && (
                <div className="flex flex-wrap items-center gap-1 p-4 pt-1 lg:hidden">
                  {allSelectedFilters.map(({ category, filter }) => {
                    let translatedFilterLabel;

                    if (category === 'brand') {
                      // For brand names, use the filter value directly
                      translatedFilterLabel = filter;
                    } else {
                      // For other categories, attempt to translate the filter value
                      const translationKey = `lifestage.${filter}`;
                      translatedFilterLabel = t(translationKey) !== translationKey ? t(translationKey) : filter;
                    }   

                  return(
                    <Chip
                      key={`${category}-${filter}`}
                      label={translatedFilterLabel}
                      onClose={() =>
                        handleFilterRemove(
                          category as keyof typeof selectedFilters,
                          filter
                        )
                      }
                    />
                    );
                  })}

                  
                  {/* will revisit post MLP with pre-selected filters */}
                    {/* Conditionally render the FoodSelectTooltip next to the chip
                    {allSelectedFilters.length === 1 && (
                      <ToolTip description={t("foodSelect.filterToolTip")} />
                    )} */}
                </div>
              )}

                {/* Mobile Filters section (covers the entire viewport on mobile/tablet when showMobileFilters is true) */}
                {showMobileFilters && (
                  <div className="fixed inset-0 z-50 bg-white overflow-y-auto max-h-[calc(100%-4rem)] lg:hidden">
                    <div className="flex items-center justify-between p-4">
                      <h3 className="flex items-center space-x-2 text-lg font-semibold lg:hidden">
                        {filterIcon}
                        <span>Filters</span>
                      </h3>
                    </div>
                    {/* X icon close button */}
                    <div className="absolute top-0 right-0 p-4 text-lg">
                      <button
                        type="button"
                        onClick={() => setShowMobileFilters(false)}
                      >
                        <CloseIcon className="h-6 w-6" />
                      </button>
                    </div>
                    <div className="flex flex-wrap gap-2 p-4">
                      {allSelectedFilters.map(({ category, filter }) => {
                      
                      let translatedFilterLabel;

                      if (category === 'brand') {
                        // For brand names, use the filter value directly
                        translatedFilterLabel = filter;
                      } else {
                        // For other categories, attempt to translate the filter value
                        const translationKey = `lifestage.${filter}`;
                        translatedFilterLabel = t(translationKey) !== translationKey ? t(translationKey) : filter;
                      }   
                      return (
                        <Chip
                          key={`${category}-${filter}`}
                          label={translatedFilterLabel}
                          onClose={() =>
                            handleFilterRemove(
                              category as keyof typeof selectedFilters,
                              filter
                            )
                          }
                        />
                        );
                      })}
                    </div>
                    {filterConfigs.map(
                      ({
                        key,
                        isFirstAccordion,
                        useTopLevelExtraction,
                      }) => {
                        {/* will revisit post MLP for Additional needs section */}
                        // If the key is "productFamily" which correlates to "additionalNeeds", skip rendering this Accordion
                        if (key === "productFamily") {
                          return null; 
                        } 
                        
                        // Translate the title using the translation key from the JSON files
                        const translatedFilterTitle = t(`foodSelect.filters.${key}`);

                        let labels: string[];
                        if (useTopLevelExtraction && key === "brand") {
                          labels = extractUniqueTopLevelValues("brand");
                        } else if (key === "healthCategory") {
                          // Use extractUniqueArrayValues for "healthCategory"
                          labels = extractUniqueArrayValues(key as keyof HillsProductType);
                        } else {
                          labels = extractUniqueTagsMapValues(
                            key as keyof HillsProductType["tagsMap"]
                          );
                        }

                        return (
                          <Accordion
                            keyToMap={key}
                            title={translatedFilterTitle}
                            expanded={expandedAccordions[key]}
                            onToggle={() => handleAccordionToggle(key)}
                            labels={labels}
                            selectedFilters={selectedFilters[key]}
                            onFilterSelect={(filter) =>
                              handleFilterSelect(key, filter)
                            }
                            onFilterRemove={(filter) =>
                              handleFilterRemove(key, filter)
                            }
                            isFirstAccordion={isFirstAccordion}
                          />
                        );
                      }
                    )}

                    {/* Bottom buttons on filters view */}
                    <div className="fixed bottom-0 w-full border-t border-gray-200 bg-white">
                      <div className="flex justify-center space-x-4 p-4 md:justify-end">
                        <button
                          type="button"
                          className="h-10 rounded border border-brand-color-library-blue-500 px-4 py-2 font-semibold text-brand-color-library-blue-500"
                          onClick={clearAllFilters}
                        >
                          {t("foodSelect.clearAll")}
                        </button>
                        <button
                          className="w-45 h-10 flex-shrink-0 rounded bg-brand-color-library-blue-500 px-4 py-2 font-semibold text-white"
                          onClick={() => setShowMobileFilters(false)}
                        >
                          {showingProduct}
                        </button>
                      </div>
                    </div>
                    {/* End Bottom buttons on filters view */}
                  </div>
                )}

                <div className="lg:hidden">
                  {isDataLoading ? (
                    <Loader />
                  ) : (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {filteredProducts.map((product: HillsProductType) => (
                        <ProductCard
                          key={product?.id}
                          id={product?.id}
                          primaryProductImageUrl={
                            product?.primaryProductImageUrl
                          }
                          name={product?.name}
                          classes=""
                          productDesc={product?.productDesc}
                          select={t("foodSelect.select") as string} 
                          children=""
                          buttonCallback={() => onSelection(product)}
                        />
                      ))}
                    </div>
                  )}
                </div>


  {/* Desktop Section */}
                {/* Left section with filters (1/3 width on desktop) */}
                <div className="hidden pl-10 lg:block lg:w-1/3 lg:border-gray-200">
                  <div className="flex justify-between py-4">
                    <h3 className="font-semibold">{t("foodSelect.selectedFilters")}</h3>
                    <button
                      type="button"
                      className="font-medium text-blue-500 underline"
                      onClick={clearAllFilters}
                    >
                      {t("foodSelect.clearAll")}
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2 p-4">
                    {allSelectedFilters.map(({ category, filter }) => {
                    let translatedFilterLabel;

                    if (category === 'brand') {
                      // For brand names, use the filter value directly
                      translatedFilterLabel = filter;
                    } else {
                      // For other categories, attempt to translate the filter value
                      const translationKey = `lifestage.${filter}`;
                      translatedFilterLabel = t(translationKey) !== translationKey ? t(translationKey) : filter;
                    }                  
                      
                    return(
                        <Chip
                          key={`${category}-${filter}`}
                          label={translatedFilterLabel}
                          onClose={() =>
                            handleFilterRemove(
                              category as keyof typeof selectedFilters,
                              filter
                            )
                          }
                        />
                      );
                    })}

                    {/* will revisit post MLP with pre-selected filters */}
                    {/* Conditionally render the FoodSelectTooltip next to the chip
                    {allSelectedFilters.length === 1 && (
                      <ToolTip description={t("foodSelect.filterToolTip")} />
                    )} */}
                  </div>
                  {filterConfigs.map(
                    ({
                      key,
                      isFirstAccordion,
                      useTopLevelExtraction,
                    }) => {
                       {/* will revisit post MLP for Additional needs section */}
                       // If the key is "productFamily" which correlates to "additionalNeeds", skip rendering this Accordion
                      if (key === "productFamily") {
                        return null; 
                      }          

                      // Translate the title using the translation key from the JSON files
                      const translatedFilterTitle = t(`foodSelect.filters.${key}`);          

                      let labels: string[];
                      if (useTopLevelExtraction && key === "brand") {
                        labels = extractUniqueTopLevelValues("brand");
                      } else if (key === "healthCategory") {
                        // Use extractUniqueArrayValues for "healthCategory"
                        labels = extractUniqueArrayValues(key as keyof HillsProductType);

                      }  else {
                        labels = extractUniqueTagsMapValues(
                          key as keyof HillsProductType["tagsMap"]
                        );
                      }

                      return (
                        <Accordion
                          keyToMap={key}
                          title={translatedFilterTitle}
                          expanded={expandedAccordions[key]}
                          onToggle={() => handleAccordionToggle(key)}
                          labels={labels}
                          selectedFilters={selectedFilters[key]}
                          onFilterSelect={(filter) =>
                            handleFilterSelect(key, filter)
                          }
                          onFilterRemove={(filter) =>
                            handleFilterRemove(key, filter)
                          }
                          isFirstAccordion={isFirstAccordion}
                        />
                      );
                    }
                  )}
                </div>

                {/* Right section with product cards (2/3 width on desktop) */}
                <div className="hidden lg:block lg:w-2/3">
                  <div className="p-4 pl-10">
                    <h2 className="text-2xl font-bold">
                      {t("foodSelect.selectFood", { petName: petName })} 
                    </h2>
                  </div>
                  <div className="p-4 pl-0">
                  <SearchBar
                  onSearchInputChange={handleSearchInputChange}
                  onSearchExecute={handleSearchExecute}
                  searchSuggestions={searchSuggestions}
                  setSearchQuery={setSearchQuery}
                  inputValue={searchInputValue}
                  setInputValue={setSearchInputValue}
                />
                  </div>
                  <div className="font-light pl-10">
                    {showingProduct}
                  </div>
                  <div className="p-4">
                    {productCount === 0 && (
                      <div className="flex flex-col items-center justify-center">
                        <h2 className="text-3xl font-semibold">
                          {t("foodSelect.noProductsFound")}
                        </h2>
                        <p className="mt-4 text-xl">
                          {t("foodSelect.tryClearFilterSearch")}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    {isDataLoading ? (
                      <Loader />
                    ) : (
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {filteredProducts.map((product: HillsProductType) => (
                          <ProductCard
                            key={product?.id}
                            id={product?.id}
                            primaryProductImageUrl={
                              product?.primaryProductImageUrl
                            }
                            name={product?.name}
                            classes=""
                            productDesc={product?.productDesc}
                            select={t("foodSelect.select") as string} 
                            children=""
                            buttonCallback={() => onSelection(product)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            </div>
          )}
        />
      ),
    }}
    dialogState={open}
    setDialog={setOpen}
  />
);
};

export default FoodSelectionModal;