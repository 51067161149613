import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import AsyncSelect from "react-select/async";

import { BreedType } from "../../../../../types";
import { ADD_EDIT_PET } from "../../../../constants/addEditPet";
import { usePetConfig } from "../../../../hooks/usePetConfig";
import { useTranslation } from "react-i18next";

export default function BreedSelection({
  setPetSize,
  petTypeId,
}: {
  setPetSize: (value: BreedType | null) => void;
  petTypeId: number;
}) {
  const { breeds } = usePetConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [petTypeBreeds, setPetTypeBreeds] = useState<BreedType[]>([]);
  const { t } = useTranslation();

  const filterBreeds = (inputValue: string) => {
    return petTypeBreeds.filter(
      (breed) =>
        t(`petBreeds.${breed.petBreedName}`)
          ?.toLowerCase()
          .indexOf(inputValue?.toLowerCase()) > -1
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: BreedType[]) => void
  ) => {
    setIsLoading(true);
    setTimeout(() => {
      const filteredBreeds = filterBreeds(inputValue);
      callback(filteredBreeds);
      setIsLoading(false);
    }, 1000);
  };

  function onInputChange(newValue: string) {
    return newValue.length > 2 ? newValue : "";
  }

  useEffect(() => {
    if (petTypeId && breeds.length) {
      const filteredBreeds = breeds
        .filter((breed) => breed.petTypeId === petTypeId)
        .sort((a, b) =>
          t(`petBreeds.${a.petBreedName}`).localeCompare(
            t(`petBreeds.${b.petBreedName}`)
          )
        );

      setPetTypeBreeds(filteredBreeds);
    }
  }, [breeds, petTypeId]);

  return (
    <div className="mt-2 mb-7 gap-1">
      <label
        className="self-baseline text-xl font-bold"
        htmlFor="petBreedSelection"
      >
        {t("addEditPet.petsBreed")}
      </label>
      <Field
        name="petBreed"
        id="petBreed"
        placeholder={t("addEditPet.searchBreed")}
      >
        {({ input, meta, ...rest }) => (
          <>
            {petTypeBreeds?.length ? (
              <AsyncSelect
                key={petTypeId}
                {...input}
                {...rest}
                inputId="petBreedSelection"
                onChange={(e) => {
                  input.onChange(e);
                  setPetSize(e || null);
                }}
                cacheOptions
                defaultOptions={petTypeBreeds}
                classNamePrefix="breed"
                loadOptions={loadOptions}
                isSearchable={true}
                isClearable={true}
                getOptionValue={(item: BreedType) => item.petBreedId.toString()}
                getOptionLabel={(item: BreedType) =>
                  t(`petBreeds.${item.petBreedName}`)
                }
                onInputChange={onInputChange}
                isLoading={isLoading}
                openMenuOnFocus={true}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#C6C7CC",
                  },
                })}
                styles={{
                  control: (base: object) => ({
                    ...base,
                    marginTop: "12px",
                    height: 50,
                    minHeight: 50,
                    borderColor:
                      meta.error && meta.submitFailed ? "#C4132A" : "#C6C7CC",
                    "&:hover": {
                      borderColor: "",
                    },
                    "&:focus": {
                      borderColor: "#C6C7CC",
                    },
                  }),
                }}
                aria-describedby="Breed Selection"
              />
            ) : null}
            <div className="mt-1 h-4">
              {meta.error && meta.submitFailed && (
                <div className="mb-2 text-xs text-brand-error">
                  {t(ADD_EDIT_PET.PET_BREED_ERROR)}
                </div>
              )}
            </div>
          </>
        )}
      </Field>
    </div>
  );
}
