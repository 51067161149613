import { useEffect, useState } from "react";

export const useScrollDirection = () => {
  const [visible, setVisible] = useState(false);
  const isMobileScreen = window.innerWidth <= 1023; // Adjust the breakpoint as needed

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.pageYOffset;
      const scrollThresholdShow = isMobileScreen ? 16 : 0;

      if (scrollY > scrollThresholdShow) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    const handleResize = () => {
      if (!isMobileScreen) {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileScreen]);

  return { visible };
};
