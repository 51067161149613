import { Form } from "react-final-form";
import { MESSAGES, SNOWFLAKE } from "../../constants";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { deletePets } from "./utils";
import { PetObject } from ".";
import { usePetsApiCall } from "../../hooks/use-apiCall";
import { useSnowFlakePets } from "../../hooks/useSnowflakeData";
import { DogCat } from "../../icons";
import { useToastConfig } from "../../lib/toast";
import { useState } from "react";

type FromScratchModalProps = {
    setStartFromScratchModal: (val: boolean) => void;
    setShowSfModal: (val: boolean) => void;
    snowflakePets: PetObject;
}


const FromScratchModal = ({ setStartFromScratchModal, setShowSfModal, snowflakePets }: FromScratchModalProps) => {

    const { t } = useTranslation();
    const { fetchSnowflakePets } = useSnowFlakePets();
    const { addEditPet } = usePetsApiCall();
    const { showToast } = useToastConfig();
    const [isLoading, setIsLoading] = useState(false);

    const goBack = () => {
        !isLoading && setStartFromScratchModal(false);
    }

    const close = async () => {
        //show loader and delete all the pets
        //[here]
        setIsLoading(true);
        const error = await deletePets(snowflakePets?.newPets,addEditPet);
        if(error.length){
            showToast({
                title: `${t(MESSAGES.ERROR)}`,
                description: `${t(MESSAGES.ERROR)}`,
                type: "error",
            });
        }
        setIsLoading(false);
        setStartFromScratchModal(false);
        setShowSfModal(false);
        fetchSnowflakePets();
    }
    return (

        <Form
            onSubmit={close}
            render={({ handleSubmit }) => {
                return (
                    <form id="startfromscratch" onSubmit={handleSubmit} role="form">
                        <div className="p-5">
                            <div className="flex flex-col items-center h-full">
                                {/* {header} */}
                                <div className="w-full">
                                    <div
                                        className="flex items-center justify-center p-1 text-center text-2xl font-bold lg:mx-28 lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {DogCat}
                                    </div>
                                    <div
                                        className="flex items-center justify-center px-0 text-center text-2xl font-bold lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {t(SNOWFLAKE.START_FROM_SCRATCH_MODAL_TITLE)}
                                    </div>
                                </div>

                                {/* {body} */}
                                <div className="flex flex-row justify-start overflow-y-auto overflow-x-hidden w-full px-8 md:px-12">
                                    <div className=" h-1/5 w-full">
                                        <div>{t(SNOWFLAKE.START_FROM_SCRATCH_MODAL_PARA_1)}</div>
                                        <div className="mt-5">{t(SNOWFLAKE.START_FROM_SCRATCH_MODAL_PARA_2)}</div>
                                    </div>
                                </div>

                                {/* {footer} */}
                                <div className="mt-auto w-full">
                                    <div className="flex flex-col px-8 md:px-12 mb-2 mt-8">
                                        <div className="flex flex-col items-center">
                                            <Button
                                                type="submit"
                                                mode="contained"
                                                variant="primary"
                                                   isLoading={isLoading}
                                                classes="lg:mt-2 w-full sm:w-96 py-4 font-bold text-xl mb-1 bg-white border border-[#C4132A] text-[#C4132A] mt-2 flex justify-center"
                                            >
                                                {t(SNOWFLAKE.START_FROM_SCRATCH_MODAL_ACTION_1)}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                mode="contained"
                                                onClick={goBack}
                                                variant="primary"
                                                classes="lg:mt-2 w-full py-4 font-bold text-xl mb-1 bg-white text-[#0054A4] mt-2"
                                            >
                                                {t(SNOWFLAKE.START_FROM_SCRATCH_MODAL_ACTION_2)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                               </div>
                        </div>
                    </form>
                );
            }}
        />
    )
}

export default FromScratchModal;