export const NOTIFICATION = {
    REBATE_HEADER: "notification.rebateHeader",
    REBATE_BODY: "notification.rebateBody",
    REBATE_PRIMARY_ACTION: "notification.rebatePrimaryAction",
    REBATE_SECONDARY_ACTION: "notification.rebateSecondaryAction",
    REBATE_CHECKBOX_LABEL: "notification.rebateCheckboxLabel"
}

export const NOTIFICATION_TYPE = {
    REBATE: 1
}