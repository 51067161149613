import { useEffect, useState } from "react";
import { Field, FieldInputProps, FieldMetaState } from "react-final-form";
import { PetTypesByNames } from "../../../../../types";
import { useTranslation } from "react-i18next";
import { ADD_EDIT_PET } from "../../../../constants/addEditPet";
import { usePetConfig } from "../../../../hooks/usePetConfig";
import { PetSizeType } from "../../../../../types";

interface BreedSize {
  petTypeId: number;
}

const RenderFieldData = ({
  input,
  meta,
  size,
  petTypeId,
  petTypesByName,
  setErrorState,
}: {
  input: FieldInputProps<string, HTMLElement>;
  meta: FieldMetaState<string>;
  size: string;
  petTypeId: number;
  petTypesByName: PetTypesByNames;
  setErrorState: (val: boolean) => void;
}) => {
  const { t } = useTranslation();

  const dogBreedSizeHelperMap = {
    Small: t("addEditPet.smallWeightRangeDog"),
    Medium: t("addEditPet.mediumWeightRangeDog"),
    Large: t("addEditPet.largeWeightRangeDog"),
  };
  const catBreedSizeHelperMap = {
    Small: t("addEditPet.smallWeightRangeCat"),
    Medium: t("addEditPet.mediumWeightRangeCat"),
    Large: t("addEditPet.largeWeightRangeCat"),
  };
  useEffect(() => {
    meta.error && meta.submitFailed
      ? setErrorState(true)
      : setErrorState(false);
  }, [meta.error, meta.submitFailed]);
  return (
    <div className="mt-5 grow flex-col lg:mt-0">
      <input {...input} id={size} className="black peer sr-only" />
      <label
        className="flex grow cursor-pointer justify-center rounded-lg border border-brand-primary py-3 font-bold text-brand-primary hover:bg-blue-100 focus:outline-none  peer-checked:bg-blue-100"
        htmlFor={size}
      >
        {petTypeId.toString() === petTypesByName.Dog.toString()
          ? dogBreedSizeHelperMap[size as keyof unknown]
          : catBreedSizeHelperMap[size as keyof unknown]}
      </label>
    </div>
  );
};

export default function BreedSize({ petTypeId }: BreedSize) {
  const { petSizes, petTypesByName } = usePetConfig();
  const [hasError, setError] = useState(false);
  const { t } = useTranslation();

  const setErrorState = (val: boolean) => {
    val ? setError(true) : setError(false);
  };
  return (
    <div className="mt-2 mb-2">
      <label htmlFor="petSizeId" className="self-baseline text-xl font-bold">
        {t(ADD_EDIT_PET.PET_SIZE)}
      </label>
      <div className="mb-1 h-4">
        {hasError && (
          <div className="mb-2 text-xs text-brand-error">
            {t(ADD_EDIT_PET.PET_SIZE_ERROR)}
          </div>
        )}
      </div>
      <div className="flex-row gap-5 lg:flex">
        {petSizes.map((size: PetSizeType) => (
          <Field
            name="petSizeId"
            type="radio"
            value={size.petSizeId}
            key={size.petSizeId}
          >
            {({ input, meta }) => {
              return (
                <RenderFieldData
                  input={input}
                  meta={meta}
                  size={size.petSizeName}
                  petTypeId={petTypeId}
                  petTypesByName={petTypesByName}
                  setErrorState={setErrorState}
                />
              );
            }}
          </Field>
        ))}
      </div>
    </div>
  );
}
