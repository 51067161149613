import { AppProtectedLayout, SideMenu } from "ui";
import { useTranslation } from "react-i18next";
import { SIDEMENU_HEADERS, SIDEMENU_ITEMS } from "../../constants";
import useRequireAuth from "../../hooks/useRequireAuth";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import { CustomNavLink } from "../CustomNavLink";
import { PetList } from "../Pet";
import ProfilePhotoUpload from "../Profile/ProfilePhotoUpload";
import { RecommendedArticles } from "../RecommendedArticles";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import PetLoader from "../PetLoader";

export default function DashBoard() {
  const auth = useRequireAuth();
  const { visible } = useScrollDirection();
  const { t } = useTranslation();
  const { recommendedArticles } = useFeatureFlagsConfig();

  if (!auth || !auth?.user?.UID) return <PetLoader/>;

  return (
    <AppProtectedLayout
      sideMenu={
        <SideMenu
          items={SIDEMENU_ITEMS}
          render={(menuItem, iconOnly) => (
            <CustomNavLink linkItem={menuItem} iconOnly={iconOnly} />
          )}
          profilePic={
            <ProfilePhotoUpload photoUrl={auth?.user?.profile?.photoURL} />
          }
          subHeading={
            t(SIDEMENU_HEADERS.subHeading) + " " + auth.user?.profile?.firstName
          }
          isMobileMenuVisible={visible}
        />
      }
      content={
        <>
          <PetList />
          {recommendedArticles && <RecommendedArticles />}
        </>
      }
    />
  );
}
