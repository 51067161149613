import React from 'react';
import { chevronDown, chevronUp } from '../../../../icons/chevronUpDown';

interface FeedingGuideTitleProps {
  title: string;
  isOpen?: boolean;
  toggleAccordion?: () => void;
}

const FeedingGuideTitle: React.FC<FeedingGuideTitleProps> = ({ title, isOpen, toggleAccordion }) => {
    return (
      <>
        <div className="flex lg:w-4/5 justify-between items-center cursor-pointer" onClick={toggleAccordion}>
          <h3 className="text-md text-brand-gray-light font-semibold">{title}</h3>
          <div className="pr-6">{isOpen ? chevronUp : chevronDown}</div>
        </div>
        {/* Gray line beneath the row */}
        <hr className="lg:w-3/4 mt-2 border-t border-gray-300"/>
      </>
    );
  };

export default FeedingGuideTitle;