import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { MESSAGES, SNOWFLAKE } from "../../constants";
import { PetType } from "../../../types";
import { PetObject } from "./index";
import PetList from "./PetList";
import { useEffect, useRef, useState } from "react";
import { ModPetType, addUpdatePets } from "./utils";
import { usePetsApiCall } from "../../hooks/use-apiCall";
import { DogCat } from "../../icons";
import { useToastConfig } from "../../lib/toast";

const LeftOutPetModal = ({
    selectedPets,
    snowflakePets,
    setOpenleftOutPetModal,
    setShowSnowFlakeModal,
    setShowPetsAddedSuccessModal
}: {
    selectedPets: Array<PetType>;
    snowflakePets: PetObject,
    setOpenleftOutPetModal: (val: boolean) => void;
    setShowSnowFlakeModal: (val: boolean) => void;
    setShowPetsAddedSuccessModal: (val: boolean) => void;
}) => {
    const [leftOutPets, setLeftOutPets] = useState<Array<ModPetType>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { addEditPet, updatePetApi } = usePetsApiCall();
    const { t } = useTranslation();
    const restrictPetsApiCall = useRef(true);
    const { showToast } = useToastConfig();

    const makeAddDeletePetApiCall = async () => {
        const error = await addUpdatePets(selectedPets, updatePetApi, addEditPet, leftOutPets);
        if (error.length) {
            showToast({
                title: `${t(MESSAGES.ERROR)}`,
                description: `${t(MESSAGES.ERROR)}`,
                type: "error",
            });
        }else{
            showToast({
              title: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
              description: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
              type: "success",
            });
          }
          return error;
    }

    const loadSuccessScreen = async () => {
        setIsLoading(true);
        const error = await makeAddDeletePetApiCall();
        setIsLoading(false);
        setOpenleftOutPetModal(false);
        if (error.length === 0) {
            setShowPetsAddedSuccessModal(true); 
        }
    }

    useEffect(() => {
        const leftOutPets = snowflakePets?.newPets?.filter((sfPet) => {
            const pet = selectedPets.find((pet: ModPetType) => pet.petName.toLocaleLowerCase() === sfPet.petName.toLocaleLowerCase());
            if (!pet) return true
        })
        if (leftOutPets?.length > 0) {
            setLeftOutPets(leftOutPets);
        } else {
            if (restrictPetsApiCall.current) {
                restrictPetsApiCall.current = false;
                loadSuccessScreen();
            }
        }
    }, [snowflakePets]);

    const removeAndContinue = async () => {
        setIsLoading(true);
        const error = await makeAddDeletePetApiCall();
        setLeftOutPets([]);
        setIsLoading(false);
        setOpenleftOutPetModal(false);
        setShowSnowFlakeModal(false);
        if (error.length === 0) {
            setShowPetsAddedSuccessModal(true); 
        }
    }

    const goBack = () => {
        !isLoading && setOpenleftOutPetModal(false);
    }

    return (
        <div className="flex items-end">
            <Form
                onSubmit={removeAndContinue}
                render={({ handleSubmit }) => {
                    return (
                        <form id="deletePetConfirmation" onSubmit={handleSubmit} role="form">
                            <div className="p-5">
                                <div className="flex flex-col items-center h-full">
                                    {/* {header} */}
                                    <div className="w-full">
                                        <div
                                            className="flex items-center justify-center p-1 text-center text-2xl font-bold lg:mx-28 lg:px-9"
                                            data-testid="modal-title"
                                        >
                                            {DogCat}
                                        </div>
                                        <div
                                            className="flex items-center justify-center px-0 text-center text-2xl font-bold lg:px-9"
                                            data-testid="modal-title"
                                        >
                                            {t(SNOWFLAKE.LEFT_OUT_PET_MODAL_TITLE)}
                                        </div>

                                        <div className="flex flex-col items-start justify-start py-4 px-8 md:px-12">
                                            <div>
                                                {t(SNOWFLAKE.LEFT_OUT_PET_MODAL_PARA_1)}
                                            </div>
                                        </div>
                                    </div>

                                    {/* {body} */}
                                    <div className="flex flex-row justify-start w-full p-8 md:px-12">
                                        <div className=" h-1/5 w-full">
                                            <PetList leftOutPets={leftOutPets} isEditable={false} hphPets={[]} snowflakePets={snowflakePets} />
                                        </div>
                                    </div>

                                    {/* {footer} */}
                                    <div className="mt-auto">
                                        <div className="flex flex-col px-8 md:px-12 m-2">
                                            <div className="flex flex-col items-center">
                                                <div className="p-5">
                                                    {t(SNOWFLAKE.LEFT_OUT_PET_MODAL_PARA_2)}
                                                </div>
                                                <Button
                                                    type="submit"
                                                    mode="contained"
                                                    variant="primary"
                                                    isLoading={isLoading}
                                                    classes="lg:mt-2 w-full py-4 font-bold text-xl px-4 mb-5 flex justify-center"
                                                    disabled={selectedPets.length === 0}
                                                >
                                                    {t(SNOWFLAKE.LEFT_OUT_PET_MODAL_ACTION_1)}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    mode="contained"
                                                    variant="primary"
                                                    onClick={goBack}
                                                    classes="lg:mt-2 w-full py-4 font-bold text-xl mb-1 bg-white text-[#0054A4] mt-2"
                                                >
                                                    {t(SNOWFLAKE.LEFT_OUT_PET_MODAL_ACTION_2)}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>


                    );
                }}
            />
        </div>
    );
};

export default LeftOutPetModal;


