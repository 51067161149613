import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { FOOD_BRANDS, IFOOD_BRANDS } from '../../../../constants/foodSelectOtherBrands';
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { ADD_EDIT_PET } from "../../../../constants/addEditPet";

export default function BrandSelection({
    setFoodTypeDetails, selectedRegion
}: {
    setFoodTypeDetails: (value: IFOOD_BRANDS | null) => void;
    selectedRegion: string | undefined;
}) {
    const [foodBrands, setFoodBrands] = useState<IFOOD_BRANDS[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        if (FOOD_BRANDS && selectedRegion) {
            setFoodBrands(
                FOOD_BRANDS.filter((brand: IFOOD_BRANDS) =>
                    brand.region.includes(selectedRegion)
                )
            );
        }
    }, [FOOD_BRANDS, selectedRegion]);

    return (
        <div className="mt-5 mr-10">
            <Field
                name="petFoodTypeDetails"
                id="petFoodTypeDetails"
            >
                {({ input, meta, ...rest }) => (
                    <>
                        {foodBrands?.length ? (
                            <AsyncSelect
                                {...input}
                                {...rest}
                                placeholder={t("addEditPet.searchBrand")}
                                inputId="brandSelection"
                                onChange={(e) => {
                                    input.onChange(e);
                                    setFoodTypeDetails(e);
                                }}
                                cacheOptions
                                isClearable={true}
                                defaultOptions={foodBrands}
                                classNamePrefix="brands"
                                getOptionValue={(item: IFOOD_BRANDS) => item.brandName.toString()}
                                getOptionLabel={(item: IFOOD_BRANDS) => item.brandName.toString()}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#C6C7CC",
                                    },
                                })}
                                styles={{
                                    control: (base: object) => ({
                                        ...base,
                                        marginTop: "12px",
                                        height: 50,
                                        minHeight: 50,
                                        "&:hover": {
                                            borderColor: "",
                                        },
                                        "&:focus": {
                                            borderColor: "#C6C7CC",
                                        },
                                    }),
                                }}
                                aria-describedby="Brand Selection"
                            />
                        ) : (<div className="mb-2 text-xs text-brand-error">
                            {t(ADD_EDIT_PET.PET_FOOD_COMMON_ERROR)}</div>)
                        }
                        <div>
                            {meta.error && meta.submitFailed && (
                                <div className="mb-2 text-xs text-brand-error">
                                    {t(ADD_EDIT_PET.PET_FOOD_BRAND_ERROR)}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Field>
        </div>
    )
}