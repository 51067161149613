/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, IconBigCalendar, IconFoodFinder, IconEditSquareOutline, Loader } from "ui";
import { calculateAgeApprox, useApi, prepareFormDataWithImage } from "utils";
import { BreedType, PetFoodTypes, PetType, PetTypes } from "../../../../../types";
import {
  PET,
  PET_FOOD_FINDER,
  PET_FOOD_FINDER_FR_V2,
  ROUTES_PATH, 
} from "../../../../constants";
import { usePetConfig } from "../../../../hooks/usePetConfig";
import PetProfilePhoto from "../../PetProfilePhoto";
import { PAGE_ACTIONS } from "../../../../constants/actions";
import { ADD_EDIT_PET } from "../../../../constants/addEditPet";
import { useFeatureFlagsConfig } from "../../../../hooks/useFeatureFlags";
import { alert } from "../../../../icons/alert";
import DeceasedPet from "../../../DeceasedPet";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { API_URLS } from "../../../../constants/apiUrls";
import { HideAndRemovePetApiOption } from "../../types";
import { useToastConfig } from "../../../../lib/toast";
import { getIsPetUpdatedFromSnowflake, prepareObjToSend } from "../../../snowflakeModal/utils";
import { useSnowFlakePets } from "../../../../hooks/useSnowflakeData";
import { useFoodSelectDataContext } from "../../AddEditPet/FoodSelectionModal/Contexts/FoodSelectDataContext";
import FeedingGuideAccordion from '../FeedingGuideAccordion/FeedingGuideAccordion'
import { FeedingGuideTitle } from "../FeedingGuideTitle";

function PetAction({
  icon,
  title,
  buttonText,
  buttonCallback,
  action,
  descText,
  feedingGuideTitle,
  toggleAccordion,
  isAccordionOpen
}: {
  icon: React.ReactNode | null;
  title: string;
  buttonText: string | React.ReactNode;
  buttonCallback: () => void;
  action?: string;
  descText?: string | null;
  feedingGuideTitle?: string;
  toggleAccordion?: () => void;
  isAccordionOpen?: boolean;
}) {
  return (
    <div className={action ? "flex md:gap-x-7" : "flex gap-x-7"}>
      {icon}
      <div className="flex w-full flex-col md:block">
        <h3 className="mb-3 text-center text-base font-bold md:text-left lg:text-xl">
          {title}
        </h3>
        {action === PAGE_ACTIONS.editPetsFood && <p className="lg:w-4/5 text-center text-base md:text-left font-normal">{descText}</p>}
        <Button
          variant="primary"
          mode={action === PAGE_ACTIONS.editPetsFood ? "link" : "outlined"}
          onClick={buttonCallback}
          aria-label={buttonText}
          classes={action === PAGE_ACTIONS.editPetsFood ? "!px-0" : ""}
        >
          {buttonText}
        </Button>
        {feedingGuideTitle && toggleAccordion && (
        <FeedingGuideTitle
          title={feedingGuideTitle}
          isOpen={isAccordionOpen}
          toggleAccordion={toggleAccordion}
        />
      )}
      </div>
    </div>
  );
}

function PetCharacteristic({ title, value }: { title: string; value: string }) {
  const { t } = useTranslation();
  return (
    <h3 className="my-2 text-center text-base font-bold md:my-1">
      <span className="mr-2">{t(`addEditPet.${title.toLowerCase()}`)}</span>
      <span className="font-normal">{title=="Breed" ? (value!="" ? t(`petBreeds.${value}`) : t("pet.notSelectedText")) : value}</span>
    </h3>
  );
}

export function PetName({ name, showIsDeceasedIcon }: { name: string, showIsDeceasedIcon: boolean }) {
  return (
    <div className="flex">
      <div><h4 className="text-lg font-extrabold text-white md:text-2xl truncate capitalize">{name} </h4></div>
      <div className="pl-2 mt-1">{showIsDeceasedIcon ? alert : null}</div>
    </div>
  );
}

const PetDetails = ({ 
  data, 
  fetchPetListData, 
} : { 
  data: PetType, 
  fetchPetListData: () => void;
  }) => {
  const { loadHillsProducts, hillsProducts, isDataLoading } = useFoodSelectDataContext();
  const [foodSelectProduct, setFoodSelectProduct] = useState<{ id: string; image: string; name: string; feedingGuide: string; } | null>(null);
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const petConfig = usePetConfig();
  const { getBreed, petTypes, petFoodTypes, breeds } = petConfig;
  const navigate = useNavigate();
  const { foodSelect, foodSelectQuiz, milestoneTracker, foodSelectQuizCodes,foodSelectQuizV2 } = useFeatureFlagsConfig();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { snowflakePets, fetchSnowflakePets } = useSnowFlakePets();
  const [isSfDataLoading, setIsSfDataLoading] = useState(false);
   // State to control the visibility of the FeedingGuideAccordion
   const [isAccordionOpen, setIsAccordionOpen] = useState(false);
   // Function to toggle the accordion's visibility
   const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);
  const { exec: updatePetApi } = useApi((options: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, options)
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t(
              `addEditPet.${apiResponse.data.serviceStatus.message}`
            )}`,
            type: "success",
          });
          fetchPetListData();
        } else {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });

  useEffect(() => {
    if (!isDataLoading) {
      // Call loadHillsProducts only if the data is not already loading
      loadHillsProducts(data.petTypeId, true);
    }
  }, [data.petTypeId, isDataLoading, loadHillsProducts]);

  useEffect(() => {
    if (!isDataLoading && data.petFoodTypeId === 1 && data.petFoodTypeInfo) {
      // Find the product details based on petFoodTypeInfo
      const productDetails = hillsProducts.find(product => product.id === data.petFoodTypeInfo);
      if (productDetails) {
        setFoodSelectProduct({
          id: productDetails.id,
          image: productDetails.primaryProductImageUrl,
          name: productDetails.name,
          feedingGuide: productDetails.feedingGuide || ''
        });
      }
    }
  }, [data.petFoodTypeId, data.petFoodTypeInfo, hillsProducts, isDataLoading]);

  //callback after  clicking  of  confirm  button
  const removeHidePet = (values: HideAndRemovePetApiOption[]) => {
    updatePetApi(prepareFormDataWithImage({ petData: values }));
  };
  
  const getPetFood = (petTypeId: number) => {
    const petType = petTypes.find(
      (pet: PetTypes) => pet.petTypeId === parseInt(petTypeId.toString())
    );

    if(foodSelectQuizV2){
      const foodQuizUrl =  PET_FOOD_FINDER_FR_V2;
      window.open(foodQuizUrl,"_blank");
    }
    else{
      const foodQuizCode = foodSelectQuizCodes[window.hph_locale] ? foodSelectQuizCodes[window.hph_locale] : null;
      
      if(petType && petType.petTypeName && foodQuizCode){
        const foodQuizUrl =  PET_FOOD_FINDER.replace('{code}',foodQuizCode[petType.petTypeName.toLocaleLowerCase()]);
        window.open(foodQuizUrl,"_blank");
      }
    }

  };
  const getPetFoodType = (petFoodTypeId: number) => {
    const petFoodType = petFoodTypes.find(
      (foodType: PetFoodTypes) => foodType.petFoodTypeId === parseInt(petFoodTypeId.toString())
    );
    return petFoodType;
  }
  const petFoodType = data?.petFoodTypeId ? getPetFoodType(data?.petFoodTypeId): null;

  const showDeceasedView = data.showIsDeceasedIcon || false;
  const { petId, petName, petImage, petBirthday, petBreedId } = data;
  const petBreed = breeds.find(
    (breed: BreedType) => breed.petBreedId === petBreedId
  );

  const navigation = async (pet: PetType) => {
    const isPetUpdated = getIsPetUpdatedFromSnowflake(pet, snowflakePets?.updatedPets as Array<PetType>,petConfig);
    if (isPetUpdated?.length) {
      setIsSfDataLoading(true);
      //make update api call then navigate to pet profile
      const petObjectToSend = prepareObjToSend(isPetUpdated[0]);
      petObjectToSend.petData[0]['petId'] = pet.petId;
      await updatePetApi(prepareFormDataWithImage(petObjectToSend));
      fetchSnowflakePets();
      setIsSfDataLoading(false);
      navigate(ROUTES_PATH.PET, {
        state: { petId: data.petId, action: PAGE_ACTIONS.editFromPetDetails },
        replace: false,
      });
    } else {
      navigate(ROUTES_PATH.PET, {
        state: { petId: pet.petId, showIsDeceased: pet.showIsDeceasedIcon },
        replace: false,
      });
    }
  }

  if (isSfDataLoading) {
    return <Loader />
  }
  return (
    <div className={!showDeceasedView ? "mb-10 grid grid-cols-none md:mb-16 md:grid-cols-8" : ""}>
      {showDeceasedView ? <DeceasedPet
        petId={petId}
        petName={petName}
        petImage={petImage as string}
        petBirthday={petBirthday as string}
        petBreed={petBreed as BreedType}
        removeHidePet={removeHidePet}
        isHomePage={false}
      /> : <>
        <div className="col-span-4 md:col-span-3 md:mr-6">
          <PetProfilePhoto
            src={data.petImage}
            size="md"
            rounded="2xl"
            petName={data.petName}
            classes="lg:mx-0 mx-auto w-48 md:w-80"
            defaultImgClasses="justify-center md:justify-start"
          />
          <div className="grid w-full grid-cols-1 2xl:w-10/12">
            <Button
              aria-label={t(PET.EDIT_PROFILE)}
              mode="link"
              variant="primary"
              onClick={() => {
                navigation(data);
              }
              }
            >
              {t(PET.EDIT_PROFILE)}
            </Button> 

            <hr className="mx-20 my-6 hidden h-px border-0 bg-brand-accent-gray-light md:block"></hr>
            <PetCharacteristic
              title="Breed"
              value={
                (data.petBreedId ? (getBreed && getBreed(data.petBreedId)?.petBreedName) : "") as string
              }
            />
            <PetCharacteristic
              title="Age"
              value={(data.petBirthday ? calculateAgeApprox(data.petBirthday, t) : t("pet.notSelectedText")) as string}
            />
          </div>
        </div>
        <div className="col-span-4 mt-6 flex flex-col gap-y-9 md:col-span-5">
          <p className="mb-0 text-center text-base md:mb-5 md:text-left md:text-lg">
            {t("pet.petToolsDesc")}
          </p>
        { milestoneTracker && <PetAction
            title={t(PET.MILESTONE_BUTTON_HEADING)}
            buttonText={t(PET.MILESTONE_BUTTON_TEXT)}
            icon={<IconBigCalendar className="hidden h-20 md:inline-block" />}
            buttonCallback={() => {
              navigate(
                `${ROUTES_PATH.MILESTONE}/${data.petId}/${data.petLifeStageId}`,
                {
                  replace: false,
                }
              );
            }}
          />}
       { foodSelectQuiz && <PetAction
            title={t(PET.FOOD_QUIZ_HEADING)}
            buttonText={t(PET.FOOD_QUIZ_BUTTON_TEXT)}
            icon={<IconFoodFinder className="hidden h-20 md:inline-block" />}
            buttonCallback={() => {
              getPetFood(data.petTypeId);
            }}
          />}

        {foodSelect && <PetAction
            title={t(PET.CURRENT_FOOD)}
            buttonText={petFoodType ? 
            <>
              <IconEditSquareOutline className="inline-block" />
              <span className="ml-2">{t(PET.EDIT_FOOD)}</span>
            </> 
            : t(PET.SELECT_FOOD)}
            icon={petFoodType?.petFoodTypeId === 1 ? (    
              <img 
                src={foodSelectProduct?.image}
                alt={foodSelectProduct?.name}
                className="h-20 w-20 md:inline-block"
              />
              ) : (
              <div className="md:h-20 md:w-24"></div> 
              )
            }
            buttonCallback={() => {
              navigate(ROUTES_PATH.PET, {
                state: { petId: data.petId, action: PAGE_ACTIONS.editPetsFood },
                replace: false,
              })
            }}
            action={petFoodType ? PAGE_ACTIONS.editPetsFood : PAGE_ACTIONS.selectPetsFood}
            descText={petFoodType?.petFoodTypeId === 1 && foodSelectProduct?.name ? foodSelectProduct.name : (
              petFoodType?.petFoodTypeId === 2 ? data.petFoodTypeInfo :
              petFoodType?.petFoodTypeId === 3 ? t(ADD_EDIT_PET.HOME_MADE) : ""
            )}
            feedingGuideTitle={foodSelectProduct?.feedingGuide ? (t("foodSelect.feedingGuide") || "") : undefined}
            isAccordionOpen={isAccordionOpen} 
            toggleAccordion={toggleAccordion}
          />}
          {/* Render the FeedingGuideAccordion below the PetAction component */}
          {isAccordionOpen && foodSelectProduct?.feedingGuide && (
          <FeedingGuideAccordion
            contentHtml={foodSelectProduct.feedingGuide}
            isOpen={isAccordionOpen} 
          />
        )}
        </div>
      </>
      }
    </div>
  );
};

PetDetails.defaultProps = {
  fetchPetListData: () => {
    return null;
  }
}

export default PetDetails;

