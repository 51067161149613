export const PET = {
  MILESTONE_TRACKER: "pet.milestoneTracker",
  HOME: "pet.home",
  HEADING: "pet.heading",
  PET_DASHBOARD: "pet.petDashboard",
  EDIT_PET: "pet.editPet",
  CHANGE_PHOTO: "pet.changePhoto",
  PASSWORD_CHANGE: "pet.passwordChange",
  MANAGE_PREFERENCES: "pet.managePreferences",
  SAVE: "common.save",
  HIDDEN_PETS_HEADING: "pet.hiddenPetsHeading",
  EDIT_PROFILE: "pet.editProfile",
  MILESTONE_BUTTON_HEADING: "pet.milestoneButtonHeading",
  MILESTONE_BUTTON_TEXT: "pet.milestoneButtonText",
  FOOD_QUIZ_HEADING: "pet.foodQuizHeading",
  FOOD_QUIZ_BUTTON_TEXT: "pet.foodQuizButtonText",
  CURRENT_FOOD: "pet.currentFood",
  EDIT_FOOD: "pet.editFood",
  SELECT_FOOD: "pet.selectFood",
  ADD_A_PET: "pet.addAPet",
  ADD_PET: "pet.addPet",
  SHOW_REMEMBERED_PETS: "pet.showRememberedPets",
  HIDE_REMEMBERED_PETS: "pet.hideRememberedPets",
  ACTION_NEEDED: "pet.actionNeeded",
};
