import data from '../featureFlags/featureFlags.json'

export { ROUTES, ROUTES_PATH, BREADCRUMB_CONFIG } from "./routes";
export { STATES } from "./stateList";
export { MESSAGES } from "./messages";
export { PROFILE } from "./profile";
export { PET } from "./pet";
export { SNOWFLAKE } from "./snowflake";
export { NOTIFICATION } from "./notification";
export * from "./sideMenu";
export const PHONE_TYPES = ["Mobile", "Home", "Office", "Other"];

type ObjectKey = keyof typeof data;
const language = window.hph_locale ? (window.hph_locale.indexOf("-") ? window.hph_locale.split("-")[1] : "en") : "en";

export const DATE_FORMAT = data[language as ObjectKey]?.dateFormat;

export const PREFIX_VALUES = [
  "Mr",
  "Ms",
  "Mx",
  "Mrs",
  "Miss",
  "Master",
  "Madam",
  "Dr",
  "Prof",
];

export const SUFFIX_VALUES = ["Jr", "Sr", "II", "III", "IV", "V"];

export const MAX_CHAR_NAME = 50;
export const MAX_CHAR_ADDRESS = 60;
export const ZIP_CODE_LENGTH = 5;
export const PHONE_NUMBER_LENGTH = 10;

export const PET_LIST_PETS_INITIALLY_VISIBLE = 3;

export const PET_BDAY_KNOWN = {
  YES: "yes",
  NO: "no",
};

export const COUNTRIES = [{ name: "United States", code: "US" }];

export const PETS = [
  {
    id: 1,
    name: "Woofers",
    image:
      "https://media.istockphoto.com/photos/dog-picture-id1328959630?k=20&m=1328959630&s=612x612&w=0&h=FG8AlYY6imeUW5RZdws6NPAvdic1XcADRgHV3u4VqNw=",
  },
  {
    id: 2,
    name: "Dogbert",
    image:
      "https://media.istockphoto.com/photos/dog-picture-id1328959630?k=20&m=1328959630&s=612x612&w=0&h=FG8AlYY6imeUW5RZdws6NPAvdic1XcADRgHV3u4VqNw=",
  },
  {
    id: 3,
    name: "Fluffy",
    image:
      "https://media.istockphoto.com/photos/dog-picture-id1328959630?k=20&m=1328959630&s=612x612&w=0&h=FG8AlYY6imeUW5RZdws6NPAvdic1XcADRgHV3u4VqNw=",
  },
];

export const SIDEMENU_HEADERS = {
  subHeading: `common.welcome`,
};

export const AUTHENTICATION_STATUS = {
  LOADING: "Loading",
  AUTHENTICATED: "Authenticated",
  NOT_AUTHENTICATED: "Not-Authenticated",
};

export const UPDATE = "Update";

export const CANCEL = "common.cancel";

export const SAVE = "common.save";

export const BOOTSTRAP_LOADER = "common.bootstrapLoader";


export const PET_FOOD_FINDER =
  "https://us-d.wayin.com/display/container/dc/{code}/entry";

export const PET_FOOD_FINDER_FR_V2 =
  "https://www.hillspet.fr/pet-food-finder";

export const ALLOWED_IMAGE_EXT = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
];

export const YEARS_IN_DIFFERENT_LOCALES = [
  'years',
  'Anos',
  'Années',
  'ans'
];

export const PET_HIDE_REASONS = {
  DECEASED: "Deceased",
  REHOMED: "Rehomed/Other",
  ARCHIVED: "Archived",
  DELETED: "Deleted",
};
