import axios from "axios";
import type { AxiosRequestHeaders } from 'axios';
import gigya from "../gigya";

const API_BAD_REQUEST = 403;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if(response.data?.serviceStatus?.code === API_BAD_REQUEST && !response.data?.isVerified) {
      gigya.accounts.logout();
      window.location.href = `account#/error`;
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status === API_BAD_REQUEST) {
      gigya.accounts.logout();
      window.location.href = `account#/error`;
    }
    return error;
  }
);
/** Middleware to return authorization token in appropriate format to all requests to BE */
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("gigyaToken");
  if (token) {
    // Initialize config.headers as AxiosRequestHeaders if it's not already set
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders; // explicitly cast to AxiosRequestHeaders
    }
    config.headers.Authorization = 'Bearer ' +  (localStorage.getItem("gigyaToken") as string);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


/** Middleware to return hph_locale value set by aem on all the FE requests to BE */
// We can add api urls here that need to be exempt from the locale headers req
const exemptUrls = [`${process.env.REACT_APP_EXEMPT_FOODSEL_URL}`];
axios.interceptors.request.use((config) => {
  // added this logic to exclude locale in headers when food select api is used as it will not work with locale as a header
  const isExempted = exemptUrls.some(url => config.url?.includes(url));
  if (!isExempted) {
    const hph_locale = window?.hph_locale;
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders; // explicitly cast to AxiosRequestHeaders
    }
  config.headers.locale = hph_locale;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const api = axios.create({
  baseURL: '',
  timeout: 3000,
  headers: { 'X-Custom-Header': 'foobar' },
});

export type APICallStatus = "IDLE" | "PENDING" | "SUCCESS" | "ERROR";
