import { useState } from "react";
import { SNOWFLAKE } from "../../constants";
import { Cat, Dog } from "../../icons";
import { ChangedAttributeType, ModPetType } from './utils'
import { usePetConfig } from "../../hooks/usePetConfig";

const getChangedAttributesHtml = (changedAttributes: ChangedAttributeType, index: number, t: (value: string) => string) => {
    if (changedAttributes.newValue) {
        return (
            <div key={index} className="flex flex-col">
                <div className="font-normal text-sm text-[#66676B] grow">{t(`snowflake.attributes.${changedAttributes.key}`)}</div>
                <div className="flex flex-row gap-2">
                    {changedAttributes.prevValue && <div className="font-normal text-sm shrink whitespace-nowrap overflow-hidden text-ellipsis">{changedAttributes.prevValue}</div>}
                    <span className="w-2">→</span>
                    <div className="font-bold text-sm grow  
                    whitespace-nowrap overflow-hidden text-ellipsis">{changedAttributes.newValue}</div>
                </div>
            </div>
        )
    }
    return <div></div>
}

const PetCardStrip = ({ pet, setCheckedPets, checkedPets, t , isEditable }: { pet: ModPetType, setCheckedPets: (value: Array<ModPetType>) => void, checkedPets: Array<ModPetType>, t: (value: string) => string , isEditable:boolean} ) => {
    const [checked, setChecked] = useState(false);
    const petConfig = usePetConfig();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        isChecked ? setCheckedPets([pet, ...checkedPets]) : setCheckedPets(checkedPets.filter((petItem) => petItem.petName.toLocaleLowerCase() !== pet.petName.toLocaleLowerCase()));
        setChecked(isChecked);
    };
    let petTypeId = pet.petTypeId;
    const isPetTypeChanged = pet.changedAttributes && pet.changedAttributes.length > 0 && pet.changedAttributes.find((attribute) => attribute.key === 'petTypeId');
    if(isPetTypeChanged){
        petTypeId = petConfig.petTypes.find((type) => type.petTypeName === isPetTypeChanged.prevValue)?.petTypeId ||pet.petTypeId;
    }
    return (
        <div className={"flex flex-col rounded-lg m-2 border gap-x-2 " + ((checked && isEditable)  && "bg-[#EEF5FF] border-[#0054A4]")}>
            <div className="flex flex-row p-4 justify-between">
                <div className="flex flex-row items-center gap-2"> 
                    {isEditable && <div className="cursor-pointer"><input type="checkbox" className="cursor-pointer" checked={checked} onChange={(e) => handleChange(e)} /></div>}
                    <div className="bg-[#E6E6E6] p-1.5 rounded-full">{petTypeId === 1 ? Dog : Cat}</div>
                    <div className="font-semibold text-lg  whitespace-nowrap overflow-hidden text-ellipsis">{pet.petName}</div>
                </div>
                <div className="flex items-center justify-center">  
                {(pet.isAdded)  && <div className="text-[#0054a4] bg-[#EEF5FF] rounded-lg py-0.5 px-2 text-sm sm:text-base"><span>{t(SNOWFLAKE.NEW_PET)}</span></div>}
                </div>
            </div>
         
                {pet.changedAttributes && pet.changedAttributes.length > 0 &&
                    pet.changedAttributes.map((attribute, index) => {
                        return (
                            <div className="p-4" key={index+attribute.key}>
                                {getChangedAttributesHtml(attribute, index, t)}
                            </div>
                        )
                    })
                }
        </div>

    )
}

export default PetCardStrip;