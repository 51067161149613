import { PetConfigType, PetType } from "../../../types";
import { format } from "date-fns";
import { PetProfileType } from "../Pet/types";
import { prepareFormDataWithImage } from "utils";
import { PetObject } from ".";
import { comparisonPetAttributes } from "../../lib/common/snowflakeProcessing";

export  type ChangedAttributeType = {
    key: string,
    prevValue: string,
    newValue: string,
    isMap?: boolean
}

export interface ModPetType extends PetType {
    isEditable?: boolean,
    isAdded?: boolean,
    changedAttributes?: Array<ChangedAttributeType>
}

const DATE_FORMAT = 'MMM dd,yyy';


const getChangedObj = (key: string, prevValue: string | unknown, newValue: string | unknown) => {
    return {
        key: key,
        prevValue: prevValue,
        newValue: newValue
    } as ChangedAttributeType

}

export const getChangedAttributes = (hphPet: PetType, snowflakePet: PetType, petConfig:PetConfigType) => {
    const changedAttributes = [];
    if (snowflakePet.petNickName && hphPet.petNickName !== snowflakePet.petNickName) {//mapped to petNickName
        changedAttributes.push(getChangedObj('petNickName', hphPet.petNickName, snowflakePet.petNickName));
    }
    if (hphPet.petBirthday && snowflakePet.petBirthday && hphPet.petBirthday !== snowflakePet.petBirthday) {
        const prevValue = format(new Date(hphPet.petBirthday), DATE_FORMAT).toString();
        const newValue = format(new Date(snowflakePet.petBirthday), DATE_FORMAT).toString();
        changedAttributes.push(getChangedObj('petBirthday', prevValue, newValue));
    }
    if (snowflakePet.petAdoptDate && hphPet.petAdoptDate !== snowflakePet.petAdoptDate) {//mapped to petNickName
        const prevValue = hphPet.petAdoptDate ? format(new Date(hphPet.petAdoptDate), DATE_FORMAT).toString(): '';
        const newValue = format(new Date(snowflakePet.petAdoptDate), DATE_FORMAT).toString();
        changedAttributes.push(getChangedObj('petAdoptDate', prevValue, newValue));
    }
    
    if (snowflakePet.petSizeId && hphPet.petSizeId !== snowflakePet.petSizeId) {//mapped to size
        const prevValue = hphPet.petSizeId ? petConfig.petSizes.find((size) => size.petSizeId === String(hphPet.petSizeId))?.petSizeName: '';
        const newValue = petConfig.petSizes.find((size) => size.petSizeId === String(snowflakePet.petSizeId))?.petSizeName;
        changedAttributes.push({ ...getChangedObj('petSizeId',prevValue, newValue)});
    }
    if (hphPet.petTypeId && snowflakePet.petTypeId && hphPet.petTypeId !== snowflakePet.petTypeId) {//mapped to type
        const prevValue = petConfig.petTypes.find((type) => type.petTypeId === hphPet.petTypeId)?.petTypeName;
        const newValue = petConfig.petTypes.find((type) => type.petTypeId === snowflakePet.petTypeId)?.petTypeName;
        changedAttributes.push({ ...getChangedObj('petTypeId', prevValue, newValue)});
    }
    if (hphPet.petGenderId && snowflakePet.petGenderId && hphPet.petGenderId !== snowflakePet.petGenderId) {//mapped to gender
        const prevValue = petConfig.petGender.find((gender:{petGenderId:number}) =>gender.petGenderId === hphPet.petGenderId)?.petGenderName;
        const newValue = petConfig.petGender.find((gender:{petGenderId:number}) =>gender.petGenderId === snowflakePet.petGenderId)?.petGenderName;
        changedAttributes.push({ ...getChangedObj('petGenderId', prevValue, newValue)});
    }

    //Info Not flowing from Snowflake
    // if (snowflakePet.petVetname && hphPet.petVetFirstName !== snowflakePet.petVetname) { //mapped to gender
    //     changedAttributes.push({ ...getChangedObj('petVetname', hphPet.petVetFirstName || '', snowflakePet.petVetname)});
    // }

    //Breeds attributes mapping will be added in phase 2 of snowflake development.
    if (snowflakePet.petBreedId && hphPet.petBreedId !== snowflakePet.petBreedId) {
        const prevValue = petConfig.breeds.find((breed) =>breed.petBreedId === hphPet.petBreedId)?.petBreedName || null;
        const newValue = petConfig.breeds.find((breed) =>breed.petBreedId === snowflakePet.petBreedId)?.petBreedName || null;
        changedAttributes.push(getChangedObj('petBreed',prevValue, newValue));
    }
    //Food type attribute mapping will be added in phase 2 of snowflake development.
    // if (hphPet.petFoodTypes && snowflakePet.petFoodTypes && hphPet.petFoodTypes !== snowflakePet.petFoodTypes) {
    //     changedAttributes.push(getChangedObj('petFoodTypes', hphPet.petFoodTypes, snowflakePet.petFoodTypes));
    // }

    return changedAttributes;
}

export const prepareObjToSend = (
   updatedPet : ModPetType
  ) => {
    const petDataValues = {} as Partial<PetProfileType>;
    petDataValues['petAdoptDate'] = updatedPet['petAdoptDate'] || undefined;
    petDataValues['petBirthdateTypeId'] = updatedPet['petBirthdateTypeId'];
    petDataValues['petBirthday'] = updatedPet['petBirthday'] || undefined;
    petDataValues['petGenderId'] =  updatedPet['petGenderId'] || undefined;
    petDataValues['petLifeStageId'] = updatedPet['petLifeStageId'] || undefined;
    petDataValues['petName'] = updatedPet['petName'];
    petDataValues['petNickName'] = updatedPet['petNickName'] || '';
    petDataValues['petSizeId'] = updatedPet['petSizeId'];
    petDataValues['petTypeId'] = updatedPet['petTypeId'];
    petDataValues['petVetName'] = updatedPet['petVetFirstName'] || '';
    if( updatedPet['petBreedId']){
        petDataValues['petBreedId'] =  updatedPet['petBreedId'] || null;
    }
    if(updatedPet.isAdded){
        petDataValues['originalSource'] = updatedPet['originalSource'] || undefined;
    }
    return {
      petData: [petDataValues],
    };
  };

export const addUpdatePets = async (selectedPets: Array<ModPetType>,updatePetApi:(value:unknown)=>Promise<unknown>,addEditPet:(value:unknown)=>Promise<unknown>, leftOutPets?:Array<ModPetType>) =>{
    let error = [] as Array<unknown>;
    if(leftOutPets && leftOutPets?.length > 0){
        const deletePetError =  await deletePets(leftOutPets,addEditPet);
        error = [...error, ...deletePetError];
    }

    if(selectedPets && selectedPets?.length > 0){
        for (const pet of selectedPets) {
            const petObjectToSend  = prepareObjToSend(pet); 
            if(!pet.isAdded){
                petObjectToSend.petData[0]['petId'] = pet.petId;
                const updatePetResonse = await updatePetApi(prepareFormDataWithImage(petObjectToSend));
                if(updatePetResonse === null){
                    error.push({
                        petId: pet.petId,
                        petName: pet.petName
                    })
                }
            }else{
                const addEditPetResponse = await addEditPet(prepareFormDataWithImage(petObjectToSend));
                if(addEditPetResponse === null){
                    error.push({
                        petId: pet.petId,
                        petName: pet.petName
                    })
                }
            }
        
        }
    }

    return error;
}

export const deletePets = async (leftOutPets: Array<ModPetType>,addEditPet:(value:unknown)=>Promise<unknown>) =>{
    const error = [];
    if(leftOutPets && leftOutPets?.length > 0){
        for (const pet of leftOutPets) {
            const petObjectToSend  = prepareObjToSend(pet); 
            petObjectToSend.petData[0]['petStatusId'] = 3;
            petObjectToSend.petData[0]['originalSource'] = leftOutPets[0]['originalSource'] || undefined;
            const updatePetResponse = await addEditPet(prepareFormDataWithImage(petObjectToSend));
            if(updatePetResponse === null){
                error.push({
                    petId: pet.petId,
                    petName: pet.petName
                })
            }
        }
        return error;
    }
    return [];
}


export const processPets = (hphPets: Array<PetType>, snowflakePets: PetObject, isEditable: boolean, petConfig: PetConfigType) => {
    const petsToAddUpdate = [];

    //processing for new pets
    if (snowflakePets?.newPets?.length > 0) {
        const modifiedNewPetsArr = snowflakePets?.newPets?.filter((pet: ModPetType) => {
            if (pet.petName) {
                pet['isEditable'] = isEditable;
                pet['isAdded'] = true;
                return pet;
            }
        });
        petsToAddUpdate.push(...modifiedNewPetsArr);
    }

    //processing for updated pets
    if (snowflakePets?.updatedPets?.length > 0) {
        const modifiedUpdatedPetsArr = snowflakePets?.updatedPets?.filter((pet: ModPetType) => {
            const hphPet = hphPets.find((hphPet: PetType) => comparisonPetAttributes(hphPet,pet));
            if (hphPet) {
                pet['petId'] = hphPet['petId']; //snowflake created new Id ignored for already hph pets.
                pet['isEditable'] = isEditable;
                pet['isAdded'] = false;
                pet['changedAttributes'] = getChangedAttributes(hphPet, pet, petConfig) as Array<ChangedAttributeType>;
                return pet['changedAttributes'] && pet['changedAttributes'].length && pet;
            }
        });
        petsToAddUpdate.push(...modifiedUpdatedPetsArr);
    }

    return petsToAddUpdate;
}


export const getIsPetUpdatedFromSnowflake = (HphPet: PetType, updatedPet: Array<PetType>,petConfig: PetConfigType) => {
    //processing for updated pets
    if (updatedPet?.length > 0) {
        const modifiedUpdatedPetsArr = updatedPet?.filter((pet: ModPetType) => {
            const isSimilarPet = comparisonPetAttributes(HphPet, pet);
            const isAnyAttributeChanged = getChangedAttributes(HphPet, pet, petConfig);
            if(pet.petName.toLocaleLowerCase() === HphPet.petName.toLocaleLowerCase() && isSimilarPet && isAnyAttributeChanged?.length > 0){
                return pet;
            }
        });
        return modifiedUpdatedPetsArr;
    }
    return [];
}