import { chevronDown, chevronUp } from "../../../../../icons/chevronUpDown";
import { useTranslation } from 'react-i18next';

type AccordionProps = {
  keyToMap: string;
  title: string;
  expanded: boolean;
  onToggle: () => void;
  labels: string[];
  selectedFilters: string[];
  onFilterSelect: (filter: string) => void;
  onFilterRemove: (filter: string) => void;
  isFirstAccordion: boolean;
}

  const Accordion: React.FC<AccordionProps> = ({
    keyToMap,
    title,
    expanded,
    onToggle,
    labels,
    isFirstAccordion,
    selectedFilters,
    onFilterSelect,
    onFilterRemove,
  }) => {
    
    const { t } = useTranslation();
    const handleCheckboxChange = (label: string, isChecked: boolean) => {
      if (isChecked) {
        onFilterSelect(label);
      } else {
        onFilterRemove(label);
      }
    };
  
    return (
    <div className={`mt-${isFirstAccordion ? '4' : '0'} ${isFirstAccordion ? 'border-t border-b' : 'border-b'} border-gray-300`}>
        <div className="flex h-12 px-6 justify-between items-center rounded cursor-pointer" onClick={onToggle}>
          <div>
            {title} {selectedFilters.length > 0 && `(${selectedFilters.length})`}
          </div>
          <div className="transform transition-transform">
            {/* Up and down chevron */}
            {expanded ? chevronUp : chevronDown}
          </div>
        </div>
        {expanded && (
          <div>
            {/* Placeholder for filter options */}
            {labels.map((label: string) => (
              <>
                {t(`lifestage.${label}`)!="" &&
                  <label key={label} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      value={label}
                      checked={selectedFilters.includes(label)}
                      onChange={(e) => handleCheckboxChange(label, e.target.checked)}
                      className="form-checkbox mx-6 my-2 h-4 w-4 border-brand-color-library-blue-500 rounded text-brand-color-library-500"
                    />
                    
                    {keyToMap == "lifestage" || keyToMap == "healthCategory" || keyToMap == "productForm" ? <>{t(`lifestage.${label}`)}</> : <>{label}</>}
                  </label>}
              </>
            ))}
        </div>
      )}
    </div>
  );
};

  export default Accordion;