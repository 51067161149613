import { Form } from "react-final-form";
import { ROUTES_PATH, SNOWFLAKE } from "../../constants";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { useNavigate } from "react-router-dom";
import { useSnowFlakePets } from "../../hooks/useSnowflakeData";
import { DogCat } from "../../icons";


const PetsAddedSuccessModal = ({ setShowPetsAddedSuccessModal }: { setShowPetsAddedSuccessModal: (val: boolean) => void }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fetchSnowflakePets } = useSnowFlakePets();

    const close = () => {
        setShowPetsAddedSuccessModal(false);
        fetchSnowflakePets();
    }

    const navigation = () => {
        fetchSnowflakePets();
        navigate(ROUTES_PATH.ADD_PET, {
            replace: true,
        })
    }

    return (
        <Form
            onSubmit={close}
            render={({ handleSubmit, submitting }) => {
                return (
                    <form id="PetsAddedSuccess" onSubmit={handleSubmit} role="form">
                        <div className="h-[calc(100vh-5%)] sm:h-[calc(100vh-60%)] p-5">
                            <div className="flex flex-col items-center h-full">
                                {/* {header} */}
                                <div className="w-full">
                                    <div
                                        className="flex items-center justify-center p-1 text-center text-2xl font-bold lg:mx-28 lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {DogCat}
                                    </div>
                                    <div
                                        className="flex items-center justify-center px-0 text-center text-2xl font-bold lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {t(SNOWFLAKE.PETS_ADDED_MODAL_TITLE)}
                                    </div>
                                </div>

                                {/* {body} */}
                                <div className="flex flex-row justify-start overflow-y-auto overflow-x-hidden w-full px-8 md:px-12">
                                    <div className=" h-1/5 w-full">
                                        {t(SNOWFLAKE.PETS_ADDED_MODAL_PARA_1)}
                                    </div>
                                </div>

                                {/* {footer} */}
                                <div className="mt-auto">
                                    <div className="flex flex-col px-8 md:px-12 mb-2 mt-8">
                                        <div className="flex flex-col items-center">
                                            <Button
                                                type="submit"
                                                mode="contained"
                                                variant="primary"
                                                isLoading={submitting}
                                                classes="lg:mt-2 w-full sm:w-72 py-4 font-bold text-xl px-4"
                                            >
                                                {t(SNOWFLAKE.PET_ADDED_MODAL_ACTION_1)}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                mode="contained"
                                                onClick={navigation}
                                                variant="primary"
                                                isLoading={submitting}
                                                classes="lg:mt-2 w-full sm:w-72 py-4 font-bold text-xl mb-1 bg-white text-[#0054A4] mt-2"
                                            >
                                                {t(SNOWFLAKE.PET_ADDED_MODAL_ACTION_2)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    )
}

export default PetsAddedSuccessModal;