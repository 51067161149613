import { forwardRef, InputHTMLAttributes } from "react";
import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse, isValid, toDate } from "date-fns";
import { Field } from "react-final-form";
import { FormControl } from "ui";
import { IconCalendar } from "ui";
import { ADD_EDIT_PET } from "../../constants/addEditPet";
import { useTranslation } from "react-i18next";
import { pt, enGB, fr } from "date-fns/locale";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";

const supportedLang = {
  "pt": {locale:"pt",dateLocale:pt},
  "en": {locale:"en",dateLocale:enGB},
  "fr": {locale:"fr",dateLocale:fr},
}


interface DatepickerConfig {
  name: string;
  label?: string;
  onBlurCapture?: (e: { target: { name: string; value: string } }) => void;
  validate?: (value: string | undefined) => string | undefined;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  optionalText?: string;
  isRequired?: boolean;
  petsBirthdayTypeId: string;
  petBirthday?: string;
}

const CustomInput = forwardRef<HTMLInputElement>(
  (input: InputHTMLAttributes<HTMLInputElement>, ref) => {
    return (
      <div className="flex">
        <input {...input} ref={ref}></input>
        <div
          className="z-0 -ml-8 flex w-7 flex-row-reverse items-center"
          onClick={input.onClick}
        >
          <IconCalendar />
        </div>
      </div>
    );
  }
);

export const Datepicker = (props: DatepickerConfig) => {
  type ObjectKey = keyof typeof supportedLang;
  const language = window.hph_locale ? (window.hph_locale.indexOf("-") ? window.hph_locale.split("-")[0] : "en") : "en" as ObjectKey;
  registerLocale(supportedLang[language as ObjectKey].locale, supportedLang[language as ObjectKey ].dateLocale)
  const {datePickerConfigType} = useFeatureFlagsConfig();
  const { t } = useTranslation();
  const approxDate = props.petsBirthdayTypeId == "2" ? true : false;
  const DateFormats = datePickerConfigType[props.petsBirthdayTypeId];

  if (props.name === "petBirthday" || props.name === "petAdoptDate") {
    return (
      <div className="mt-1 w-full">
        <label
          className={`self-baseline pt-5 text-xl font-bold`}
          htmlFor={props.name}
        >
          {props.label}
          {props.isRequired && <span className="text-red-600">*</span>}
        </label>
        {props.name === "petBirthday" && (
          <div className="w-full rounded pt-5 lg:flex">
            <FormControl
              fieldProps={{
                name: "petBirthdateTypeId",
                component: "input",
                value: "1",
              }}
              type="radio"
              classes="-mt-5 w-fit -ml-10"
            >
              {t(ADD_EDIT_PET.ACTUAL_DATE)}
            </FormControl>
            <FormControl
              fieldProps={{
                name: "petBirthdateTypeId",
                component: "input",
                value: "2",
              }}
              type="radio"
              classes="mt-5 w-fit -ml-10 lg:ml-5 lg:-mt-5"
            >
              {t(ADD_EDIT_PET.APPROXIMATE_DATE)}
            </FormControl>
          </div>
        )}
        <Field name={props.name} validate={props.validate && props.validate}>
          {({ input, meta }) => {
            let isValidDate = false;
            let selected = null;

            if (input.value) {
              const parsedDate = parse(
                input.value as unknown as string,
                DateFormats.formFormat,
                new Date()
              );
              isValidDate = isValid(parsedDate);
              selected = isValidDate ? toDate(parsedDate) : null;
            }

            return (
              <>
                <DatePicker
                  id={input.name}
                  name={input.name}
                  disabled={props.disabled || false}
                  dateFormat={DateFormats.datePicker}
                  showMonthYearPicker={approxDate}
                  selected={selected}
                  onBlur={input.onBlur}
                  customInput={<CustomInput />}
                  placeholderText={t(`common.${DateFormats.placeHolderKey}`).toString()}
                  className={`h-12 w-full rounded-md border-2 p-2 ${((meta.error && meta.touched)|| (meta.error && !meta.pristine))?"border-brand-error":""}`}
                  onChange={(date) => {
                    if (isValid(date)) {
                      const validDate = format(date as Date, DateFormats.InputOnChangeFormat);
                      input.onChange(validDate);
                    } else {
                      input.onChange(null);
                    }
                  }}
                  onCalendarClose={() => {
                    input.value &&
                      props.onBlurCapture &&
                      props.onBlurCapture({
                        target: {
                          name: input.name,
                          value: input.value,
                        },
                      });
                  }}
                  yearDropdownItemNumber={5}
                  minDate={props.min}
                  maxDate={props.max || new Date()}
                  showYearDropdown
                  dropdownMode="select"
                  popperPlacement="bottom-end"
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [1, 1],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                    {
                      name: "arrow",
                      options: {
                        padding: 20,
                      },
                    },
                  ]}
                  locale={supportedLang[language as ObjectKey].locale}
                />
                {props.optionalText && (
                  <span className="text-xs text-slate-500">
                    {props.optionalText}
                  </span>
                )}
                <div className="mt-1 h-4">
                  {((meta.error && meta.touched)|| (meta.error && !meta.pristine)) && (
                    <div className="mb-2 text-xs text-brand-error">
                      {`${props.name === "petBirthday"?props.label:""} ${meta.error}`}
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
    );

  }
  else {
    return (
      <div className="mt-1 w-full">
        <label
          className={`self-baseline text-xl font-bold ${props.disabled ? "opacity-40" : ""
            }`}
          htmlFor={props.name}
        >
          {props.label}
          {props.isRequired && <span className="text-red-600">*</span>}
        </label>
        <Field name={props.name} validate={props.validate && props.validate}>
          {({ input, meta }) => {
            let isValidDate = false;
            let selected = null;

            if (input.value) {
              const parsedDate = parse(
                input.value as unknown as string,
                DateFormats.formFormat,
                new Date()
              );
              isValidDate = isValid(parsedDate);
              selected = isValidDate ? toDate(parsedDate) : null;
            }

            return (
              <>
                <DatePicker
                  id={input.name}
                  name={input.name}
                  disabled={props.disabled || false}
                  dateFormat={DateFormats.datePicker}
                  selected={selected}
                  onBlur={input.onBlur}
                  customInput={<CustomInput />}
                  placeholderText={t(`common.${DateFormats.placeHolderKey}`).toString()}
                  className={`h-12 w-full rounded-md border-2 p-2 disabled:opacity-40 ${((meta.error && meta.touched)||(meta.error && !meta.pristine))?"border-brand-error":""}`}
                  onChange={(date) => {
                    if (isValid(date)) {
                      input.onChange(format(date as Date, DateFormats.InputOnChangeFormat));
                    } else {
                      input.onChange(null);
                    }
                  }}
                  onCalendarClose={() => {
                    input.value &&
                      props.onBlurCapture &&
                      props.onBlurCapture({
                        target: {
                          name: input.name,
                          value: input.value,
                        },
                      });
                  }}
                  yearDropdownItemNumber={5}
                  minDate={props.min}
                  maxDate={props.max || new Date()}
                  showYearDropdown
                  dropdownMode="select"
                  popperPlacement="bottom-end"
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [1, 1],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                    {
                      name: "arrow",
                      options: {
                        padding: 20,
                      },
                    },
                  ]}
                  locale={supportedLang[language as ObjectKey].locale}
                />
                {props.optionalText && (
                  <span className="text-xs text-slate-500">
                    {props.optionalText}
                  </span>
                )}
                <div className="mt-1 h-4">
                  {((meta.error && meta.touched) || (meta.error && !meta.pristine)) && (
                    <div className="mb-2 text-xs text-brand-error">
                      {`${props.name === "petBirthday"?props.label:""} ${meta.error}`}
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
    );
  }

};

Datepicker.defaultProps = {
  petsBirthdayTypeId:"1"
};
Datepicker.displayName = "Datepicker";