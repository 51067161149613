import { useState } from "react";
import {
    PET, PET_HIDE_REASONS,SNOWFLAKE
} from "../../constants";
import { ADD_EDIT_PET } from "../../constants/addEditPet";
import { heartIcon } from "../../icons/heart";
import Heading from "../Heading";
import PetProfilePhoto from "../Pet/PetProfilePhoto";
import { useTranslation } from "react-i18next";
import {

    Button,

} from "ui";
import { HideAndRemovePetApiOption } from "../Pet/types";
import { Modal } from "../../lib/modal";
import ConfirmationModalForm from "./ConfirmationModalForm";
import { calculateAgeApprox } from "utils";

interface BreedName {
    petBreedName: string;
}

interface DeceasedPetProps {
    petName: string;
    petBirthday: string;
    petBreed: BreedName;
    petImage: string;
    removeHidePet: (value: HideAndRemovePetApiOption[]) => void;
    petId: string,
    isHomePage: boolean
}

const getrievanceHeader = (translation: string, petName: string) => {
    if (translation.split("{{petName}}")[0] && translation.split("{{petName}}")[1]) {
        return (
            <>
                <span>{translation.split("{{petName}}")[0]}</span>
                <span className="font-bold">{petName}</span>
                <span>{translation.split("{{petName}}")[1]}</span>
            </>
        );
    } else {
        return <span>{translation}</span>;
    }

}

const DeceasedPet = (initData: DeceasedPetProps) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const { petName, petImage, petBreed, removeHidePet, petId,isHomePage, petBirthday } = initData;
   
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const age = calculateAgeApprox(petBirthday,t);
    const grievanceBody = t(SNOWFLAKE.GRIEVANCE_BODY);
    const grievanceAction = t(SNOWFLAKE.GRIEVANCE_ACTION).replace('{{petName}}', petName);
    // hide confirmation modal
    const modalConfigHideConfirm = {
        title: t(SNOWFLAKE.REMOVE_PET_MODAL_TITLE),
        body: (
            <ConfirmationModalForm
                removeHidePet={removeHidePet}
                setConfirmationModalOpen={setConfirmationModalOpen}
                petId={petId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        ),
        btnConfig: [],
    };

    const showConfirmationModal = () => {
        setConfirmationModalOpen(true);
    };

    return (
        <>
           {isHomePage ? <div className="mb-20">
                <Heading name={t(PET.ACTION_NEEDED)} classes="!mb-6 mt-6" />
                <div className="rounded">
                    <div className="gap-6 flex flex-col sm:flex-row items-center">
                        <div className="lg:w-1/3">
                            <div className="w-full items-center gap-5">
                                <>
                                    <PetProfilePhoto
                                        src={petImage}
                                        size="md"
                                        rounded="2xl"
                                        classes="mx-auto w-48 md:w-80"
                                    />
                                </>
                            </div>
                        </div>
                        <div className="mt-4 lg:mt-0 lg:grow">
                            <div className="mb-3 text-center  md:text-left">
                                <span className="text-base font-bold lg:text-xl">{t(ADD_EDIT_PET.BREED)}</span> {" "} <span className="lg:text-xl">{petBreed?.petBreedName || t("pet.notSelectedText")}</span>
                            </div>
                            <div className="mb-3 text-center md:text-left">
                                <span className="text-base font-bold lg:text-xl">  {t(ADD_EDIT_PET.AGE)}</span> {" "} <span className="lg:text-xl">{age}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col leading-5 p-1 sm:p-5 max-w-3xl">
                        <div className="py-3 text-lg">{getrievanceHeader(t(SNOWFLAKE.GRIEVANCE_HEADER), petName)}</div>
                        {grievanceBody ? <div className="py-2 text-lg">{grievanceBody}</div>: null}
                        <div className="flex py-5 justify-center">
                            <div>{heartIcon}</div>
                        </div>
                        <div className="py-5 text-lg">{grievanceAction}</div>
                    </div>
                    <div className="mt-10 flex-row justify-center sm:flex sm:items-end sm:justify-end sm:space-x-8 max-w-3xl">
                        <Button
                            variant="primary"
                            aria-label="cancel changes"
                            type="button"
                            onClick={() => {
                                const petData = {
                                    petId: petId,
                                    petStatusId: 2,
                                    petRemovalReason: PET_HIDE_REASONS.ARCHIVED,
                                }
                                setIsLoading(true);
                                removeHidePet([petData] as HideAndRemovePetApiOption[]);
                            }}
                            classes="flex-col w-full items-center mb-5 lg:mb-0 lg:pr-4 lg:w-80 lg:flex-none flex justify-center"
                            isLoading={isLoading}
                        >
                            {t(SNOWFLAKE.GRIEVANCE_ACTION_TEXT_ARCHIVE)}
                        </Button>
                        <Button
                            variant="primary"
                            mode="contained"
                            aria-label="submit"
                            type="button"
                            classes="flex-col w-full items-center mb-5 lg:mb-0 lg:w-80 lg:flex-none flex justify-center"
                            onClick={() => {
                                showConfirmationModal();
                            }}
                            isLoading={isLoading}
                        >
                            {`${t(SNOWFLAKE.GRIEVANCE_ACTION_TEXT_DELETE)}`}
                        </Button>
                    </div>
                </div>
            </div> :  <div className="mb-5">
               
                <div className="rounded flex flex-col sm:flex-row">
                    <div className="flex flex-col">
                        <div className="w-full items-center gap-5">
                            <>
                                <PetProfilePhoto
                                    src={petImage}
                                    size="md"
                                    rounded="2xl"
                                    classes="mx-auto w-48 md:w-80"
                                />
                            </>
                        </div>
                        <div className="mt-8">
                            <div className="mt-3 text-center">
                                <span className="text-base font-bold lg:text-xl">{t(ADD_EDIT_PET.BREED)}</span> {":"} <span className="lg:text-xl">{petBreed?.petBreedName || t("pet.notSelectedText")}</span>
                            </div>
                            <div className="mt-3 text-center">
                                <span className="text-base font-bold lg:text-xl">  {t(ADD_EDIT_PET.AGE)}</span> {":"} <span className="lg:text-xl">{age}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col leading-5 p-1 sm:p-5 max-w-3xl">
                        <Heading name={t(PET.ACTION_NEEDED)} classes="!mb-6 mt-6 text-center sm:text-left" />
                        <div className="py-3 text-lg">{getrievanceHeader(t(SNOWFLAKE.GRIEVANCE_HEADER), petName)}</div>
                        <div className="py-2 text-lg">{grievanceBody}</div>
                        <div className="flex py-5 justify-center">
                            <div>{heartIcon}</div>
                        </div>
                        <div className="py-5 text-lg">{grievanceAction}</div>
                    </div>
                    
                </div>
                <div className="mt-10 flex flex-col items-center md:flex-row md:items-end md:justify-end md:space-x-8 ">
                        <Button
                            variant="primary"
                            aria-label="cancel changes"
                            type="button"
                            onClick={() => {
                                const petData = {
                                    petId: petId,
                                    petStatusId: 2,
                                    petRemovalReason: PET_HIDE_REASONS.ARCHIVED,
                                }
                                setIsLoading(true);
                                removeHidePet([petData] as HideAndRemovePetApiOption[]);
                            }}
                            isLoading={isLoading}
                            classes="flex-col lg:w-80 w-full items-center  mb-5 flex justify-center"
                        >
                            {t(SNOWFLAKE.GRIEVANCE_ACTION_TEXT_ARCHIVE)}
                        </Button>
                        <Button
                            variant="primary"
                            mode="contained"
                            aria-label="submit"
                            type="button"
                            classes="flex-col lg:w-80 w-full items-center mb-5 flex justify-center" 
                            onClick={() => {
                                showConfirmationModal();
                            }}
                            isLoading={isLoading}
                        >
                            {`${t(SNOWFLAKE.GRIEVANCE_ACTION_TEXT_DELETE)}`}
                        </Button>
                    </div>
            </div>} 
           
            {confirmationModalOpen ? <Modal
                modalConfig={modalConfigHideConfirm}
                dialogState={confirmationModalOpen}
                setDialog={setConfirmationModalOpen}
            /> : null}
        </>
    );
};


export default DeceasedPet
