import React from 'react';
import { useTranslation } from "react-i18next";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
} : PaginationProps) {
  const handleClick = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`inline-block ${i === currentPage ? 'bg-brand-color-library-blue-500 text-white' : 'bg-white text-black'} 
          h-10 w-10 leading-10 mx-1 text-center rounded`}
        >
          <button className="h-full w-full text-lg font-semibold" onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }

    // adding proper spacing between start of page numbers and the previous button
    if (pageNumbers.length > 0) {
      pageNumbers[0] = React.cloneElement(pageNumbers[0], {
        className: `${pageNumbers[0].props.className} ml-8`,
      });

    // adding proper spacing between end of page numbers and the next button
      pageNumbers[pageNumbers.length - 1] = React.cloneElement(
        pageNumbers[pageNumbers.length - 1],
        {
          className: `${pageNumbers[pageNumbers.length - 1].props.className} mr-8`,
        }
      );
    }

    return pageNumbers;
  };

  const { t } = useTranslation();

  return (
    <nav className="flex justify-center">
      <ul className="flex items-center justify-center w-509 h-40">
        {currentPage > 1 && (
          <li className="inline-block h-10 w-50 leading-10 mx-1 text-center rounded-full bg-white text-black font-semibold">
            <button className="h-full w-full" onClick={() => handleClick(currentPage - 1)}>
              {`${t("pet.previousPage")}`}
            </button>
          </li>
        )}
        {renderPageNumbers()}
        {currentPage < totalPages && (
          <li className="inline-block h-10 w-50 leading-10 mx-1 text-center rounded-full bg-white text-black font-semibold">
            <button className="h-full w-full" onClick={() => handleClick(currentPage + 1)}>
              {`${t("pet.nextPage")}`}
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

