import React, { ChangeEvent, useState } from "react";
import { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { hasAllowedExt, hasAllowedSize, hasDoubleExtInFileName, hasMultiPeriodInFileName } from "utils";
import { ALLOWED_IMAGE_EXT, MESSAGES } from "../../../../constants";
import { ADD_EDIT_PET } from "../../../../constants/addEditPet";
import { useToastConfig } from "../../../../lib/toast";
import PhotoCropper from "../../../Cropper";

export default function PhotoUpload({
  isEdit,
  setProfilePhoto,
}: {
  isEdit: boolean;
  setProfilePhoto: (
    file?: File,
    croppedImage?: string,
    coordinates?: Area
  ) => void;
}) {
  const { showToast } = useToastConfig();
  const { t } = useTranslation();
  const [openCropper, setOpenCropper] = useState(false);
  const [image, setImage] = useState<File>();
  // after photo selection
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const MAX_SIZE = process.env.REACT_APP_PROFILE_PIC_SIZE_IN_MB as string;
    if (files) {
      const selectedFile = files[0];
      const hasMultiPeriod = hasMultiPeriodInFileName(selectedFile.name)
      const isAllowedExt = hasAllowedExt(ALLOWED_IMAGE_EXT, selectedFile.type);
      const isAllowedSize = hasAllowedSize(+MAX_SIZE, selectedFile.size);
      const hasDoubleExt = hasDoubleExtInFileName(selectedFile.name);

      if (!hasMultiPeriod && isAllowedExt && isAllowedSize && !hasDoubleExt) {
        setImage(selectedFile);
        setOpenCropper(true);
      } else {
        showToast({
          title: `${t(ADD_EDIT_PET.ADDED)}`,
          description: 
            !isAllowedExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : hasMultiPeriod
            ? `${t(MESSAGES.PROFILE_PIC_MULTI_PERIOD_ERROR)}`
            : hasDoubleExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : `${t(MESSAGES.PROFILE_PIC_SIZE_ERROR)}` + MAX_SIZE + "mb",
          type: "error",
        });
      }
      e.target.value = "";
    }
  };
  function handleCroppedImageUpload(croppedImage: string, croppedArea?: Area) {
    if (croppedImage && croppedArea) {
      setProfilePhoto(image as File, croppedImage, croppedArea);
    }
    setOpenCropper(false);
  }
  return (
    <>
      <label
        htmlFor="file-upload"
        className="peer mt-5 flex cursor-pointer flex-col items-center justify-center lg:mt-0"
        data-testid="imageUpload"
        tabIndex={0}
        role="button"
      >
        <div className="mt-2 items-center text-lg font-semibold text-brand-primary">
          {isEdit ? t(ADD_EDIT_PET.CHANGE_PHOTO) : t(ADD_EDIT_PET.UPLOAD_PHOTO)}
        </div>
        <input
          id="file-upload"
          type="file"
          accept={ALLOWED_IMAGE_EXT.join(",")}
          className="sr-only"
          onChange={handleFileChange}
          data-testid="file-upload"
        />
      </label>
      <PhotoCropper
          open={openCropper}
          setOpen={setOpenCropper}
          image={image ? URL.createObjectURL(image as Blob) : ""}
          aspect={2 / 1}
          cropShape="rect"
          setCroppedImage={handleCroppedImageUpload}
          title={isEdit ? t(ADD_EDIT_PET.CHANGE_PHOTO) : t(ADD_EDIT_PET.UPLOAD_PHOTO)}
        />
    </>
  );
}
