/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
    createContext,
    useContext
} from "react";
import axios from "axios";
import { API_URLS } from "../constants/apiUrls";

const petsApiCall = {

    fetchPetList: async () => {

        try {
            const abortController = new AbortController();
            const apiResponse = await axios
                .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
                    signal: abortController.signal,
                })
            if (apiResponse.data?.serviceStatus?.code === 200) {
                return apiResponse;
            } else {
                return null;
            }
        } catch (e) {
            console.log("error in fetchMyPetsData snowflake update", e);
            return null;
        }
    },

    fetchMyPetsData: async (petId: number) => {
        try {
            const apiResponse = await axios
                .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}/${petId}`)
            if (apiResponse.data?.serviceStatus?.code === 200) {
                return apiResponse;
            } else {
                return null;
            }
        } catch (e) {
            console.log("error in fetchMyPetsData snowflake update", e);
            return null;
        }
    },

    updatePetApi: async (options: unknown) => {
        const apiResponse = await axios
            .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, options)
        if (apiResponse.data?.serviceStatus?.code === 200) {
            return apiResponse;
        } else {
            return null;
        }
    },

    addEditPet: async (data: unknown) => {
        const apiResponse = await axios
            .post(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, data)
        if (apiResponse.data?.serviceStatus?.code === 200) {
            return apiResponse;
        } else {
            return null;
        }
    }
    
}
const PetsApiCallContext = createContext(petsApiCall);


export function PetsApiCallProvider({ children }: { children: React.ReactNode }) {
    return <PetsApiCallContext.Provider value={petsApiCall} > {children} </PetsApiCallContext.Provider>;
}

export const usePetsApiCall = () => {
    return useContext(PetsApiCallContext);
};

