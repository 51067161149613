import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { MESSAGES, PET_HIDE_REASONS } from "../../../constants";
import { heartIcon } from "../../../icons/heart";

const HidePetConfirmOptionsForm = ({
  hidePet,
  setConfirmationHideDialogOpen,
}: {
  hidePet: (reason: string) => void;
  setConfirmationHideDialogOpen: (val: boolean) => void;
}) => {
  const { t } = useTranslation();
  const onSubmit = () => {
    setConfirmationHideDialogOpen(false);
    hidePet(PET_HIDE_REASONS.DECEASED);
  };
  return (
    <div className="flex items-center">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <div className="flex w-auto justify-center">
              <form id="hidePetConfirmOptionsForm" onSubmit={handleSubmit} role="form">
                <div className="flex w-96 flex-col">
                  <div className="flex flex-col px-4 my-6 text-center text-lg lg:px-0">
                    <div>{t(MESSAGES.PET_HIDE_PERMANENT_DESC)}</div>
                  </div>
                  <div className="flex flex-col px-4 mb-6 text-center text-lg lg:px-0">
                    <div>{t(MESSAGES.PET_HIDE_PERMANENT_DESC_SEC)}</div>
                  </div>
                  <div className="flex flex-col px-4 items-center text-lg lg:px-0">
                    {heartIcon}
                  </div>
                  <div className="mt-8 flex flex-col items-center">
                    <Button
                      type="submit"
                      mode="contained"
                      variant="primary"
                      isLoading={submitting}
                      classes="lg:mt-2 w-80 py-4 font-bold text-xl px-4"
                    >
                      {t(MESSAGES.PET_HIDE_CONFIRMATION_SAVE_LABEL)}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      />
    </div>
  );
};

export default HidePetConfirmOptionsForm;
