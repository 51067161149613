import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Add } from "ui";
import { PET, ROUTES_PATH } from "../../../constants";
import GTMACTIONS from '../../../config/gtmActions';
import { datalayerPush } from '../../../lib/gtm';
import { fullUrl } from "../../../lib/common/gtm";

export default function AddPetTile() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const combinedFunction = () => {
    gaClickAddAPet();
    navigation();
  }

  const navigation = () => {
      navigate(ROUTES_PATH.ADD_PET, {
      replace: false,
    })
  }

    // Adding GA tag for Add Pet Tile on Dashboard
    const gaClickAddAPet = () => {
      const GAclickEvent = {
        ...GTMACTIONS.ADDAPETTILE,
        "click_text": t(PET.ADD_A_PET),
        "click_url": fullUrl + ROUTES_PATH.ADD_PET
      }
      datalayerPush(GAclickEvent);
    }

  return (
    <Card
      classes="flex-col w-full justify-center items-center cursor-pointer border border-brand-secondary hover:drop-shadow-2xl"
      onClick={combinedFunction}
      role="listitem"
      aria-label="Add Pet"
    >
      <Add width={59} height={60} />
      <Button aria-label={t(PET.ADD_A_PET)} mode="link" size="lg" variant="dark">
        {t(PET.ADD_A_PET)}
      </Button>
    </Card>
  );
}
