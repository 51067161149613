import useRequireAuth from "../../hooks/useRequireAuth";
import gigya from "../../lib/gigya";
import SCREENSETS from "../../config/gigya/screensets";
import { AUTHENTICATION_STATUS, BREADCRUMB_CONFIG } from "../../constants";
import { Breadcrumb } from "../../lib/breadcrumb";
import { useEffect } from "react";

interface GigyaEvent{
  response?:{
    errorCode?:number
  },
  currentScreen?:string,
  instanceID?:string,
  eventName?:string
}

export default function Profile() {
  const auth = useRequireAuth();

  useEffect(() => {
    if (auth?.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      gigya?.accounts.showScreenSet({
        ...SCREENSETS.EDITPROFILE,
        onAfterSubmit:(event:GigyaEvent)=>{
          if(event?.response?.errorCode===0){
            auth.fetchMyProfileData && auth.fetchMyProfileData();  
          }
      }
    });
    }
  }, [auth]);
  if (!auth || !auth?.user?.UID) return null;

  return (
    <>
      <Breadcrumb
        config={[BREADCRUMB_CONFIG.home, BREADCRUMB_CONFIG.myProfile]}
      />
      <div className="mt-8">
        <div id="gigya-update-profile-screen"></div>
      </div>
    </>
  );
}
