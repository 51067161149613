import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { MESSAGES, SNOWFLAKE } from "../../constants";
import { PetType } from "../../../types";
import { PetObject } from "./index";
import PetList from "./PetList";
import { useState } from "react";
import { ModPetType, addUpdatePets } from "./utils";
import { usePetsApiCall } from "../../hooks/use-apiCall";
import { useSnowFlakePets } from "../../hooks/useSnowflakeData";
import { useToastConfig } from "../../lib/toast";


const AddUpdatePetModal = ({
    hphPets,
    snowflakePets,
    setShowSfModal
}: {
    hphPets: Array<PetType>;
    snowflakePets: PetObject,
    setShowSfModal: (val: boolean) => void;
}) => {
    const [selectedPets, setSelectedPets] = useState<Array<ModPetType>>([]);
    const { t } = useTranslation();
    const { addEditPet, updatePetApi } = usePetsApiCall();
    const { fetchSnowflakePets } = useSnowFlakePets();
    const { showToast } = useToastConfig();
    const [isSfDataLoading, setIsSfDataLoading] = useState(false);

    const onSubmit = async () => {
        setIsSfDataLoading(true);
        const error = await addUpdatePets(selectedPets, updatePetApi, addEditPet);
        if (error.length) {
            showToast({
                title: `${t(MESSAGES.ERROR)}`,
                description: `${t(MESSAGES.ERROR)}`,
                type: "error",
            });
        } else {
            showToast({
                title: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
                description: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
                type: "success",
            });
        }
        setIsSfDataLoading(false);
        setShowSfModal(false);
        fetchSnowflakePets();
        //Add/update all pets to hph
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                return (
                    <form id="newupdatedPets" onSubmit={handleSubmit} role="form">
                        <div className="p-5">
                            <div className="flex flex-col items-center h-full">
                                {/* {header} */}
                                <div className="w-full">
                                    <div
                                        className="flex items-center justify-center px-0 text-center text-2xl font-bold  lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {t(SNOWFLAKE.ADD_UPDATE_PET_MODAL_TITLE)}
                                    </div>
                                    <div className="flex flex-col  items-start text-center py-4 px-8 md:px-12">
                                        <div>
                                            {t(SNOWFLAKE.ADD_UPDATE_PET_MODAL_PARA1)}
                                        </div>

                                    </div>
                                </div>

                                {/* {body} */}
                                <div className="flex flex-row justify-start w-full p-8 md:px-12">
                                    <div className=" h-1/5 w-full">
                                        <PetList hphPets={hphPets} snowflakePets={snowflakePets} isEditable={false} setSelectedPets={setSelectedPets} />
                                    </div>
                                </div>

                                {/* {footer} */}
                                <div className="mt-auto">
                                    <div className="flex flex-col m-2">
                                        <div className="flex flex-col items-start text-start p-4">
                                            <div>  <span>{t(SNOWFLAKE.ADD_UPDATE_PET_MODAL_PARA2)}</span></div>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <Button
                                                type="submit"
                                                mode="contained"
                                                variant="primary"
                                                isLoading={isSfDataLoading}
                                                classes="lg:mt-2 w-72 py-4 font-bold text-xl px-4 flex justify-center"
                                            >
                                                {t(SNOWFLAKE.ADD_UPDATE_PET_MODAL_CTA)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        />
    )
};

export default AddUpdatePetModal;


