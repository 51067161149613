import AddUpdatePetModal from "./showNewUpdatedPetsModal";
import AddPetModal from "./showNewPetsModal";
import { PetType } from "../../../types";
import { useState } from "react";
import { ModPetType, deletePets } from "./utils";
import LeftOutPetModal from "./LeftOutPetsModal";
import FromScratchModal from "./StartFromScratchModal";
import PetsAddedSuccessModal from "./PetsAddedSuccessModal";
import {ModalWithList} from "../../lib/modalWithList";
import { usePetsApiCall } from "../../hooks/use-apiCall";
import { useToastConfig } from "../../lib/toast";
import { MESSAGES } from "../../constants";
import { useTranslation } from "react-i18next";

export interface PetObject {
    rememberedPets: Array<PetType>;
    newPets: Array<PetType>;
    updatedPets: Array<PetType>;
}

const SnowFlakeModal = ({
    hphPets,
    setShowSfModal,
    snowflakePets,
    showSfModal
}: {
    hphPets: Array<PetType>;
    setShowSfModal: (val: boolean) => void;
    snowflakePets: PetObject,
    showSfModal: boolean
}) => {
    const { t } = useTranslation();
    const { addEditPet } = usePetsApiCall();
    const { showToast } = useToastConfig();
    const [wrapperLoaderState,setWrapperLoaderState]  = useState(false);
    
    const wrapperStateClose = async (value : boolean) =>{
        // if value is false means that the new pets modal was ignored by the user so delete all pets.
        setWrapperLoaderState(true);
        if(value === false){
            const error = await deletePets(snowflakePets?.newPets,addEditPet);
            if(error.length){
                showToast({
                    title: `${t(MESSAGES.ERROR)}`,
                    description: `${t(MESSAGES.ERROR)}`,
                    type: "error",
                });
            }
        }
        setShowSfModal(false);
    }

    const [showSnowFlakeModal, setShowSnowFlakeModal] = useState(true);
    const [openleftOutPetModal, setOpenleftOutPetModal] = useState(false);
    const [selectedPets, setSelectedPets] = useState<Array<ModPetType>>([]);
    const [startFromScratchModal, setStartFromScratchModal] = useState(false);
    const [showPetsAddedSuccessModal, setShowPetsAddedSuccessModal] = useState(false);

    const modalConfigShowSFAddUpdatePetWrap = {
        body: (
            <AddUpdatePetModal
                hphPets={hphPets}
                snowflakePets={snowflakePets}
                setShowSfModal={setShowSfModal}
            />
        ),
        btnConfig: []
    };

    const modalConfigShowSFAddPetWrap = {
        body: (
            <AddPetModal
                hphPets={hphPets}
                snowflakePets={snowflakePets}
                selectedPets={selectedPets}
                setSelectedPets={setSelectedPets}
                setOpenleftOutPetModal={setOpenleftOutPetModal}
                setStartFromScratchModal={setStartFromScratchModal}
                setShowPetsAddedSuccessModal={setShowPetsAddedSuccessModal}
                wrapperLoaderState={wrapperLoaderState}
            />
        ),
        btnConfig: []
    };

    const leftOutPetsModalWrap = {
        body: (
            <LeftOutPetModal
                selectedPets={selectedPets}
                snowflakePets={snowflakePets}
                setOpenleftOutPetModal={setOpenleftOutPetModal}
                setShowSnowFlakeModal={setShowSnowFlakeModal}
                setShowPetsAddedSuccessModal={setShowPetsAddedSuccessModal}
            />
        ),
        btnConfig: [],
    }

    const PetsAddedSuccessModalWrap = {
        body: (
            <PetsAddedSuccessModal
                setShowPetsAddedSuccessModal={setShowPetsAddedSuccessModal}
            />
        ),
        btnConfig: []
    }

    const StartOverModalWrap = {
        body: (
            <FromScratchModal
                setStartFromScratchModal={setStartFromScratchModal}
                setShowSfModal={setShowSfModal}
                snowflakePets={snowflakePets}
            />
        ),
        btnConfig: []
    }

    return (
        <div>
            {showSnowFlakeModal ?
                (hphPets.length ? <ModalWithList
                    modalConfig={modalConfigShowSFAddUpdatePetWrap}
                    dialogState={showSfModal}
                    setDialog={setShowSfModal}

                /> : <ModalWithList
                    modalConfig={modalConfigShowSFAddPetWrap}
                    dialogState={showSfModal}
                    setDialog={wrapperStateClose}
                />) : null}


            {openleftOutPetModal && <ModalWithList
                modalConfig={leftOutPetsModalWrap}
                dialogState={openleftOutPetModal}
                setDialog={setOpenleftOutPetModal}
            />}
            {startFromScratchModal && <ModalWithList
                modalConfig={StartOverModalWrap}
                dialogState={startFromScratchModal}
                setDialog={setStartFromScratchModal}
            />}
            {showPetsAddedSuccessModal && <ModalWithList
                modalConfig={PetsAddedSuccessModalWrap}
                dialogState={showPetsAddedSuccessModal}
                setDialog={setShowPetsAddedSuccessModal}
            />}
        </div>
    )
}

export default SnowFlakeModal;