import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ButtonWide, Loader, IconAddOutlineBlue } from "ui";
import { useApi } from "utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { API_URLS } from "../../../constants/apiUrls";
import { PetType } from "../../../../types";
import Heading from "../../Heading";
import { Breadcrumb } from "../../../lib/breadcrumb";
import { BREADCRUMB_CONFIG, ROUTES_PATH, PET } from "../../../constants";
import PetDetails, { PetName } from "./PetDetails";
import HiddenPets from "./HiddenPets";
import Pagination from "../Pagination";
import { RecommendedArticles } from "../../RecommendedArticles";
import { useFeatureFlagsConfig } from "../../../hooks/useFeatureFlags";
import { isMobile } from "../../../lib/common/mobile";
import getSfMergedPets from "../../../lib/common/snowflakeProcessing";
import { useSnowFlakePets } from "../../../hooks/useSnowflakeData";

export default function PetDashBoard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [hiddenPets, setHiddenPets] = useState<PetType[]>([]);
  const [petList, setPetList] = useState<PetType[]>([]);
  const [isDataloading, setIsDataloading] = useState(true);
  const [openIndex, setOpenIndex] = useState(0);
  const { recommendedArticles } = useFeatureFlagsConfig();
  const { snowflakePets, isSfDataLoading } = useSnowFlakePets();

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPaginationVisible, setIsPaginationVisible] = useState(false);
  

  //Api configs
  //fetch pet Api
  const { exec: fetchPetListData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        window.scrollTo(0, 0);
        const sfComparedData = getSfMergedPets(apiResponse.data.data,snowflakePets.rememberedPets as Array<PetType>); // merge sf data with pet data
        const petsArray = sfComparedData;
        if (apiResponse.data.serviceStatus.code === 200) {
          const activePets: PetType[] = [];
          const inactivePets: PetType[] = [];
          
          const petsPerPage = isMobile ? 5 : 6;
        // logic to show/hide pagination component
        setIsPaginationVisible(petsArray.length > (isMobile ? 5 : 6));

        // logic for total number of pages for pagination 
        const calculateTotalPages = (): number => {
          const lastPageMaxPets = isMobile ? 1 : 2;
          const totalPages = Math.ceil(petsArray.length / petsPerPage);
          const lastPagePets = petsArray.length % petsPerPage;
          return lastPagePets === 0 ? totalPages : totalPages - 1 + (lastPagePets <= lastPageMaxPets ? 1 : 0);
        };

          petsArray.forEach((pet: PetType) => {
            pet.petStatusId === 2
              ? inactivePets.push(pet)
              : activePets.push(pet);
          }); // 2 = inactive

            // Calculate the currentPagePets based on the current page and maximum limits
            const startIndex = (currentPage - 1) * petsPerPage;
            const endIndex = startIndex + petsPerPage;
            const currentPagePets = activePets.slice(startIndex, endIndex);

          setHiddenPets(inactivePets);
          setPetList(currentPagePets);
          setTotalPages(calculateTotalPages())
        }
        setIsDataloading(false);
      });
  });

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    !isSfDataLoading && fetchPetListData();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [isSfDataLoading]);

  useEffect(() => {
    !isSfDataLoading && fetchPetListData(currentPage);
    setIsDataloading(true)
    scrollToTop();
  }, [currentPage,isSfDataLoading])

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  }

  return (
    <>
      <Breadcrumb
        config={[BREADCRUMB_CONFIG.home, BREADCRUMB_CONFIG.petDashboard]}
      />
      <Heading name={t(PET.HEADING)} />
      {isDataloading ? (
        <Loader />
      ) : (
        <>
          <Accordion
            name="activePet"
            variant="primary"
            items={petList}
            header={(item: PetType) => (
              <PetName name={item.petNickName || item.petName} showIsDeceasedIcon={item.showIsDeceasedIcon || false} />
            )}
            content={(item: PetType) => <PetDetails data={item} fetchPetListData={fetchPetListData}/>}
            openIndex={openIndex}
            updateOpenIndex={setOpenIndex}
          />

          <ButtonWide
            aria-label={t(PET.ADD_A_PET)}
            mode="outlined"
            size="md"
            variant="primary"
            classes="hover:bg-white hover:text-brand-primary"
            onClick={() => navigate(ROUTES_PATH.ADD_PET, { replace: false })}
          >
            <IconAddOutlineBlue className="mr-3  h-5 w-5 " /> {t(PET.ADD_A_PET)}
          </ButtonWide>
        </>
      )}

      {isPaginationVisible ? (
        <Pagination 
          currentPage={currentPage} 
          totalPages={totalPages} 
          onPageChange={handlePageChange}
        />
        ) : ''
      }
      <HiddenPets hiddenPets={hiddenPets} />
      {recommendedArticles && <RecommendedArticles />}
    </>
  );
}
