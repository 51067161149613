export const alert =
    <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    aria-label="alert icon"
    xmlns="http://www.w3.org/2000/svg">
         <g aria-hidden="true">
        <rect width="24" height="24" rx="12" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.007 16.9897C12.4643 16.9897 12.8423 16.8431 13.141 16.5498C13.4397 16.2565 13.589 15.8984 13.589 15.4753C13.589 15.0426 13.442 14.6868 13.148 14.408C12.854 14.1291 12.4737 13.9897 12.007 13.9897C11.5403 13.9897 11.1577 14.1291 10.859 14.408C10.5603 14.6868 10.411 15.0426 10.411 15.4753C10.411 15.8984 10.5603 16.2565 10.859 16.5498C11.1577 16.8431 11.5403 16.9897 12.007 16.9897ZM13.589 7L13.057 12.9987H10.943L10.425 7H13.589Z" fill="#C4132A" />
        </g>
    </svg>

