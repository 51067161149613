export const LifestageMilestone = {
  BACK: "common.back",
  MILESTONES: "lifestageMilestone.milestones",
  LIFESTAGE: "lifestageMilestone.lifestage",
  COMPLETED_DATE: "lifestageMilestone.completedDate",
  PAST_MILESTONES: "lifestageMilestone.pastMilestones",
  NEXT_MILESTONES: "lifestageMilestone.nextMilestones",
  TITLE: "lifestageMilestone.title",
  UPDATE_SUCCESS: "lifestageMilestone.updateSuccess",
  UPDATE_FAILED: "common.updateFailed",
  MILESTONE_FORM_HEADER_RPL_STR: "lifestageMilestone.milestoneFormHeaderRplStr",
  MILESTONE_COLUMN_HEADER_RPL_STR: "lifestageMilestone.milestoneColumnHeaderRplStr"
};
