import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconExpandDown, Loader } from "ui";
import { useApi } from "utils";
import axios from "axios";
import { API_URLS } from "../../../constants/apiUrls";
import { PET_LIST_PETS_INITIALLY_VISIBLE, PET, SNOWFLAKE, NOTIFICATION, MESSAGES } from "../../../constants";
import { PetType } from "../../../../types";
import { PetTile, AddPetTile } from "../PetTile";
import Heading from "../../Heading";
import { Auth, useAuth } from "../../../hooks/use-auth";
import { shiftWords } from "../../../lib/common";
import getSfMergedPets from "../../../lib/common/snowflakeProcessing";
import { useSnowFlakePets } from "../../../hooks/useSnowflakeData";
import { notificationObjType } from "../types";
import { RightIcon } from "../../../icons/";
import SnowFlakeModal from "../../snowflakeModal/index";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { addUpdatePets, getChangedAttributes, processPets } from "../../snowflakeModal/utils";
import { usePetsApiCall } from "../../../hooks/use-apiCall";
import Notification from "./../Notification";
import { NotificationObj } from "../../../hooks/use-auth";
import { useToastConfig } from "../../../lib/toast";
import { useFeatureFlagsConfig } from "../../../hooks/useFeatureFlags";
import { NOTIFICATION_TYPE } from "../../../constants/notification";

const PetPortalName = () => {
  const auth: Auth = useAuth();
  const { t } = useTranslation();
  return (
    <div data-testid="heading">{shiftWords() ? `${t(`addEditPet.petPortal`)} ${
      auth?.user?.profile?.firstName ?? ""
    }` : `${
      auth?.user?.profile?.firstName + t(`addEditPet.apostropheS`) ?? ""
    } ${t(`addEditPet.petPortal`)} `}</div>
  );
};
const NoPet = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-3xl font-extrabold">
        <PetPortalName />
      </h1>
      <p className="mb-15 mt-6 text-lg font-normal lg:w-2/3">
        {`${t("noPet.warningOne")} “${t(PET.ADD_A_PET)}” ${t("noPet.warningTwo")}`}
      </p>
    </>
  );
};

const NotificationIds = {
  SNOWFLAKE_NOTIFICATION: 'snowflake-notification',
  REBATE_NOTIFICATION: 'rebate-notification'
};

export default function PetList() {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [wholePetList, setWholePetList] = useState<PetType[]>([]);
  const [petList, setPetList] = useState<PetType[]>([]);
  const [showMore, setShowMore] = useState(true);
  const [isDataloading, setIsDataloading] = useState(true);
  const [isNotificationLoading, setIsNotificationLoading] = useState(true);
  const [notificationObj, setNotificationObj] = useState<NotificationObj>();
  const { t } = useTranslation();
  const region = window?.hph_locale?.split('-')[1];
  const { snowflakePets, isSfDataLoading, fetchSnowflakePets } = useSnowFlakePets();
  const [notification, setNotification] = useState<notificationObjType[]>([]);
  const [showSfModal, setShowSfModal] = useState<boolean>(false);
  const petConfig = usePetConfig();
  const {addEditPet,updatePetApi} = usePetsApiCall();
  const { showToast } = useToastConfig();
  const [isFetchPetsLoading,setFetchPetsLoading] = useState(true);
  const { rebateNotification, rebateNotification_url } = useFeatureFlagsConfig();
  const auth: Auth = useAuth();
  //Api configs
  //fetch pet Api
  const { exec: fetchPetListData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        window.scrollTo(0, 0);
        if (apiResponse.data.serviceStatus.code === 200) {
          const sfComparedData = getSfMergedPets(apiResponse.data.data, snowflakePets.rememberedPets as Array<PetType>);
          const filteredList = sfComparedData.filter(
            (pet: PetType) => pet.petStatusId !== 2
          );
          setWholePetList(filteredList);
          setShowMore(filteredList.length > PET_LIST_PETS_INITIALLY_VISIBLE);
          setPetList(filteredList.slice(0, PET_LIST_PETS_INITIALLY_VISIBLE));
          setFetchPetsLoading(false);
        } else {
          setShowMore(false);
        }
        setIsDataloading(false);
      });
  });

  const {exec: fetchNotificationData} = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
    .get(`${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_NOTIFICATIONS}`,
    {headers: {  locale : window.hph_locale , UID :auth.user?.UID, notificationtypeid:NOTIFICATION_TYPE.REBATE}}).then((apiResponse)=>{
      if (apiResponse.data.serviceStatus.code === 200) {
        setNotificationObj(apiResponse.data.data as NotificationObj);
        setIsNotificationLoading(false);
      }
    
    })
  });



  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (!isSfDataLoading) {
      fetchPetListData();
      fetchNotificationData();
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [isSfDataLoading]);

  useEffect(() => {
    if (!isSfDataLoading && !isFetchPetsLoading) {
      // check if snowflakePets has any pets
      // if yes, then show notification but also check the wholePetList for attribute change
      const snowflakeNotification = notification.find((obj) => obj.id === NotificationIds.SNOWFLAKE_NOTIFICATION);

      if (snowflakePets?.newPets?.length > 0 && wholePetList.length === 0) {
        //step 3 - show modal
        setShowSfModal(true);
      } else {
        //step 2 - show notification
        if(showSfModal){
          setShowSfModal(false);
        }
        const updatedPets = snowflakePets?.updatedPets?.filter((pet) => {
          const hphPet = wholePetList.find((hphPet: PetType) => hphPet.petName.toLocaleLowerCase() === pet.petName.toLocaleLowerCase());
          if (hphPet) {
            const attributes = getChangedAttributes(hphPet, pet, petConfig);
            return attributes && attributes.length;
          }
        });
        if ((snowflakePets?.newPets?.length > 0 || updatedPets?.length > 0)) {
          const notificationObj = [{
            id: NotificationIds.SNOWFLAKE_NOTIFICATION,
            header: t(SNOWFLAKE.NOTIFICATION_HEADER),
            body: t(SNOWFLAKE.NOTIFICATION_BODY),
            ctaOneText: t(SNOWFLAKE.NOTIFICATION_ACTION),
            ctaOneAction: async () => {
              setShowSfModal(true);
            },
            closeCtaAction: async (notificationObj: notificationObjType, notification: Array<notificationObjType>) => {
              const petsToAddUpdate = processPets(wholePetList, snowflakePets, false, petConfig);
              setIsDataloading(true);
              const error = await addUpdatePets(petsToAddUpdate, updatePetApi, addEditPet);
              setIsDataloading(false);
              if (error.length) {
                showToast({
                  title: `${t(MESSAGES.ERROR)}`,
                  description: `${t(MESSAGES.ERROR)}`,
                  type: "error",
                });
              } else {
                showToast({
                  title: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
                  description: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
                  type: "success",
                });
              }
              closeNotification(notificationObj, notification);
              fetchSnowflakePets();
            },
            icon: RightIcon
          }]

          if (!snowflakeNotification) {
            setNotification([...notification, ...notificationObj]);
          }
        } else {
          const updatedNotification = notification.filter((obj) => {
            return obj.id !== NotificationIds.SNOWFLAKE_NOTIFICATION;
          });
          setNotification(updatedNotification);
        }
      }
    }
  }, [isSfDataLoading, wholePetList, snowflakePets,isFetchPetsLoading])

  useEffect(() => {
    if(rebateNotification && !isNotificationLoading && notificationObj?.showNotification) {
      const notificationObj = [
        {
          id: NotificationIds.REBATE_NOTIFICATION,
          header: t(NOTIFICATION.REBATE_HEADER),
          body:  t(NOTIFICATION.REBATE_BODY),
          ctaOneText: t(NOTIFICATION.REBATE_PRIMARY_ACTION),
          ctaOneAction: async (notificationObj : notificationObjType,notification:Array<notificationObjType>,isChecked:boolean) => {
            isChecked && auth.setNotification && auth.setNotification({notificationTypeId: NOTIFICATION_TYPE.REBATE, showNotification: false});
            closeNotification(notificationObj,notification);
          },
          ctaOneStyle: "bg-white hover:bg-blue-900 text-blue-900 font-semibold hover:text-white py-2 px-3 border border-blue-900 hover:border-transparent rounded mb-2 mr-2 lg:mb-0 text-sm",
          ctaTwoText: t(NOTIFICATION.REBATE_SECONDARY_ACTION),
          ctaTwoAction: (notificationObj : notificationObjType,notification:Array<notificationObjType>) => {
            auth.setNotification && auth.setNotification({notificationTypeId: NOTIFICATION_TYPE.REBATE, showNotification: false});
            closeNotification(notificationObj,notification)
            window.open(rebateNotification_url, '_blank')
          },
          ctaTwoStyle: "px-3 py-2 text-white font-semibold bg-blue-900 rounded mr-0 text-sm",
          showCheckbox: true,
          checkboxLabel: t(NOTIFICATION.REBATE_CHECKBOX_LABEL),
          position: "right",
          closeCtaAction: async (notificationObj : notificationObjType,notification:Array<notificationObjType>,isChecked:boolean) =>{
            isChecked && auth.setNotification && auth.setNotification({notificationTypeId: NOTIFICATION_TYPE.REBATE, showNotification: false});
            closeNotification(notificationObj,notification)
          },
        },
      ];
      const isDuplicate = notification.some((existingNotification) => existingNotification.id === notificationObj[0].id);
  
      if (!isDuplicate && rebateNotification) {
        setNotification([...notification, ...notificationObj]);
      }
    }
  }, [rebateNotification,isNotificationLoading]);

  function showMorePets() {
    setShowMore(!showMore);
    setPetList(wholePetList);
  }

  const closeNotification = (notificationObj: notificationObjType,notification:Array<notificationObjType>) => {
    const updatedNotification = notification.filter((obj) => {
      return obj.id !== notificationObj.id;
    })
    setNotification(updatedNotification);
  }

  if(isSfDataLoading){
    return <Loader />
  }

  return (
    <>
      <div>
        {notification.length ? (
          <div>
            {notification.map((notificationObj, index) => (
              <Notification
                key={notificationObj.id || index}
                notificationObj={notificationObj}
                notification={notification}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className="py-0 lg:py-8">
        {isDataloading ? (
          <Loader />
        ) : (
          <>
            {petList.length ? (
              <Heading name={<PetPortalName />} classes="hidden md:block" />
            ) : (
              <NoPet />
            )}
            <div className="flex flex-col">
              <div
                className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3"
                role="list"
                aria-label="pets"
              >
                {petList &&
                  petList.map((pet: PetType) => (
                    <PetTile pet={pet} key={pet.petId} updatedPets={snowflakePets.updatedPets} fetchSnowflakePets={fetchSnowflakePets} />
                  ))}
                {!showMore && <AddPetTile />}
              </div>
              {showMore && (
                <Button
                  variant="primary"
                  mode="link"
                  classes="self-end flex items-center"
                  onClick={showMorePets}
                >
                  <IconExpandDown width="24" height="24" />{" "}
                  {region === 'ca' ? (
                    <span className="ml-5">
                      {showMore ? t("common.more") : t("common.less")}
                      {" "}{t("common.pets")}
                    </span>
                  ) : (
                    <span className="ml-5">
                      {t("common.view")}{" "}
                      {showMore ? t("common.more") : t("common.less")}
                    </span>
                  )}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      {
        showSfModal &&  <SnowFlakeModal
              hphPets={wholePetList}
              setShowSfModal={setShowSfModal}
              snowflakePets={snowflakePets}
              showSfModal={showSfModal}
            />
      }
    </>
  );
}