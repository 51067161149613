import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { MESSAGES, SNOWFLAKE } from "../../constants";
import { PetType } from "../../../types";
import { PetObject } from "./index";
import PetList from "./PetList";
import { ModPetType, addUpdatePets } from "./utils";
import { DogCat } from "../../icons";
import { usePetsApiCall } from "../../hooks/use-apiCall";
import { useToastConfig } from "../../lib/toast";
import { useState } from "react";

const AddPetModal = ({
    hphPets,
    snowflakePets,
    selectedPets,
    setSelectedPets,
    setOpenleftOutPetModal,
    setStartFromScratchModal,
    setShowPetsAddedSuccessModal,
    wrapperLoaderState
}: {
    hphPets: Array<PetType>;
    snowflakePets: PetObject,
    selectedPets: Array<ModPetType>,
    setSelectedPets: (val: Array<ModPetType>) => void
    setOpenleftOutPetModal: (val: boolean) => void;
    setStartFromScratchModal: (val: boolean) => void;
    setShowPetsAddedSuccessModal: (val: boolean) => void;
    wrapperLoaderState: boolean;
}) => {

    const { t } = useTranslation();
    const { addEditPet, updatePetApi } = usePetsApiCall();
    const { showToast } = useToastConfig();
    const [isLoading, setIsLoading] = useState(false);
    
    const makeAddDeletePetApiCall = async (selectedPets:Array<PetType>) => {
        const error = await addUpdatePets(selectedPets, updatePetApi, addEditPet,[]);
        if (error.length) {
            showToast({
                title: `${t(MESSAGES.ERROR)}`,
                description: `${t(MESSAGES.ERROR)}`,
                type: "error",
            });
        }else{
            showToast({
              title: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
              description: `${t(MESSAGES.PET_UPDATE_MESSAGE)}`,
              type: "success",
            });
          }
          return error;
    }

    
    const onSubmit = async () => {
        //show new Modal saying pets are added/updated
        //check if selected pets and new pets have same count
        if(selectedPets.length === snowflakePets.newPets.length){
            setIsLoading(true);
            const error = await makeAddDeletePetApiCall(selectedPets);
            setIsLoading(false);
            if (error.length === 0) {
                setShowPetsAddedSuccessModal(true); 
            }
        }else{
            setOpenleftOutPetModal(true);
        }
    };

    const startOver = () => {
        !isLoading && setStartFromScratchModal(true);
    }

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                return (
                    <form id="newPets" onSubmit={handleSubmit} role="form">
                        <div className="p-5">
                            <div className="flex flex-col items-center h-full">
                                {/* {header} */}
                                <div className="w-full">
                                    <div
                                        className="flex items-center justify-center p-1 text-center text-2xl font-bold lg:mx-28 lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {DogCat}
                                    </div>
                                    <div
                                        className="flex items-center justify-center px-0 text-center text-2xl font-bold  lg:px-9"
                                        data-testid="modal-title"
                                    >
                                        {t(SNOWFLAKE.ADD_PET__MODAL_TITLE)}
                                    </div>

                                    <div className="flex flex-col items-start justify-start py-4 px-8 md:px-12">
                                        <div>
                                            {t(SNOWFLAKE.ADD_PET__MODAL_PARA_1)}
                                        </div>
                                        <div>
                                            {t(SNOWFLAKE.ADD_PET__MODAL_PARA_2)}
                                        </div>
                                    </div>
                                </div>

                                {/* {body} */}
                                <div className="flex flex-row justify-start w-full p-8 md:px-12">
                                    <div className=" h-1/5 w-full">
                                        <PetList hphPets={hphPets} snowflakePets={snowflakePets} isEditable={true} setSelectedPets={setSelectedPets} />
                                    </div>
                                </div>
                                {/* {footer} */}
                                <div className="mt-auto">
                                    <div className="flex flex-col px-8 md:px-12 m-2">
                                        <div className="flex flex-col items-center">
                                            <Button
                                                type="submit"
                                                mode="contained"
                                                variant="primary"
                                                isLoading={isLoading || wrapperLoaderState}
                                                classes="lg:mt-2 w-full py-4 font-bold text-xl px-4 mb-5 flex justify-center items-center"
                                                disabled={selectedPets.length === 0}
                                            >
                                                {t(SNOWFLAKE.ADD_PET_MODAL_ACTION_1)}
                                            </Button>
                                            <div className="w-full  sm:text-center">
                                                <span>{t(SNOWFLAKE.ADD_PET_MODAL_TEXT)}</span>
                                            </div>
                                            <Button
                                                type="button"
                                                mode="contained"
                                                variant="primary"
                                                onClick={startOver}
                                                isLoading={isLoading || wrapperLoaderState}
                                                classes="lg:mt-2 w-full py-4 font-bold text-xl mb-1 bg-white border border-[#C4132A] text-[#C4132A] mt-2 justify-center items-center"
                                            >
                                                {t(SNOWFLAKE.ADD_PET_MODAL_ACTION_2)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        />
    )
};

export default AddPetModal;
