/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Form, FormSpy, Field } from "react-final-form";
import {
  calculateAge,
  calculateDateOfBday,
  useApi,
  prepareFormDataWithImage,
  scrollToElement,
} from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  composeValidators,
  maxLength,
  required,
  Button,
  FormControl,
  dateGreaterThan,
  alphaNumericFieldsValidation,
  charValidationWithEmptyString,
} from "ui";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Area } from "react-easy-crop";

import BreedSelection from "./BreedSelection/BreedSelection";
import BreedSize from "./BreedSize/BreedSize";
import useRequireAuth from "../../../hooks/useRequireAuth";
import {
  MAX_CHAR_NAME,
  MESSAGES,
  PET,
  PET_HIDE_REASONS,
  YEARS_IN_DIFFERENT_LOCALES,
} from "../../../constants";
import {
  BreedType,
  LifeStagesType,
  ModifierType,
  PetGender,
  PetTypes,
} from "../../../../types";

import { API_URLS } from "../../../constants/apiUrls";
import { useToastConfig } from "../../../lib/toast";
import {
  HideAndRemovePetApiOption,
  PetProfileKeyType,
  PetProfileType,
} from "../types";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { FormApi, MutableState, Tools } from "final-form";
import HideDeleteModals from "../HideDeleteModal";
import { Breadcrumb } from "../../../lib/breadcrumb";
import Heading from "../../Heading";
import { ADD_EDIT_PET } from "../../../constants/addEditPet";
import { BREADCRUMB_CONFIG, ROUTES_PATH } from "../../../constants/routes";
import PhotoUpload from "./PhotoUpload";
import PetProfilePhoto from "../PetProfilePhoto";

import { Datepicker } from "../../../lib/Datepicker";
import BrandSelection from "./BrandSelection/BrandSelection";
import { FOOD_BRANDS, IFOOD_BRANDS } from '../../../constants/foodSelectOtherBrands';
import { PAGE_ACTIONS } from "../../../constants/actions";
import FoodSelectionModal from "./FoodSelectionModal/FoodSelectionModal";
import { useFeatureFlagsConfig } from "../../../hooks/useFeatureFlags";
// for mock products
// import { HILLS_PRODUCTS } from "../../../mocks/fixtures/hillsProducts";
import { shiftWords } from "../../../lib/common";
import { editIcon } from "../../../icons/editIcon";
import DeceasedPet from "../../DeceasedPet";
import { useFoodSelectDataContext } from "./FoodSelectionModal/Contexts/FoodSelectDataContext";

export interface CustomizedState {
  state: { petId: string, action: string, showIsDeceased: boolean};
}

interface FormErrors{
  petAdoptDate?:string,
  petBirthday?:string,
  petName?:string
}

const AddEditPet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // useState hooks
  const {
    lifeStages,
    petTypes,
    petGender,
    breeds,
    petStatusByName,
    petTypesByName,
    petFoodTypes,
  } = usePetConfig();

  const { foodSelect } = useFeatureFlagsConfig();
  const { loadHillsProducts, hillsProducts, isDataLoading } = useFoodSelectDataContext();
  const [age, setAge] = useState("");
  const [petName, setPetName] = useState("Pet");
  const [petNickName, setPetNickName] = useState("");
  const [petTypeId, setPetTypeId] = useState(1);
  const [hideDialogOpen, setHideDialogOpen] = useState(false);
  const [foodSelectModalOpen, setFoodSelectModalOpen] = useState(false);
  const [showReasonDialogDelete, setShowReasonDialogDelete] = useState(false);
  const [petFoodTypeDetails, setPetFoodTypeDetails] = useState<string | IFOOD_BRANDS | undefined>("");
  const [foodSelectProduct, setFoodSelectProduct] = useState<{ id: string; image: string; name: string } | null>(null);
  const [imageToUpload, setImageToUpload] = useState<{
    file: File;
    rendition: Area;
  }>();
  const [previousImage, setPreviousImage] = useState("");
  const [petImage, setPetImage] = useState("");
  const [petStatusId, setPetStatusId] = useState(null);
  const { showToast } = useToastConfig();
  const [loading, setLoading] = useState<{
    petId?: string;
    petStatusId?: number;
  }>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const location = useLocation();
  const petId = (location as CustomizedState)?.state?.petId;
  const action = (location as CustomizedState)?.state?.action;
  const isPetDeceased = (location as CustomizedState)?.state?.showIsDeceased;
  const lastRefElement = useRef<HTMLDivElement>(null);
  const [initData, setInitData] = useState<Record<string, unknown>>({
    petName: "",
    petNickName: "",
    petsAge: age,
    petLifeStage: {
      petLifeStageId: 0,
      petLifeStageName: "",
      petTypeId: "1",
      petAgeFrom: 0,
      petAgeTo: 0,
    },
    petBirthday: "",
    petAdoptDate: "",
    petsAgeMonth: 0,
    petBreed: "",
    petGenderId: "1",
    petSizeId: "",
    petTypeId: "1",
    petBirthdateTypeId: "1",
    petFoodTypeId: 0,
    petFoodTypeDetails: "",
    petRemovalReason: "",
    selectedFood: undefined
  });
  const [formValues, setFormValues] = useState(initData);
  // Ref to store the form object
  const formRef = useRef<FormApi<PetProfileType, Partial<PetProfileType>> | null>(null);
  const [resetFiltersTrigger, setResetFiltersTrigger] = useState(false);
  const [lastPetTypeId, setLastPetTypeId] = useState<number | string | null>(null);

  useEffect(() => {
    if (foodSelectModalOpen) {
      // When the modal opens, check if the pet type has changed since the last opening
      if (lastPetTypeId !== null && formValues.petTypeId !== lastPetTypeId) {
        setResetFiltersTrigger(true); // Trigger filter reset only if pet type has changed
      }
      // Update the last pet type to the current pet type
      setLastPetTypeId(formValues.petTypeId as string);
    } else {
      // When the modal closes, reset the trigger
      setResetFiltersTrigger(false);
    }
  }, [foodSelectModalOpen, lastPetTypeId, formValues.petTypeId]);

  useEffect(() => {
    if (!isDataLoading && petTypeId) {
      loadHillsProducts(petTypeId, true);
    }
  }, [petTypeId, isDataLoading, loadHillsProducts]);

  useEffect(() => {
    // Check if the component is mounting (initial render) or if petFoodTypeInfo has changed
    if ((typeof formValues.petFoodTypeInfo === 'string' && formValues.petFoodTypeId === '1') && !foodSelectProduct) {
      const selectedProduct = hillsProducts.find((product) => product.id === formValues.petFoodTypeInfo);
      if (selectedProduct) {
        setFoodSelectProduct({
          id: selectedProduct.id,
          image: selectedProduct.primaryProductImageUrl,
          name: selectedProduct.name,
        });
      }
    }
    // Add foodSelectProduct to the dependency array to avoid overwriting when a new product is selected
  }, [formValues, hillsProducts, foodSelectProduct]);

  const updateSelectedFood = (selectedFoodId: string | undefined) => {
    if (formRef.current) {
      // Update the form state with the new selected food ID
      formRef.current.change('selectedFood', selectedFoodId);
  
      // update the local state with the newly selected product details
      const selectedProduct = hillsProducts.find((product) => product.id === selectedFoodId);
      if (selectedProduct) {
        setFoodSelectProduct({
          id: selectedProduct.id,
          image: selectedProduct.primaryProductImageUrl,
          name: selectedProduct.name,
        });
      }
    }
  };

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    window.scrollTo(0, 0);
    if (
      petId &&
      (lifeStages as Array<LifeStagesType>).length !== 0 &&
      (breeds as Array<BreedType>).length !== 0 &&
      (petTypes as Array<PetTypes>).length !== 0
    ) {
      fetchMyPetData();
    } else {
      setInitData({
        petName: "",
        petNickName: "",
        petsAge: age,
        petLifeStage: {
          petLifeStageId: 0,
          petLifeStageName: "",
          petTypeId: "1",
          petAgeFrom: 0,
          petAgeTo: 0,
        },
        petBirthday: "",
        petAdoptDate: "",
        petsAgeMonth: 0,
        petBreed: "",
        petGenderId: "1",
        petSizeId: "",
        petTypeId: "1",
        petBirthdateTypeId: "1",
        petFoodTypeId: 0,
        petFoodTypeDetails: "",
      });
      setPetImage("");
      setPetNickName("");
    }

    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [lifeStages, breeds, petTypes, location as CustomizedState]);

  // Pet age 25+ years warning message
  useEffect(() => {
    const splitAge = age.split(" ");
    (splitAge[1]?.toLowerCase() === t('addEditPet.years').toLowerCase()) &&
      parseInt(splitAge[0]) > 25 &&
      showToast({
        title: "AgeWarning",
        description: `${t(
          ADD_EDIT_PET.PET_AGE_MAX_WARNING_1
        )} ${petNickName}'s ${t(
          ADD_EDIT_PET.PET_AGE_MAX_WARNING_2
        )} ${age}. ${t(ADD_EDIT_PET.PET_AGE_MAX_WARNING_3)}?`,
        type: "warning",
      });
  }, [age]);

  useEffect(() => {
    if(action === PAGE_ACTIONS.editPetsFood) {
      scrollToPetsFoodSection();
    }
  }, [action])

  // custom functions

  const scrollToPetsFoodSection = () => {
    const timeOut = setTimeout(() => {
      scrollToElement(lastRefElement.current);
      clearTimeout(timeOut);
    }, 10);
  };

  const isValueChanged = (isModifiedValues: ModifierType) => {
    return Object.keys(isModifiedValues);
  };

const prepareObjToSend = (
    isModifiedValues: ModifierType,
    values: PetProfileType
  ) => {
    const petDataValues = {} as Partial<PetProfileType>;
    isValueChanged(isModifiedValues).forEach((key) => {
      const excludeValues = [
        "petsAge",
        "petsAgeMonth",
        "petsAgeYear",
      ];
      const modifiedKey = key as keyof PetProfileKeyType;
      modifiedKey === "petLifeStage.petLifeStageName"
        ? (petDataValues["petLifeStageId"] = (
            values["petLifeStage"] as LifeStagesType
          ).petLifeStageId)
        : excludeValues.indexOf(modifiedKey) !== -1
        ? null
        : modifiedKey === "petBreed"
        ? (petDataValues["petBreedId"] = (values["petBreed"] as BreedType)?.petBreedId as number || null)
        : modifiedKey === "petBirthday"
        ? (petDataValues["petBirthday"] = values["petBirthday"])
        : modifiedKey === "petAdoptDate"
        ? (petDataValues["petAdoptDate"] = values["petAdoptDate"])
        : modifiedKey === "petGenderId"
        ? (petDataValues["petGenderId"] = Number(values["petGenderId"]))
        : modifiedKey === "petTypeId"
        ? (petDataValues["petTypeId"] = Number(values["petTypeId"]))
        : modifiedKey === "petBirthdateTypeId"
        ? (petDataValues["petBirthdateTypeId"] = Number(values["petBirthdateTypeId"]))
        : modifiedKey === "petSizeId"
        ? (petDataValues["petSizeId"] = Number(values["petSizeId"]) || null)
        : modifiedKey === "petFoodTypeId"
        ? (petDataValues["petFoodTypeId"] = Number(values["petFoodTypeId"]) || undefined)
        : modifiedKey === "petFoodTypeDetails"
        ? (petDataValues["petFoodTypeInfo"] = (values["petFoodTypeDetails"] as IFOOD_BRANDS)?.brandName?.toString() || undefined)
        : modifiedKey === "petNickName"
        ? (petDataValues["petNickName"] = values["petNickName"] || petNickName)
        : (petDataValues[modifiedKey] = values[modifiedKey]);
    });
    if (!petId && !petDataValues["petGenderId"])
      petDataValues["petGenderId"] = petGender[0].petGenderId;

    if (!petId && !petDataValues["petTypeId"])
      petDataValues["petTypeId"] = petTypesByName.Dog;

    if (!petId && !petDataValues["petBirthdateTypeId"])
      petDataValues["petBirthdateTypeId"] = 1;


    const petFoodTypeId = Number(values["petFoodTypeId"]);
      if (petFoodTypeId === 1) {
        petDataValues["petFoodTypeInfo"] = foodSelectProduct?.id;
      } else if (petFoodTypeId === 3) {
        petDataValues["petFoodTypeInfo"] = `${t(ADD_EDIT_PET.HOME_MADE)}`
      } else if (petFoodTypeDetails) {
        petDataValues["petFoodTypeInfo"] = values["petFoodTypeDetails"]?.brandName?.toString();
      } 

    petDataValues.petId = petId;
    // exclude the selectedFood field from form submission
    delete petDataValues.selectedFood;

    return {
      petData: [petDataValues],
    };
  };

  const onSubmit = (
    values: PetProfileType,
    form: FormApi<PetProfileType, Partial<PetProfileType>>
  ) => {

    //mandatory chck for validation
    if(values.petBirthday && values.petAdoptDate && new Date(values.petBirthday) > new Date(values.petAdoptDate)){
      showToast({
        title: `${t(ADD_EDIT_PET.ADDED)}`,
        description: `${t("addEditPet.birthdayGreaterThanAdoptDate")}`,
        type: "error",
      });
     
    }else{
      const updatedValues = prepareFormDataWithImage(
        prepareObjToSend(form.getState().dirtyFields, values),
        imageToUpload?.file,
        imageToUpload?.rendition
          ? {
              width: imageToUpload.rendition.width,
              height: imageToUpload.rendition.height,
              left: imageToUpload.rendition.x,
              top: imageToUpload.rendition.y,
            }
          : undefined
      );
      return petId ? updatePetApi(updatedValues) : addEditPet(updatedValues);
    }
  };

  const filterLifeStage = (age: number, petTypeId: number) => {
    return lifeStages.find(
      (stage: LifeStagesType) =>
        stage.petTypeId === petTypeId &&
        age >= stage.petAgeFrom &&
        age <= stage.petAgeTo
    );
  };

  //Api configs
  // hide and delete pet api
  const { exec: fetchMyPetData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}/${petId}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          const {
            petName,
            petNickName,
            petTypeId,
            petBreedId,
            petGenderId,
            petSizeId,
            petBirthday,
            petAdoptDate,
            petLifeStageId,
            petVetName,
            petImage,
            petStatusId,
            petBirthdateTypeId,
            petFoodTypeId,
            petFoodTypeInfo,
            petRemovalReason
          } = apiResponse.data.data[0];

          const petLifeStage = lifeStages.find(
            (stage: LifeStagesType) => stage.petLifeStageId === petLifeStageId
          );
          const petBreed = breeds.find(
            (breed: BreedType) => breed.petBreedId === petBreedId
          );
          const petFoodTypeDetails = petFoodTypeInfo ? FOOD_BRANDS.find((brand: IFOOD_BRANDS) => brand?.brandName === petFoodTypeInfo) : ""
          setPetTypeId(petTypeId);
          setPetName(petName);
          setPetNickName(petNickName);
          setPetStatusId(petStatusId);
          setPetFoodTypeDetails(petFoodTypeDetails);
          const petAge = calculateAge(petBirthday, t);
          
          const response = {
            petName: petName,
            petNickName: petNickName,
            petsAge: petAge,
            petLifeStage: {
              petLifeStageId: petLifeStageId,
              petLifeStageName: t(`pet.${petLifeStage?.petLifeStageName}`),
              petTypeId: petLifeStage?.petTypeId,
              petAgeFrom: petLifeStage?.petAgeFrom,
              petAgeTo: petLifeStage?.petAgeTo,
            },
            petBirthday: petBirthday,
            petAdoptDate: petAdoptDate ? petAdoptDate : "",
            petsAgeMonth: 0,
            petBreed: petBreed,
            petGenderId: petGenderId?.toString(),
            petSizeId: petSizeId?.toString(),
            petTypeId: petTypeId?.toString(),
            petVetName,
            petBirthdateTypeId: petBirthdateTypeId?.toString(),
            petFoodTypeId: petFoodTypeId?.toString(),
            petFoodTypeDetails: petFoodTypeDetails,
            petFoodTypeInfo: petFoodTypeInfo,
            petRemovalReason: petRemovalReason
          };
          setPetImage(petImage);
          setPreviousImage(petImage);
          setInitData(response);
        }
      });
  });

  const { exec: updatePetApi } = useApi((options: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, options)
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t(
              `addEditPet.${apiResponse.data.serviceStatus.message}`
            )}`,
            type: "success",
          });
          navigate(ROUTES_PATH.PET_DASHBOARD, {
            state: { 
              foodSelectProduct: {
                id: foodSelectProduct?.id,
                image: foodSelectProduct?.image,
                name: foodSelectProduct?.name,
              }
            },
             replace: true,
          });
        } else {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });

  const { exec: addEditPet } = useApi((data: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .post(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, data, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t(MESSAGES.PET_ADDED_MESSAGE)}`,
            type: "success",
          });
          window.scrollTo(0, 0);
          navigate(ROUTES_PATH.DASHBOARD, { replace: true });
        } else {
          //todo show error message
          showToast({
            title: `${t(ADD_EDIT_PET.REMOVED)}`,
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });
  //callback after  clicking  of  confirm  button
  const removeHidePet = (values: HideAndRemovePetApiOption[]) => {
    setLoading({
      petStatusId: values[0].petStatusId as number,
    });
    updatePetApi(prepareFormDataWithImage({ petData: values }));
  };

  const setProfilePhoto = (
    file?: File,
    croppedImage?: string,
    coordinates?: Area
  ) => {
    setPetImage(croppedImage ? croppedImage : previousImage);
    if (file && coordinates) {
      setImageToUpload({ file, rendition: coordinates });
    }
  };

  const petLifeStageMutator = (
    args: [string, number?],
    state: MutableState<PetProfileType, Partial<PetProfileType>>,
    utils: Tools<PetProfileType, Partial<PetProfileType>>
  ) => {
    const ageNumber = parseInt(calculateAge(args[0], t, true));
    const currentPetTypeId = args[1];
    
    let updatedPetLifeStageName: { petLifeStageName?: string } | undefined;
    utils.changeValue(state, "petLifeStage", () => {
      const age = calculateAge(args[0], t);
      setAge(calculateAge(args[0], t));
      let filteredLifeStage;
      petTypes.every((type: PetTypes) => {
        if (Number(currentPetTypeId) === type.petTypeId) {
          if (YEARS_IN_DIFFERENT_LOCALES.indexOf(age.split(" ")[1]) > -1) {
            filteredLifeStage = filterLifeStage(ageNumber, type.petTypeId);
            return false;
          } else {
            filteredLifeStage = lifeStages.find(
              (stage: LifeStagesType) =>
              stage.petTypeId === type.petTypeId && stage.petAgeFrom === 0
              );                              
            return false;
          }
        }
        return true;
      });
      
      if (filteredLifeStage && (filteredLifeStage as unknown as { petLifeStageName: string })
      ?.petLifeStageName as string) {
        updatedPetLifeStageName = { petLifeStageName: (filteredLifeStage as unknown as { petLifeStageName: string })
        ?.petLifeStageName as string };
      }
      return filteredLifeStage;
    });

    utils.changeValue(state, "petLifeStage.petLifeStageName", () => {
      if(currentPetTypeId==2 && (ageNumber > 1 && ageNumber<=7)) {   //This is only for adult cat.
        return t(`pet.CatAdult`) || "";
      } else {
        return t(`pet.${updatedPetLifeStageName?.petLifeStageName}`) || ""; //others work fine using backend keys
      }
    });
  };

  const hideUnhidePet = (petId: string) =>{
    if(petStatusId === petStatusByName.Remembered){
      //means we are viewing remembered pets and this CTA event is for unhiding the pet.
      setLoading({
        petId:petId,
        petStatusId: petStatusByName.Remembered,
      });
      updatePetApi(
        prepareFormDataWithImage({
          petData: [
            {
              petId: petId,
              petStatusId: petStatusByName.Active,
            },
          ],
        })
      );
    }else{
      setHideDialogOpen(true);
    }
  }

  const redirectToPreviousPage = () => {
    if ((action === PAGE_ACTIONS.editFromPetDetails) || (action === PAGE_ACTIONS.editPetsFood)) {
      navigate(ROUTES_PATH.PET_DASHBOARD, { replace: true });
    } else {
      navigate(ROUTES_PATH.DASHBOARD, { replace: true });
    }
  }

  const handleOpenModal = () => {
    setFoodSelectModalOpen(true);
  };

  //auth check
  const auth = useRequireAuth();
  if (!auth || !auth?.user?.UID) return null;
  const region = window?.hph_locale;
  return (
    <>
      <Breadcrumb
        config={[
          BREADCRUMB_CONFIG.home,
          BREADCRUMB_CONFIG.petDashboard,
          petId
            ? { ...BREADCRUMB_CONFIG.pet, label: petNickName, translate: false }
            : BREADCRUMB_CONFIG.addPet,
        ]}
      />
      {isPetDeceased ? <DeceasedPet
       petId={petId}
       petName={petName} 
       petImage={petImage} 
       petBirthday={initData?.petBirthday as string}
       petBreed={initData?.petBreed as BreedType}
       removeHidePet={removeHidePet}
       isHomePage={true}
      /> :
      <div className="mb-20">
        <Heading name={petId ? t(PET.EDIT_PET) : t(PET.ADD_PET)} classes="!mb-6 mt-6"/>
        <div className="rounded">
            <Form
              initialValues={initData}
              onSubmit={onSubmit}
              validate={(values)=>{
                const errors:FormErrors = {};
               if(values.petAdoptDate!=null && values.petBirthday!=null && new Date(values.petBirthday)> new Date(values.petAdoptDate) ){
                errors.petAdoptDate = `${t("validations.greater")}${t(ADD_EDIT_PET.PETS_BIRTHDAY)}`;
               }
               return errors;
              }}
              mutators={{
                setFormAge: (args, state, utils) => {
                  utils.changeValue(state, "petsAge", () =>
                    calculateAge(args[0], t)
                  );
                  petLifeStageMutator(args, state, utils);
                },
                setFormDOB: (args, state, utils) => {
                  utils.changeValue(state, "petBirthday", () =>
                    calculateDateOfBday(args[0], args[1])
                  );
                  utils.changeValue(state, "petsAge", () =>
                    calculateAge(calculateDateOfBday(args[0], args[1]), t)
                  );
                  petLifeStageMutator(
                    [calculateDateOfBday(args[0], args[1]), petTypeId],
                    state,
                    utils
                  );
                },
                setPetSize: (args, state, utils) => {
                  utils.changeValue(state, "petSizeId", () =>
                    args?.[0]?.petSizeId.toString()
                  );
                },
                setFoodTypeDetails: () => {
                  const timeOut = setTimeout(() => {
                    clearTimeout(timeOut);
                  })
                },
                setLifeStage: (args, state, utils) => {
                  petLifeStageMutator(args, state, utils);
                },
              }}
              render={({
                handleSubmit,
                form,
                values,
                submitting,
                pristine,
                valid,
                touched,
                errors
              }) => {
                formRef.current = form as FormApi<PetProfileType, Partial<PetProfileType>>;
              return (
                <form onSubmit={handleSubmit} role="form">
                  <Field name="selectedFood" component="input" type="hidden" />
                  <FormSpy
                    onChange={(props) => {
                      if (props.active === "petTypeId") {
                        setPetTypeId(props.values.petTypeId);
                        props.values.petsAge = "";
                        props.values.petBreed = null;
                        props.values.petSizeId = '';
                        setAge("");
                      }
                      setFormValues(props.values);
                    }}
                  />
                  
                  <div className="gap-6 lg:flex">
                    <div className="flex-none lg:w-1/3">
                      <div className="w-full flex-col items-center gap-5">
                        <>
                          <PetProfilePhoto
                            src={petImage}
                            size="md"
                            rounded="2xl"
                            petName={values.petName}
                            classes="mx-auto w-48 md:w-80"
                          />
                          <PhotoUpload
                            setProfilePhoto={setProfilePhoto}
                            isEdit={!!petImage}
                          />
                        </>
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:grow">
                      <FormControl
                        fieldProps={{
                          name: "petName",
                          component: "input",
                          validate: composeValidators(
                            required(t("validations.isRequired") as string),
                            alphaNumericFieldsValidation(
                              `${t("validations.noSpecialCharsNums")}`
                            ),
                            maxLength(
                              MAX_CHAR_NAME,
                              `${t("validations.canOnlyBe")}`,
                              `${t("validations.chars")}`,
                              `${t("validations.digits")}`,
                              `${t("validations.long")}`
                            )
                          ),
                        }}
                        onBlurCapture={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.value
                            ? setPetName(e.target.value)
                            : setPetName("Pet")
                        }
                        placeholder={`${t(ADD_EDIT_PET.PETS_NAME)}`}
                        label={`${t(ADD_EDIT_PET.PETS_NAME)}`}
                        isRequired={true}
                        classes="h-24"
                      />

                      <FormControl
                        fieldProps={{
                          name: "petNickName",
                          component: "input",
                          validate: composeValidators(
                            charValidationWithEmptyString(
                              `${t("validations.noSpecialCharsNums")}`
                            ),
                            maxLength(
                              MAX_CHAR_NAME,
                              `${t("validations.canOnlyBe")}`,
                              `${t("validations.chars")}`,
                              `${t("validations.digits")}`,
                              `${t("validations.long")}`
                            )
                          ),
                        }}
                        onBlurCapture={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.value ? setPetNickName(e.target.value)
                            : setPetNickName("")
                        }
                        placeholder={`${t(ADD_EDIT_PET.PETS_NICKNAME)}`}
                        label={`${t(ADD_EDIT_PET.PETS_NICKNAME)}`}
                        classes="h-24"
                      />
                    </div>
                  </div>
                  <div className="lg:flex">
                    <div className="flex-none lg:w-1/3">
                      <label
                        htmlFor="petType"
                        className="self-baseline text-xl font-bold"
                      >
                        {t(ADD_EDIT_PET.PETS_TYPE)}
                        <span className="text-red-600">*</span>
                      </label>
                      <div className="gap-10 lg:flex rounded pl-2 focus-within:outline focus-within:outline-brand-primary">
                        {petTypes.map((type: PetTypes) => {
                          if (type.petTypeId === 1) {
                            return (
                            <div key={`${type.petTypeId}_petTypeId`} className="flex w-full flex-col gap-1 -ml-10 w-fit mb-7">
                              <div>
                                <div className="ml-10 mr-1">
                                  <Field
                                  name="petTypeId"
                                  component="input"
                                  type="radio"
                                  value="1"
                                  >
                                    {({input}) => {
                                      return (
                                        <input
                                        {...input}
                                        className="peer mt-1 mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-8 checked:border-brand-primary"
                                        onChange={e => {
                                          input.onChange(e);
                                          form.mutators.setLifeStage(form.getState().values.petBirthday, "1");
                                        }}
                                        />
                                      )
                                    }}
                                  </Field>{" "}
                                  <label className="ml-2">
                                    {t(`addEditPet.${type.petTypeName}`)}
                                  </label>
                                  <div className="h-4"></div>
                                </div>
                              </div>
                            </div>
                            )
                          }
                          return (
                            <div key={`${type.petTypeId}_petTypeId`} className="flex w-full flex-col gap-1 -ml-10 w-fit mb-7">
                              <div>
                                <div className="ml-10 mr-1">
                                  <Field
                                  name="petTypeId"
                                  component="input"
                                  type="radio"
                                  value="2"
                                  >
                                    {({input}) => {
                                      return (
                                        <input
                                        {...input}
                                        className="peer mt-1 mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-8 checked:border-brand-primary"
                                        onChange={e => {
                                          input.onChange(e);
                                          form.mutators.setLifeStage(form.getState().values.petBirthday, "2");
                                        }}
                                        />
                                      )
                                    }}
                                  </Field>{" "}
                                  <label className="ml-2">
                                    {t(`addEditPet.${type.petTypeName}`)}
                                  </label>
                                  <div className="h-4"></div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="mt-10 lg:mt-0 lg:grow">
                      <label className="self-baseline text-xl font-bold">
                        {t(ADD_EDIT_PET.PETS_GENDER)}
                      </label>
                      <div className="gap-10 lg:flex rounded pl-2 focus-within:outline focus-within:outline-brand-primary">
                        {petGender.map((type: PetGender) => {
                          return (
                            <FormControl
                              key={type.petGenderId}
                              fieldProps={{
                                name: "petGenderId",
                                component: "input",
                                value: type.petGenderId.toString(),
                              }}
                              type="radio"
                              classes="-ml-10 w-fit"
                            >
                              {t(`addEditPet.${type.petGenderName}`)}
                            </FormControl>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <BreedSelection
                    setPetSize={(value) => form.mutators.setPetSize(value)}
                    petTypeId={Number(petTypeId)}
                  />
                  <BreedSize petTypeId={petTypeId} />
                  {values.petBirthdateTypeId ? (
                    <div
                      className={`gap-2 lg:flex mt-10 ${
                        values.petBirthdateTypeId?.toString() === "2"
                          ? "flex-row-reverse"
                          : "flex-row"
                      }`}
                    >
                      <Datepicker
                        name="petBirthday"
                        label={`${t(ADD_EDIT_PET.PETS_BIRTHDAY)}`}
                        validate={required(
                          t("validations.isRequired") as string
                        )}
                        petBirthday={values.petBirthday}
                        petsBirthdayTypeId={values.petBirthdateTypeId?.toString()}
                        max={new Date()}
                        onBlurCapture={(e: {
                          target: { name: string; value: string };
                        }) => {
                            form.mutators.setFormAge(e.target.value, petTypeId);
                        }}
                        isRequired={true}
                      />

                     
                    </div>
                  ) : null}
                 

                  <Datepicker
                    name="petAdoptDate"
                    validate={dateGreaterThan(
                      values.petBirthday,
                      `${t("addEditPet.birthdayGreaterThanAdoptDate")}`
                    )}
                    petBirthday={values.petAdoptDate}
                    max={new Date()}
                    petsBirthdayTypeId={values.petBirthdateTypeId?.toString()}
                    label={`${t(ADD_EDIT_PET.PETS_ADOPTION_DATE)}`}
                    optionalText={`${t(ADD_EDIT_PET.OPTIONAL_TEXT)}`}
                    isRequired={false}
                  />
                  <FormControl
                    fieldProps={{
                      name: "petLifeStage.petLifeStageName",
                      component: "input",
                      validate: required(
                        t("validations.petsLifeStage") as string
                      ),
                    }}
                    errorShort={true}
                    disabled={true}
                    label={`${t(ADD_EDIT_PET.PETS_LIFE_STAGE)}`}
                    value={values.petLifeStage.petLifeStageName}
                  />
                  <FormControl
                    data-testid="Pet's Vet"
                    fieldProps={{
                      name: "petVetName",
                      component: "input",
                      validate: composeValidators(
                        charValidationWithEmptyString(
                          `${t("validations.noSpecialCharsNums")}`
                        ),
                        maxLength(
                          MAX_CHAR_NAME,
                          `${t("validations.canOnlyBe")}`,
                          `${t("validations.chars")}`,
                          `${t("validations.digits")}`,
                          `${t("validations.long")}`
                        )
                      ),
                    }}
                    placeholder={
                      petName 
                        ? (shiftWords() ? `${ t(ADD_EDIT_PET.S_VET)}  ${petName}`:
                            `${petName}${t(ADD_EDIT_PET.S_VET)} `) : 
                            `${t(ADD_EDIT_PET.PETS_VET)}`
                    }
                    label={
                      petName 
                      ? (shiftWords() ? `${ t(ADD_EDIT_PET.S_VET)}  ${petName?.replace(/(.{8})..+/, "$1...")}`:
                          `${petName?.replace(/(.{8})..+/, "$1...")}${t(ADD_EDIT_PET.S_VET)} `) : 
                          `${t(ADD_EDIT_PET.PETS_VET)}`
                  }
                    classes="!mb-5"
                    optionalText={`${t(ADD_EDIT_PET.OPTIONAL_TEXT)}`}
                  />

                  {/* Pet's Food Selection entire section start */}
                  {foodSelect && <div className="flex w-full flex-col gap-1">
                    <label
                      htmlFor="petFood"
                      className="self-baseline text-xl font-bold"
                    >
                      {t(ADD_EDIT_PET.PETS_FOOD)}
                    </label>
                    {/* 1/3 food select section */}
                    {petFoodTypes && petFoodTypes?.length > 0 && <>
                      <FormControl
                        key={`${petFoodTypes[0]?.petFoodTypeId}_type`}
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: petFoodTypes[0]?.petFoodTypeId?.toString(),
                        }}
                        type="radio"
                        isUncheckable={true}
                        classes={values?.petFoodTypeId?.toString() === "1" && !foodSelectProduct ? "border border-red-600 mt-2 !mb-2 rounded food-select-parent-div pt-2.5 pb-4" : "border border-brand-accent-gray-light mt-2 !mb-2 rounded food-select-parent-div pt-2.5 pb-4"}
                      >
                        {t(`addEditPet.${petFoodTypes[0].petFoodTypeName}`)}
                        
                        {values?.petFoodTypeId?.toString() === "1" && (
                        <div className={`grid grid-cols-1 gap-2 !mt-5 !mr-10 ${foodSelectProduct? 'px-4 py-5':''}`}>
                          {!foodSelectProduct && (
                            <>
                                <Button
                                  variant="primary"
                                  aria-label="select food"
                                  type="button"
                                  onClick={handleOpenModal}
                                  classes="col-span-2 md:col-span-1 items-center mb-5 lg:mb-0 lg:w-1/2 lg:flex-none"
                                >
                                  {t(PET.SELECT_FOOD)}
                                </Button>
                                <div className="text-brand-error mt-1">{t(ADD_EDIT_PET.PETS_FOOD_NOT_SELECTED_TEXT)}</div>
                            </>
                          )}
                        {foodSelectProduct && (
                        <div className="flex items-start">                        
                          <img className="w-20 h-20 mr-4" src={foodSelectProduct.image} alt={foodSelectProduct.name} />
                          <div className="flex flex-col justify-center h-full">
                            <span className="text-base font-normal xl:font-semibold">{foodSelectProduct.name}</span>
                            <div className="flex items-center mt-2">
                             {editIcon}
                              <Button
                                mode="link"
                                variant="primary"
                                aria-label="edit food"
                                type="button"
                                onClick={handleOpenModal}
                              >
                                {t(`pet.editFood`)}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                        </div>
                      )}
                      </FormControl>

                      {/* 2/3 Other brand section start */}
                      <FormControl
                        key={`${petFoodTypes[1]?.petFoodTypeId}_type`}
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: petFoodTypes[1]?.petFoodTypeId?.toString(),
                        }}
                        type="radio"
                        isUncheckable={true}
                        classes={values?.petFoodTypeId?.toString() === "2" && values?.petFoodTypeDetails?.toString() === "" ? "border border-red-600 mt-2 !mb-2 rounded food-select-parent-div pt-2.5 pb-4" : "border border-brand-accent-gray-light mt-2 !mb-2 rounded food-select-parent-div pt-2.5 pb-4"}
                      >
                        {t(`addEditPet.${petFoodTypes[1]?.petFoodTypeName}`)}
                        {values?.petFoodTypeId?.toString() === "2" && <span onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}><BrandSelection
                            setFoodTypeDetails={(value) => form.mutators.setFoodTypeDetails(value)}
                            selectedRegion={region}
                          />
                          {values?.petFoodTypeDetails?.toString() === "" ? <div className="text-brand-error mt-1">{t(ADD_EDIT_PET.OTHER_BRAND_NOT_SELECTED_TEXT)}</div> : ""}
                          </span>}
                      </FormControl>

                      {/* 3/3 Homemade section start */}
                      <FormControl
                        key={`${petFoodTypes[2]?.petFoodTypeId}_type`}
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: petFoodTypes[2]?.petFoodTypeId?.toString(),
                        }}
                        isUncheckable={true}
                        type="radio"
                        classes="border border-brand-accent-gray-light mt-2 !mb-2 rounded food-select-parent-div pt-2.5 pb-4"
                      >
                        {t(`addEditPet.${petFoodTypes[2]?.petFoodTypeName}`)}
                      </FormControl>
                      
                      </>}
                      </div>}
                      {!valid && ((touched?.petName && errors?.petName) || (touched?.petBirthday && errors?.petBirthday) || errors?.petAdoptDate) && (
                      <div className="flex justify-center text-brand-error">
                      <>{t(ADD_EDIT_PET.COMMON_ERROR_WARNING)}</>
                      </div>
                  )}
                  {/* Food selection entire section end */}

                {/* Bottom button row start */}
                  <div className="mt-10 flex-row justify-center lg:flex lg:items-end lg:justify-end lg:space-x-5">
                    {petId && (
                      <>
                      {/* Remove pet button start */}
                        <Button
                          mode="link"
                          variant="primary"
                          aria-label="remove pet"
                          type="button"
                          isLoading={
                            loading &&
                            loading.petStatusId === petStatusByName.Deleted 
                          }
                          onClick={() => {
                            setShowReasonDialogDelete(true);
                          }}
                          classes="flex-col w-full mb-5 lg:mb-0 items-center lg:w-auto lg:flex-none"
                        >
                          {t(ADD_EDIT_PET.REMOVE_PET)}
                        </Button>

                  {/* Hide button start */}
                       {initData.petRemovalReason === PET_HIDE_REASONS.ARCHIVED.toLocaleLowerCase() ? null : <Button
                          mode="link"
                          variant="primary"
                          aria-label={petStatusId === petStatusByName.Remembered ? t(ADD_EDIT_PET.UNHIDE_PET): t(ADD_EDIT_PET.HIDE_PET)}
                          type="button"
                          isLoading={
                            loading &&
                            loading.petStatusId === petStatusByName.Remembered
                          }
                          onClick={() => {
                            hideUnhidePet(petId);
                          }}
                          classes="flex-col mb-5 lg:mb-0 w-full items-center lg:w-auto lg:flex-none"
                        >
                          {petStatusId === petStatusByName.Remembered ? t(ADD_EDIT_PET.UNHIDE_PET): t(ADD_EDIT_PET.HIDE_PET)}
                        </Button>}
                      </>
                    )}
                    {/* Cancel button start */}
                    <Button
                      variant="primary"
                      aria-label="cancel changes"
                      type="button"
                      onClick={() => {
                        setPetName(initData.petName as string);
                        setPetNickName(initData.petNickName as string);
                        setProfilePhoto(undefined);
                        form.reset();
                        redirectToPreviousPage();
                      }}
                      classes="flex-col w-full items-center mb-5 lg:mb-0 lg:w-auto lg:flex-none"
                    >
                      {t(ADD_EDIT_PET.CANCEL)}
                    </Button>

                    {/* Save button start */}
                    <Button
                      variant="primary"
                      mode="contained"
                      aria-label="submit"
                      type="submit"
                      classes="flex-col w-full items-center mb-5 lg:mb-0 lg:w-auto lg:flex-none"
                      disabled={
                        (pristine && !imageToUpload) ||
                        submitting ||
                        (foodSelect && values.petFoodTypeId === 1 && !foodSelectProduct) || // Check foodSelectProduct only if foodSelect is true
                        (foodSelect && values.petFoodTypeId === 2 && !values.petFoodTypeDetails) // Check petFoodTypeDetails only if foodSelect is true
                      }
                      isLoading={submitting}
                    >
                     {`${t(ADD_EDIT_PET.SAVE)}`}
                    </Button>
                    
                  </div>
                  <div ref={lastRefElement}></div>
                </form>
                )
              }}
            />
            <HideDeleteModals
              hideAndRemovePetApi={removeHidePet}
              petId={(location as CustomizedState).state?.petId}
              showReasonDialogDelete={showReasonDialogDelete}
              setShowReasonDialogDelete={setShowReasonDialogDelete}
              setHideDialogOpen={setHideDialogOpen}
              hideDialogOpen={hideDialogOpen}
            />
            <FoodSelectionModal
              petName={petName}
              petTypeId={petTypeId} 
              open={foodSelectModalOpen}
              setOpen={setFoodSelectModalOpen}
              updateSelectedFood={updateSelectedFood}
              resetFiltersTrigger={resetFiltersTrigger}
            />
        </div>
      </div>
       }
    </>
  );
};

export default AddEditPet;