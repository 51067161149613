import React, { createContext, useContext, useState, useRef, useCallback } from "react";
import { HillsProductType } from "../../../../../../types";
import axios from "axios";
import { useApi } from "utils";
import { LOCALE_MAPPINGS } from "../../../../../constants/foodSelectApiMapping";
import { useFeatureFlagsConfig } from "../../../../../hooks/useFeatureFlags";

type FoodSelectContextProviderProps = {
  children: React.ReactNode
};

type FoodSelectContext = {
  hillsProducts: HillsProductType[];
  isDataLoading: boolean;
  loadHillsProducts: (petTypeId: number, open: boolean) => void;
};

const FoodSelectDataContext = createContext<FoodSelectContext | null>(null);

export const FoodSelectDataContextProvider = ({
  children
}: FoodSelectContextProviderProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [hillsProducts, setHillsProducts] = useState<HillsProductType[]>([]);
  const [productsCache, setProductsCache] = useState<Record<number, HillsProductType[]>>({});
  const [isDataLoading, setIsDataLoading] = useState(false); 
  const [hasFetchedProducts, setHasFetchedProducts] = useState<Record<number, boolean>>({});  // Added to track if products have been fetched
  const { foodSelect } = useFeatureFlagsConfig();

  // Function to retrieve products from the cache
  const getStoredProducts = (id: number): HillsProductType[] => {
    return productsCache[id] || [];
  };

  // Function to update the cache with new products
  const updateProductsCache = (id: number, products: HillsProductType[]) => {
    setProductsCache(prevCache => ({
      ...prevCache,
      [id]: products
    }));
  };

  const updateHasFetchedProducts = (id: number, fetched: boolean) => {
    setHasFetchedProducts(prevState => ({
      ...prevState,
      [id]: fetched
    }));
  };
  
  const { exec: fetchHillsFood } = useApi(async (petTypeId) => {
    setIsDataLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const numericPetTypeId = Number(petTypeId);
    const petType = numericPetTypeId === 1 ? 'dog' : 'cat';
    const apiUrl = `${process.env.REACT_APP_FOODSEL_URL}`;
    const apiKey = `${process.env.REACT_APP_FOODSEL_API_KEY}`;
    const storeId = `${process.env.REACT_APP_FOODSEL_STORE_ID}`;
    const mappedLocale: string = LOCALE_MAPPINGS[window.hph_locale];

    const params = new URLSearchParams();
    params.append('hph-api-key', apiKey);
    params.append('species', petType);
    params.append('context', mappedLocale);

    try {
      const apiResponse = await axios.get(apiUrl, {
        headers: {
          'store-id': storeId,
        },
        params: params,
        signal: abortController.signal,
      });

      if (apiResponse.status === 200) {
        const apiResp = apiResponse.data;
        const filteredProducts = apiResp.filter((product: HillsProductType) => product.primaryProductImageUrl);
        updateProductsCache(numericPetTypeId, filteredProducts); 
        setHillsProducts(filteredProducts);
        updateHasFetchedProducts(numericPetTypeId, true);  
      } else {
        updateProductsCache(numericPetTypeId, []);  // Added to handle non-200 responses
        setHillsProducts([]);  // Added to handle non-200 responses
        updateHasFetchedProducts(numericPetTypeId, true);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsDataLoading(false);
    }
  });

  const loadHillsProducts = useCallback((petTypeId: number, open: boolean) => {
    if (open) {
       // Check if the foodSelect feature is enabled for the current locale
      if (!foodSelect) {
        return;
      }
      const storedProducts = getStoredProducts(petTypeId);
      const hasFetched = hasFetchedProducts[petTypeId]; 
      // Only update the state if it's different from the current state and not loading
      if (storedProducts.length > 0 || isDataLoading) { 
        if (hillsProducts !== storedProducts) {
          setHillsProducts(storedProducts);
        }
        // Added to handle empty array response
      } else if (storedProducts.length === 0 && hasFetched) {  // ** Handle empty array response if already fetched
        return;
      } else if (!isDataLoading) {
        // If we don't have products for this petTypeId, fetch them
        fetchHillsFood(petTypeId);
      }
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [fetchHillsFood, foodSelect, hillsProducts, isDataLoading]);

  return (
    <FoodSelectDataContext.Provider value={{ hillsProducts, isDataLoading, loadHillsProducts }}>
      {children}
    </FoodSelectDataContext.Provider>
  );
};

export function useFoodSelectDataContext() {
  const context = useContext(FoodSelectDataContext);
  if (!context) {
    throw new Error('useFoodSelectDataContext must be used within a FoodSelectDataContextProvider');
  }
  return context;
};