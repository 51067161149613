import { Button, Card, Loader } from "ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { calculateAgeApprox, prepareFormDataWithImage } from "utils";

import { PetType } from "../../../../types";
import { HideAndRemovePetApiOption } from "../types";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { ROUTES_PATH } from "../../../constants/routes";
import PetProfilePhoto from "../PetProfilePhoto";
import GTMACTIONS from '../../../config/gtmActions';
import { datalayerPush } from '../../../lib/gtm';
import { fullUrl } from "../../../lib/common/gtm";
import { alert } from "../../../icons/alert";
import { getIsPetUpdatedFromSnowflake, prepareObjToSend } from "../../snowflakeModal/utils";
import { usePetsApiCall } from "../../../hooks/use-apiCall";
import { useState } from "react";

const PetTile = ({
  pet,
  updatedPets,
  fetchSnowflakePets
}: {
  pet: PetType;
  hideAndRemovePetApi?: (options: HideAndRemovePetApiOption[]) => void;
  loading?: { petId?: string; petStatusId?: number };
  updatedPets: Array<PetType>;
  fetchSnowflakePets: () => void
}) => {
  const navigate = useNavigate();
  const petConfig = usePetConfig();
  const { getBreed } = petConfig;
  const { t } = useTranslation();
  const { updatePetApi } = usePetsApiCall();
  const [isSfDataLoading, setIsSfDataLoading] = useState(false);

  const navigation = async () => {
    //before navigating to pet profile, check if the pet is updated from Snowflake
    const isPetUpdated = getIsPetUpdatedFromSnowflake(pet, updatedPets, petConfig);
    if (isPetUpdated?.length) {
      //make update api call then navigate to pet profile
      setIsSfDataLoading(true);
      const petObjectToSend = prepareObjToSend(isPetUpdated[0]);
      petObjectToSend.petData[0]['petId'] = pet.petId;
      await updatePetApi(prepareFormDataWithImage(petObjectToSend));
      fetchSnowflakePets();
      setIsSfDataLoading(false);
      navigate(ROUTES_PATH.PET, {
        state: { petId: pet.petId, showIsDeceased: pet.showIsDeceasedIcon },
        replace: false,
      });
    } else {
      navigate(ROUTES_PATH.PET, {
        state: { petId: pet.petId, showIsDeceased: pet.showIsDeceasedIcon },
        replace: false,
      });
    }
  }

  const combinedFunction = () => {
    gaClickPet();
    navigation();
  }

  

  // Adding GA tag for Pet Tiles on Dashboard
  const gaClickPet = () => {
    const GAclickEvent = {
      ...GTMACTIONS.PETTILE,
      "click_text": "pet name: " + pet?.petName,
      "click_text2": "pet nickname: " + pet?.petNickName,
      "click_url": fullUrl + ROUTES_PATH.PET
    }
    datalayerPush(GAclickEvent);
  }

  const getMyPetsBreed =  pet.petBreedId && getBreed && getBreed(pet.petBreedId);
  if (isSfDataLoading) {
    return <Loader />
  }
  
  return (
    <Card
      role="listitem"
      classes="flex-col w-full relative border border-brand-secondary"
      aria-label={"Your Pet " + pet.petName}
    >
      <PetProfilePhoto
        src={pet.petImage}
        size="lg"
        rounded="t-2xl"
        petName={pet.petName}
        background="bg-brand-accent-gray-light h-[45%]"
      />
      <div className="grid rounded-b-2xl ">
        <div className="m-2.5 mt-7 text-center truncate">
          <h2 className="mb-3 text-3xl font-extrabold capitalize truncate">
            {pet.petNickName || pet.petName}
          </h2>
          <div className="mx-auto mb-2 mt-4 w-44 border-t border-brand-accent-gray-light"></div>
          <p className="text-lg font-normal">
            {getMyPetsBreed ? t(`petBreeds.${getMyPetsBreed.petBreedName}`) : t("pet.notSelectedText")}{" "}/{" "}
            {pet.petBirthday && calculateAgeApprox(pet.petBirthday, t)}
          </p>
        </div>
        <div className="flex items-center justify-center mt-10">
          <div>
            <Button
              classes="capitalize truncate !px-1 !lg:px-4"
              data-testid="View profile"
              mode="link"
              variant="primary"
              aria-label={`View ${pet.petName}'s Profile`}
              onClick={combinedFunction}
            >
              {t("common.view")} {pet.petName}
            </Button>
          </div>
          <div>
            {pet.showIsDeceasedIcon ? <div>{alert}</div> : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PetTile;
