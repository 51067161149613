type CloseIconProps = {
  className?: string;
};

export const CloseIcon: React.FC<CloseIconProps> = ({ className = '' }) => {
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-label="X close button"
  >
    <g aria-hidden="true">
      <path
        d="M12.4593 3.54738C12.1832 3.27131 11.7373 3.27131 11.4612 3.54738L7.99968 7.00182L4.53817 3.54031C4.26209 3.26423 3.81613 3.26423 3.54006 3.54031C3.26399 3.81638 3.26399 4.26234 3.54006 4.53841L7.00157 7.99992L3.54006 11.4614C3.26399 11.7375 3.26399 12.1835 3.54006 12.4595C3.81613 12.7356 4.26209 12.7356 4.53817 12.4595L7.99968 8.99802L11.4612 12.4595C11.7373 12.7356 12.1832 12.7356 12.4593 12.4595C12.7354 12.1835 12.7354 11.7375 12.4593 11.4614L8.99778 7.99992L12.4593 4.53841C12.7283 4.26942 12.7283 3.81638 12.4593 3.54738Z"
        fill="#34363B"
      />
    </g>
  </svg>
  );
};

