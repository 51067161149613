import axios from "axios";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, IconBigCalendar } from "ui";
import { useApi, formatMilestoneDate, DATE_FORMAT } from "utils";
import { API_URLS, GENERATE_MILESTONES_PATH } from "../../../constants/apiUrls";
import { MilestoneType } from "../types";
import { CANCEL, SAVE } from "../../../constants";
import useRequireAuth from "../../../hooks/useRequireAuth";
import { Breadcrumb } from "../../../lib/breadcrumb";
import { BREADCRUMB_CONFIG } from "../../../constants/routes";
import { FormApi } from "final-form";
import { ModifierType } from "../../../../types";
import Heading from "../../Heading";
import { LifestageMilestone } from "../../../constants/lifestageMilestone";
import { IconPrev, IconNext } from "ui";
import { useToastConfig } from "../../../lib/toast";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { LANG } from "../../../constants/languages";
import { Datepicker } from "../../../lib/Datepicker";
import { format, subYears } from "date-fns";
import InfoTooltip from "../../../lib/tooltip";

const LifestageMilestoneForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [initData, setInitData] = useState<Record<string, unknown>>({});
  const [milestonesData, setMilestonesData] = useState([]);
  const [metaData, setMetadata] = useState<Record<string, object>>({});
  const { showToast } = useToastConfig();
  const { petId, lifestageId } = useParams();
  const { getLifeStageName, getLifeStageDesc } = usePetConfig();

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    petId && lifestageId && fetchMilestonesData();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  //Api configs
  // fetch and update milestones api
  const { exec: fetchMilestonesData } = useApi((lifeId = 0) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(
        `${process.env.REACT_APP_PET_API_URL}${
          API_URLS.PETS
        }${GENERATE_MILESTONES_PATH(
          petId as string,
          lifeId ? (lifeId as string) : (lifestageId as string)
        )}`,
        {
          signal: abortController.signal,
          headers: { locale: navigator.language || LANG.EN_US },
        }
      )
      .then((apiResponse) => {
        window.scrollTo(0, 0);
        if (apiResponse.data.serviceStatus.code === 200) {
          const modifiedResponse = apiResponse.data.data;
          let modifiedInitState = {};
          setMilestonesData(modifiedResponse);
          setMetadata(apiResponse.data.metadata);
          modifiedResponse.map((res: MilestoneType) => {
            if (res.completedDate) {
              modifiedInitState = {
                ...modifiedInitState,
                [`milestoneCheck_${res.petMilestoneId}`]: true,
                [`completeDate_${res.petMilestoneId}`]: res.completedDate,
              };
            }
          });
          setInitData(modifiedInitState);
        }
      });
  });

  const { exec: updateMilestonesData } = useApi((options: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .put(
        `${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}${API_URLS.MILESTONE}`,
        {
          petData: options,
        }
      )
      .then((apiResponse) => {
        window.scrollTo(0, 0);
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(LifestageMilestone.TITLE)}`,
            description: `${t(LifestageMilestone.UPDATE_SUCCESS)}`,
            type: "success",
          });
          fetchMilestonesData();
        } else {
          showToast({
            title: `${t(LifestageMilestone.TITLE)}`,
            description: `${t(LifestageMilestone.UPDATE_FAILED)}`,
            type: "error",
          });
        }
      });
  });

  const setUpdatedUrl = (lifestageId: string) => {
    window.history.replaceState(
      null,
      "",
      `#/milestone/${petId}/${lifestageId}`
    );
  };

  //auth check
  const auth = useRequireAuth();
  if (!auth || !auth?.user?.UID) return null;

  const prepareObjToSend = (
    isModifiedValues: ModifierType,
    values: MilestoneType
  ) => {
    const uniqueIds: string[] = [];
    for (const val in isModifiedValues) {
      const num = val.split("_")[1];
      !uniqueIds.includes(num) && uniqueIds.push(num);
    }
    const milestoneObject = uniqueIds.map((id: string) => {
      return {
        petId: petId,
        petMilestoneId: id,
        completedDate: values[`completeDate_${id}` as keyof MilestoneType],
      };
    });
    return milestoneObject;
  };

  const onSubmit = (
    values: MilestoneType,
    form: FormApi<MilestoneType, Partial<MilestoneType>>
  ) => {
    const updatedValues = prepareObjToSend(form.getState().dirtyFields, values);
    if (Object.keys(form.getState().dirtyFields).length) {
      updateMilestonesData(updatedValues);
    }
  };
  return (
    <>
      <div className="mt-0 lg:container lg:mt-4">
        <Breadcrumb
          config={[
            BREADCRUMB_CONFIG.home,
            BREADCRUMB_CONFIG.petDashboard,
            BREADCRUMB_CONFIG.milestones,
          ]}
        />
        <div className="mb-20">
          <Heading name={t(LifestageMilestone.TITLE)} />
          <div className=" flex justify-between rounded-t-xl bg-brand-primary">
            <h2 className="py-4 pl-8 font-bold capitalize text-white">{`${
              metaData
                ? t(LifestageMilestone.MILESTONE_FORM_HEADER_RPL_STR).replace("REPLACE_STRING", `${(metaData.petInfo as Record<string, string>)?.petName}'s`)
                : t(LifestageMilestone.MILESTONE_FORM_HEADER_RPL_STR).replace("REPLACE_STRING", "<Pet>")
            }`}</h2>
            <Button
              variant="light"
              mode="link"
              size="sm"
              classes="underline underline-offset-4 mr-6"
              aria-label="update changes"
              type="submit"
              onClick={() => navigate("/pets", { replace: true })}
            >
              {t(LifestageMilestone.BACK)}
            </Button>
          </div>
          <div className="rounded-b-xl border border-brand-primary p-8">
            <div className="grid grid-cols-5">
              <div className="col-span-5 mb-9 flex flex-col items-center text-sm font-normal text-slate-500 lg:flex-row">
                <div className="mb-6 flex lg:mb-0 lg:w-1/5">
                  <IconBigCalendar className="h-20 mr-4" />
                </div>
                <div className="lg:grow"></div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `lifestageMilestone.${
                        (getLifeStageDesc &&
                          (getLifeStageDesc(
                            +(lifestageId as string)
                          ) as string)) as string
                      }`
                    ),
                  }}
                />
              </div>
              <div className="col-span-5 flex justify-center font-bold lg:col-span-4 lg:flex-none lg:justify-start">
                {getLifeStageName && t(LifestageMilestone.MILESTONE_COLUMN_HEADER_RPL_STR)
                  .replace("REPLACE_STRING", t(`pet.${getLifeStageName(+(lifestageId as string))}`))}
              </div>
              <div className="hidden justify-end font-bold lg:flex">
                {t(LifestageMilestone.COMPLETED_DATE)}
              </div>
            </div>
            <div className="flex justify-center">
              <div className="lg:align-center mt-2 lg:w-full lg:border-t-2"></div>
            </div>
            <Form
              initialValues={initData}
              onSubmit={onSubmit}
              mutators={{
                setCompleteDate: (args, state, utils) => {
                  const splitString = args[0].split("_");
                  utils.changeValue(
                    state,
                    `milestoneCheck_${splitString[1]}`,
                    () => (args[1] ? true : false)
                  );
                  utils.changeValue(
                    state,
                    `completeDate_${splitString[1]}`,
                    () => args[1]
                  );
                },
                setCheckValue: (args, state, utils) => {
                  utils.changeValue(state, args[0], () => args[1]);
                  utils.changeValue(state, args[2], () => args[3]);
                },
              }}
              render={({ handleSubmit, form }) => (
                <form
                  onSubmit={handleSubmit}
                  role="form"
                  className="mt-6 min-h-[30rem]"
                >
                  {milestonesData.map((milestone: MilestoneType) => {
                    const dateFrom = new Date(milestone.suggestedDates.from);
                    const dateTo =
                      milestone.suggestedDates.to !== "NA"
                        ? new Date(milestone.suggestedDates.to)
                        : undefined;
                    return (
                      <div
                        className="mt-4 -mb-2 grid grid-cols-5 lg:-mb-4"
                        key={milestone.petMilestoneId}
                      >
                        <div className="col-span-5 flex lg:col-span-4">
                          <FormControl
                            fieldProps={{
                              name: `milestoneCheck_${milestone.petMilestoneId}`,
                              component: "input",
                            }}
                            type="checkbox"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const splitString = e.target.name.split("_");
                              form.mutators.setCheckValue(
                                `milestoneCheck_${splitString[1]}`,
                                e.target.checked,
                                `completeDate_${splitString[1]}`,
                                e.target.checked
                                  ? format(new Date(), DATE_FORMAT).toString()
                                  : null
                              );
                            }}
                            label={<div >{`${t(
                              `lifestages.${milestone.petMilestoneName}`
                            )}`} <InfoTooltip description={`${t(`milestoneHelperText.${milestone.petMilestoneHelperText}`)}`}/></div>}
                            subtext={
                              dateTo
                                ? `${t("lifestageMilestone.estimated")}: ${t(
                                    "lifestageMilestone.between"
                                  )} ${formatMilestoneDate(dateFrom)} ${t(
                                    "lifestageMilestone.and"
                                  )} ${formatMilestoneDate(dateTo)}`
                                : `${t("lifestageMilestone.estimated")}: ${t(
                                    "lifestageMilestone.around"
                                  )} ${formatMilestoneDate(dateFrom)}`
                            }
                            classes="justify-end font-medium gap-4 -mb-6 lg:mb-6"
                            flexDirection="row-reverse"
                          />
                        </div>
                        <div className="col-span-5 mt-8 flex lg:col-auto lg:mt-0 lg:justify-self-end">
                          <>
                            <Datepicker
                              name={`completeDate_${milestone.petMilestoneId}`}
                              min={subYears(new Date(), 70)}
                              onBlurCapture={(e: {
                                target: { name: string; value: string };
                              }) => {
                                form.mutators.setCompleteDate(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                            />
                          </>
                        </div>
                      </div>
                    );
                  })}

                  <div className="mt-7 flex justify-between">
                    <div className="flex cursor-pointer items-center text-brand-primary">
                      {metaData.previousPage &&
                        (metaData.previousPage as { milestoneCount: number })
                          .milestoneCount > 0 && (
                          <div
                            className="flex"
                            onClick={() => {
                              setUpdatedUrl(
                                (
                                  metaData.previousPage as {
                                    lifeStageId: string;
                                  }
                                ).lifeStageId
                              );
                              fetchMilestonesData(
                                (
                                  metaData.previousPage as {
                                    lifeStageId: string;
                                  }
                                ).lifeStageId
                              );
                            }}
                          >
                            <span>
                              <IconPrev />
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {t(LifestageMilestone.PAST_MILESTONES)}
                          </div>
                        )}
                    </div>
                    <div className="flex cursor-pointer items-center text-brand-primary">
                      {metaData.nextPage &&
                        (metaData.nextPage as { milestoneCount: number })
                          .milestoneCount > 0 && (
                          <div
                            className="flex"
                            onClick={() => {
                              setUpdatedUrl(
                                (metaData.nextPage as { lifeStageId: string })
                                  .lifeStageId
                              );
                              fetchMilestonesData(
                                (metaData.nextPage as { lifeStageId: string })
                                  .lifeStageId
                              );
                            }}
                          >
                            {t(LifestageMilestone.NEXT_MILESTONES)}
                            &nbsp;&nbsp;&nbsp;
                            <span>
                              <IconNext />
                            </span>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="mt-7 mb-0 flex flex-col-reverse justify-center lg:flex-row lg:justify-end lg:space-x-5">
                    <Button
                      variant="primary"
                      size="md"
                      aria-label="cancel"
                      onClick={() => {
                        setInitData({});
                        form.reset();
                      }}
                      classes="w-full mt-4 lg:mt-0 lg:w-32"
                    >
                      {t(CANCEL)}
                    </Button>
                    <Button
                      variant="primary"
                      mode="contained"
                      size="md"
                      aria-label="update changes"
                      type="submit"
                      disabled={
                        !Object.keys(form.getState().dirtyFields).length
                      }
                      classes="w-full lg:w-32"
                    >
                      {t(SAVE)}
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LifestageMilestoneForm;
