import { PetType } from "../../../types";
const isDeceasedStatus = 2;

export const comparisonPetAttributes = (hphPet: PetType, pet: PetType) => {
    const requiredFields = ['petName', 'petBirthday', 'petTypeId'];
    const isSimilarPet = requiredFields.every((field) => {
        return hphPet[field as keyof PetType] === pet[field as keyof PetType];
    });
    return isSimilarPet;
}


const getSfMergedPets = (hphPets: Array<PetType>, rememberedPets: Array<PetType> = []) => {
    const mergedPets: Array<PetType> = [];
    hphPets.forEach((hphPet: PetType) => {
        if(rememberedPets && rememberedPets.length > 0) {
            const sfPet = rememberedPets.find(
                (pet) => comparisonPetAttributes(hphPet, pet) && hphPet.petStatusId !== isDeceasedStatus
            );
            if (sfPet) {
                hphPet['showIsDeceasedIcon'] = true; // Set the property on individual hphPet object
            }
        }
        mergedPets.push(hphPet);
    });
        return mergedPets;
};

export default getSfMergedPets;