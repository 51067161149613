import { ReactNode,useEffect } from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { IconClose } from "ui";

export const AlertDialog = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogContent = AlertDialogPrimitive.Content;
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;

interface BtnConfig {
  name: string;
  callback?: () => void;
  variant?: string;
  mode?: string;
  size?: string;
  type?: string;
  classes?: string;
}
interface ModalConfig {
  modalConfig: {
    title?: string;
    btnConfig: BtnConfig[];
    body?: string | ReactNode;
    titleWidth?: string;
    customClass?: string;
    customBodyClass?: string;
    innerClass?: string;
  };
  dialogState: boolean;
  setDialog: (open: boolean) => void;
}


export const ModalWithList = ({ modalConfig, dialogState, setDialog }:ModalConfig) => {
   
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.overflow = "unset"
        }
    }, [])

    const cssClass = modalConfig.customClass ? modalConfig.customClass : " ";

    return (
        <AlertDialog open={dialogState}>
            <AlertDialogContent>
                <>
                    <div className="flex items-center justify-center fixed inset-0 z-[999] outline-none focus:outline-none">
                        <div className="fixed inset-0 bg-black opacity-40"></div>
                        <div className={"relative bg-white shadow-lg w-full h-full bg-white sm:w-3/5 lg:w-2/5 sm:h-4/5 rounded-lg  overflow-y-auto overflow-x-hidden " + cssClass}>
                            <div className="flex flex-col h-full">
                                <div>
                                    <div className="float-right flex justify-end border-0 bg-transparent text-black"
                                     onClick={()=>{setDialog(false)}}>
                                        <div
                                            className="mt-5 mr-5 mb-4 flex h-1 items-center"
                                            data-testid="closeButton"
                                        >
                                            <IconClose />
                                        </div>
                                    </div>
                                </div>
                                {modalConfig.body}
                                {/* {main pop up dialog} */}
                            </div>
                        </div>
                    </div>
                </>
            </AlertDialogContent>
        </AlertDialog>
    )
}