import { useEffect, useState } from "react";
import { PetType } from "../../../types";
import { PetObject } from "./index";

import { useTranslation } from "react-i18next";
import { usePetConfig } from "../../hooks/usePetConfig";
import { processPets, ModPetType } from './utils'
import PetCardStrip from "./PetCardStrip";

type PetListProps = {
    hphPets: Array<PetType>;
    snowflakePets: PetObject,
    isEditable: boolean,
    setSelectedPets?: (value: Array<ModPetType>) => void
    leftOutPets?: Array<ModPetType>
}

const PetList = ({ hphPets, snowflakePets, isEditable, setSelectedPets, leftOutPets }: PetListProps) => {
    const [petsToAddUpdate, setPetsToAddUpdate] = useState<Array<ModPetType>>([]);
    const petConfig = usePetConfig();
    const petsToDisplay = processPets(hphPets, snowflakePets, isEditable, petConfig);
    const [checkedPets, setCheckedPets] = useState<Array<ModPetType>>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setPetsToAddUpdate(petsToDisplay as Array<ModPetType>);
    }, []);

    useEffect(() => {
        (setSelectedPets) ? (isEditable ? setSelectedPets(checkedPets) : setSelectedPets(petsToAddUpdate)) : null;
    }, [checkedPets, petsToAddUpdate]);
    return (
        <div className="flex flex-col gap-4">
            {leftOutPets?.length ?
                (leftOutPets.map((pet, index) => {
                    return <PetCardStrip
                    isEditable={isEditable}
                    key={index}
                    pet={pet}
                    setCheckedPets={setCheckedPets}
                    checkedPets={checkedPets}
                    t={t}
                /> 
                })) :
                (petsToAddUpdate.map((pet, index) => {

                    return (
                        <PetCardStrip
                            isEditable={isEditable}
                            key={index}
                            pet={pet}
                            setCheckedPets={setCheckedPets}
                            checkedPets={checkedPets}
                            t={t}
                        /> 
                    )
                }))}

        </div>
    )
}

export default PetList;