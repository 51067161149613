// Notification component
import React from "react";
import { xCloseBtn } from "../../../icons/xCloseBtn";
import { notificationObjType } from "../types";

interface NotificationProps {
  notificationObj: notificationObjType;
  notification: Array<notificationObjType>;
}

const Notification: React.FC<NotificationProps> = ({ notificationObj, notification }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const onCheckboxClick = (isChecked: boolean) => {
    setIsChecked(isChecked);
  }
  const isButtonsOnLeft = notificationObj.position === "left";

  return (
    <div
      className={`m-2 flex max-w-2xl flex-row items-start rounded-lg bg-[#EEF5FF] p-4 ${
        isButtonsOnLeft ? "justify-between" : "justify-start"
      }`}
    >
      <div className="flex flex-col">
        <div className="text-lg font-semibold">{notificationObj.header}</div>
        <div className="text-base font-normal">{notificationObj.body}</div>
        <div
          className={`mt-2 flex flex-col text-lg font-semibold text-brand-primary lg:flex-row ${

            !notificationObj.ctaTwoText ? `items-start` : `justify-between`
          } ${isButtonsOnLeft ? "order-2" : ""}`}
        >
          {notificationObj.showCheckbox && (
            <label className="mb-3 flex items-center gap-2 lg:mb-0">
              <input
                type="checkbox"
                className="form-checkbox h-6 w-6 border-2 border-blue-900 text-blue-900"
                onChange={(e) => onCheckboxClick(e.target.checked)}
              />
              <span className="text-base text-black font-normal">{notificationObj.checkboxLabel}</span>
            </label>
          )}
          <div>
            <button
              className={`cursor-pointer ${
                notificationObj.ctaOneStyle ||
                "mt-2 flex flex-row items-center text-lg font-semibold text-brand-primary"
              }`}
              onClick={() => {
                notificationObj.ctaOneAction(notificationObj,notification,isChecked);
              }}
            >
              {notificationObj.ctaOneText}
              {notificationObj.icon ? <div className="cursor-pointer pl-3">{notificationObj.icon}</div> : null}
            </button>
            {notificationObj.ctaTwoText ? (
              <button
                className={`cursor-pointer ${
                    notificationObj.ctaTwoStyle ||
                  "mr-2 rounded border border-blue-900 bg-transparent py-2 px-4 font-semibold text-blue-900 hover:border-transparent hover:bg-blue-900 hover:text-white"
                }`}
                onClick={()=>{notificationObj.ctaTwoAction && notificationObj.ctaTwoAction(notificationObj,notification,isChecked)}}
              >
                {notificationObj.ctaTwoText}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          notificationObj.closeCtaAction(notificationObj,notification,isChecked);
        }}
      >
        {xCloseBtn}
      </div>
    </div>
  );
};

export default Notification;